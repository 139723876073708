import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import WebsiteRoundedIcon from '@mui/icons-material/LanguageRounded';
import React from 'react';
import {
  Box,
  Container,
  Divider,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';
import PropulseCard from '../../components/core/Universal/PropulseCard';

import event1 from '../../assets/images/misc/eventimg_1.webp';
import event2 from '../../assets/images/misc/eventimg_2.webp';
import event3 from '../../assets/images/misc/eventimg_3.webp';
import event4 from '../../assets/images/misc/eventimg_4.webp';

function UpcomingEvents() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Divider />
      <Box
        id="events"
        sx={{
          scrollSnapAlign: { xs: 'none', md: 'center' },
          minHeight: '100dvh',
          width: '100%',
          overflow: 'hidden',
          position: 'relative',
          py: { xs: 8, md: 12 },
          background: `linear-gradient(to bottom, ${theme.palette.background.default} 0%,  ${theme.palette.background.paper} 100%)`
        }}
      >
        <img
          src={event2}
          className="sliding_image4"
          style={{
            width: isMobile ? 250 : 400,
            aspectRatio: '1.6',
            backgroundSize: 'cover',
            position: 'absolute',
            borderRadius: '20px',
            zIndex: 0,
            top: '50%',
            right: isMobile ? -250 : -400
          }}
        />
        <img
          src={event3}
          className="sliding_image1"
          style={{
            width: isMobile ? 200 : 350,
            aspectRatio: '1.4',
            position: 'absolute',
            backgroundSize: 'cover',
            borderRadius: '20px',
            zIndex: 0,
            top: '20%',
            right: isMobile ? -200 : -350
          }}
        />
        <img
          src={event4}
          className="sliding_image2"
          style={{
            width: isMobile ? 350 : 500,
            backgroundSize: 'cover',
            aspectRatio: '1',
            position: 'absolute',
            borderRadius: '20px',
            zIndex: 0,
            top: '60%',
            right: isMobile ? -350 : -500
          }}
        />
        <img
          src={event1}
          className="sliding_image3"
          style={{
            width: isMobile ? 150 : 250,
            backgroundSize: 'cover',
            aspectRatio: '1.6',
            position: 'absolute',
            borderRadius: '20px',
            zIndex: 0,
            top: '40%',
            right: isMobile ? -150 : -250
          }}
        />
        <Container
          maxWidth="lg"
          sx={{
            position: 'inherit',
            zIndex: 10
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <Typography
              variant="h1"
              fontWeight={700}
              color="primary.main"
              // width="max-content"
              fontSize={{ xs: '2rem', sm: '3rem', md: '4rem' }}
            >
              Upcoming Events
            </Typography>
            {/* <PropulseCard
              sx={{
                width: '100%',
                maxWidth: 500,
                p: 4,
                bgcolor: `${theme.palette.background.paper}aa`,
                backdropFilter: 'blur(24px)',
                boxShadow: 8
              }}>
              <Typography variant="subtitle2" color="secondary.dark">
                Friday 8th September 2023
              </Typography>
              <Typography
                variant="h4"
                fontWeight={700}
                color="primary.main"
                fontSize={{ xs: '1.8rem', md: '2.5rem' }}
                mt={2}>
                Project 2024 Kickoff
              </Typography>
              <Box display="flex" gap={1} mt={1}>
                <Typography variant="subtitle1" fontSize={{ xs: '0.8rem', md: '1rem' }}>
                  Kickoff for the new team and project
                </Typography>
              </Box>
            </PropulseCard> */}
            <PropulseCard
              sx={{
                width: '100%',
                maxWidth: 550,
                p: 4,
                bgcolor: `${theme.palette.background.paper}aa`,
                backdropFilter: 'blur(24px)',
                boxShadow: 8
              }}
            >
              <Typography variant="subtitle2" color="secondary.dark">
                November 15th - November 17th, 2023
              </Typography>
              <Typography
                variant="h4"
                fontWeight={700}
                color="primary.main"
                fontSize={{ xs: '1.8rem', md: '2.2rem' }}
                mt={1}
              >
                Preliminary Design Review
              </Typography>
              <Typography
                variant="subtitle1"
                my={1}
                fontSize={{ xs: '0.8rem', md: '1rem' }}
              >
                {/* Propulse NTNU will be competing in the European Rocketry Challange 2023, where
                Bifrost will be launched in the 3000m SRAD Liquid class.
                <br /> */}
                PDR is where we ensure that the preliminary design meets all
                system requirements and constraints. This establishes the basis
                for proceeding with detailed final design.
                <br /> <br />
              </Typography>
              <Typography
                variant="subtitle1"
                my={1}
                fontSize={{ xs: '0.8rem', md: '1rem' }}
              >
                <b>Wednesday 15th:</b> 10:15 - 15:00,{' '}
                <a
                  target="_blank"
                  href="https://link.mazemap.com/JNT3x7jV"
                  rel="noreferrer"
                  style={{ textDecoration: 'underline' }}
                >
                  VG1
                </a>
              </Typography>
              <Typography
                variant="subtitle1"
                my={1}
                fontSize={{ xs: '0.8rem', md: '1rem' }}
              >
                <b>Thursday 16th:</b> 12:15 - 17:00,{' '}
                <a
                  target="_blank"
                  href="https://link.mazemap.com/XfBXPjoT"
                  rel="noreferrer"
                  style={{ textDecoration: 'underline' }}
                >
                  P1
                </a>
              </Typography>
              <Typography
                variant="subtitle1"
                my={1}
                fontSize={{ xs: '0.8rem', md: '1rem' }}
              >
                <b>Friday 17th:</b> 12:15 - 17:00,{' '}
                <a
                  target="_blank"
                  href="https://link.mazemap.com/XfBXPjoT"
                  rel="noreferrer"
                  style={{ textDecoration: 'underline' }}
                >
                  P1
                </a>
              </Typography>
              {/* <Box display="flex" gap={1} mt={1}>
                <WebsiteRoundedIcon />
                <Typography
                  variant="subtitle1"
                  fontSize={{ xs: '0.8rem', md: '1rem' }}
                  sx={{ textDecoration: 'underline' }}>
                  <a
                    target="_blank"
                    href="https://euroc.pt"
                    rel="noreferrer"
                    style={{ textDecoration: 'underline' }}>
                    EuRoC Website
                  </a>
                </Typography>
              </Box> */}
              {/* <Box display="flex" sx={{ alignItems: 'center' }} gap={1} mt={1}>
                <LocationOnRoundedIcon />
                <Typography
                  variant="subtitle1"
                  fontSize={{ xs: '0.8rem', md: '1rem' }}
                  sx={{ textDecoration: 'underline' }}>
                  <a
                    target="_blank"
                    href="https://www.google.com/maps/place/Const%C3%A2ncia,+Portugal/@39.4791088,-8.3413134,16.01z/data=!4m6!3m5!1s0xd186573e5bf12d7:0xdba4866970c0f3c6!8m2!3d39.4780719!4d-8.3372252!16zL20vMDV4YmY4?entry=ttu"
                    rel="noreferrer">
                    Constância, Portugal
                  </a>
                </Typography>
              </Box> */}
            </PropulseCard>
          </Box>
        </Container>
      </Box>
      <Divider />
    </>
  );
}

export default UpcomingEvents;
