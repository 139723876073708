import { createTheme } from '@mui/material';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#0035f7'
    },
    secondary: {
      main: '#C69C6D'
    },
    background: {
      default: '#f7f7f7',
      paper: '#ffffff'
    }
  },
  typography: {
    fontFamily: "'Lexend Deca', sans-serif"
  }
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#2d3afe'
    },
    secondary: {
      main: '#C69C6D'
    },
    background: {
      default: '#141414',
      paper: '#202020'
    }
  },
  typography: {
    fontFamily: "'Lexend Deca', sans-serif"
  }
});

export { lightTheme, darkTheme };
