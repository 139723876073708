import HeightRoundedIcon from '@mui/icons-material/HeightRounded';
import RocketLaunchRoundedIcon from '@mui/icons-material/RocketLaunchRounded';
import LocalFireDepartmentRoundedIcon from '@mui/icons-material/LocalFireDepartmentRounded';
import StraightenRoundedIcon from '@mui/icons-material/StraightenRounded';
import {
  Box,
  Card,
  Container,
  Fade,
  Grid,
  styled,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';

import React from 'react';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import LoremIpsum from '../../components/LoremIpsum';
import getProjectBackground from './getProjectImg';
import TimelineLayout from '../../components/timeline/TimelineLayout/TimelineLayout';
import TimelineTile from '../../components/timeline/TimelineTile/TimelineTile';
import InformationCard from '../../components/timeline/InformationCard/InformationCard';
import RocketCarousel from '../../components/Projects/RocketCarousel';

import birkelandGroundStation from '../../assets/images/rocket-projects/2022-birkeland/groundstation.webp';
import valemonPatch from '../../assets/images/rocket-projects/mission-patches/valemon_patch.png';
import valemonRocket from '../../assets/images/rocket-projects/2024-valemon/ValemonClosed.png';
import valemonLogo from '../../assets/images/rocket-projects/2024-valemon/Valemon-logo.png';
import project2025Patch from '../../assets/images/rocket-projects/mission-patches/project2025_patch.png';
import project2025Logo from '../../assets/images/rocket-projects/2025-temp/project2025_logo.png';

import valemonOuter from '../../assets/images/rocket-projects/2024-valemon/ValemonClosed-horizontal.png';
import valemonInner from '../../assets/images/rocket-projects/2024-valemon/ValemonOpen-horizontal.png';

const BirkelandCard = styled(Card)(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: '#ffffff00',
  backgroundImage: 'none',
  backdropFilter: 'blur(12px)',
  boxShadow: theme.shadows[0],
  border: 'solid white 1px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 4
}));

const Project2025 = () => {
  const carouselSlides = [
    {
      title: 'Outer Structure',
      content: <LoremIpsum />,
      image: valemonOuter
    },
    {
      title: 'Inner Structure',
      content: <LoremIpsum />,
      image: valemonInner
    }
  ];

  // const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = React.useState('');

  const handleOpen = (modalID) => {
    setOpen(modalID);
  };
  const handleClose = () => {
    setOpen('');
  };

  return (
    <Box
      sx={{
        height: '100vh',
        overflow: 'auto'
      }}
    >
      <Box
        component="img"
        src={getProjectBackground('Project2025')}
        sx={{
          position: 'fixed',
          top: 0,
          zIndex: -1,
          objectFit: 'cover',
          width: '100%',
          height: '100vh',
          background:
            'linear-gradient(0deg, rgba(230,230,230,1) 0%, rgba(255,255,255,1) 100%)',
          filter: 'brightness(0.5)'
        }}
      />
      <Box>
        <Box
          sx={{
            minHeight: { xs: 'fit-content', md: 'calc(100vh - 119.47px)' }
          }}
        >
          <Header
            sx={{
              width: '100%',
              zIndex: 1000
            }}
          />
          <Container maxWidth="lg">
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={8}>
                <Grid container padding={4} spacing={{ xs: 2, md: 4 }}>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                  >
                    <Box display="flex" gap={4} alignItems="center">
                      <Box
                        component="img"
                        src={project2025Logo}
                        height={{ xs: 100, sm: 150, md: 180 }}
                      />
                      <Box>
                        <Typography
                          variant="h2"
                          color="white"
                          fontWeight={700}
                          fontSize={{ xs: '1.5rem', sm: '2.7rem', md: '3rem' }}
                          // fontSize={{ xs: 28, md: 40 }}
                          sx={{ mt: { xs: 4, md: 0 } }}
                        >
                          PROJECT <br /> 2025
                        </Typography>
                        <Typography
                          variant="h4"
                          color="white"
                          fontWeight={600}
                          // fontSize={{ xs: 23, md: 25 }}
                          fontSize={{ xs: '1rem', sm: '1.5rem', md: '2rem' }}
                        >
                          TEAM 2025
                        </Typography>
                      </Box>
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{ color: 'white' }}
                      // fontWeight={300}
                      fontSize={{ xs: 16, md: 18 }}
                      // fontSize={{ xs: '0.8rem', md: '1rem' }}
                    >
                      <br />
                      Project 2025 is Propulse's seventh and most ambitious
                      project yet. The project will build on the experience and
                      knowledge gained from project Valemon. We aim to reach
                      heights never before achieved by Propulse with a bi-liquid
                      rocket, launched from Norwegian soil.
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={6} md={3}>
                    <BirkelandCard sx={{ p: 2, height: isMobile ? 150 : 140 }}>
                      <RocketLaunchRoundedIcon sx={{ fontSize: 40, color: 'white' }} />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={isMobile ? '0.7rem' : '0.8rem'}
                        sx={{ color: 'white', textAlign: 'center' }}>
                        Lorem
                        <br />
                        Ipsum
                        <br />
                        Dolor
                      </Typography>
                    </BirkelandCard>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <BirkelandCard sx={{ p: 2, height: isMobile ? 150 : 140 }}>
                      <StraightenRoundedIcon sx={{ fontSize: 40, color: 'white' }} />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={isMobile ? '0.7rem' : '0.8rem'}
                        sx={{ color: 'white', textAlign: 'center' }}>
                        X m <br /> Length
                      </Typography>
                    </BirkelandCard>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <BirkelandCard sx={{ p: 2, height: isMobile ? 150 : 140 }}>
                      <LocalFireDepartmentRoundedIcon sx={{ fontSize: 40, color: 'white' }} />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={isMobile ? '0.7rem' : '0.8rem'}
                        sx={{ color: 'white', textAlign: 'center' }}>
                        X N
                        <br />
                        Thrust
                      </Typography>
                    </BirkelandCard>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <BirkelandCard sx={{ p: 2, height: isMobile ? 150 : 140 }}>
                      <HeightRoundedIcon sx={{ fontSize: 40, color: 'white' }} />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={isMobile ? '0.7rem' : '0.8rem'}
                        sx={{ color: 'white', textAlign: 'center' }}>
                        X m <br /> Estimated <br /> Apogee
                      </Typography>
                    </BirkelandCard>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Footer color="#fff" />
        <Box></Box>
      </Box>
    </Box>
  );
};

export default Project2025;
