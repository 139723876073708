import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import HeightRoundedIcon from '@mui/icons-material/HeightRounded';
import RocketLaunchRoundedIcon from '@mui/icons-material/RocketLaunchRounded';
import StraightenRoundedIcon from '@mui/icons-material/StraightenRounded';
import LocalFireDepartmentRoundedIcon from '@mui/icons-material/LocalFireDepartmentRounded';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  Card,
  Container,
  Button,
  Fade,
  Grid,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useRef } from 'react';
import getProjectBackground from './getProjectImg';
import bifrostRocket from '../../assets/images/rocket-projects/2023-bifrost/bifrost_rocket.webp';
import bifrostLogo from '../../assets/images/rocket-projects/2023-bifrost/bifrost_logo.webp';
import bifrostPatch from '../../assets/images/rocket-projects/mission-patches/bifrost_patch.png';
import bifrostFigure from '../../assets/images/rocket-projects/2023-bifrost/bifrost-internal.webp';
import bifrostGroundStation from '../../assets/images/rocket-projects/2023-bifrost/groundstation1.JPG';
import bifrostTestSite from '../../assets/images/rocket-projects/2023-bifrost/test_site3.webp';

import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import { motion, useScroll, useTransform } from 'framer-motion';
import Footer from '../../components/Footer/Footer';
import ReactPlayer from 'react-player';

const BifrostCard = styled(Card)(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: '#ffffff00',
  backgroundImage: 'none',
  backdropFilter: 'blur(12px)',
  boxShadow: theme.shadows[0],
  border: 'solid #7703fc 1px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 4
}));

const BifrostTextBox = styled(Box)(({ theme }) => ({
  backdropFilter: 'blur(12px)',
  width: 'max-content',
  padding: 20,
  borderRadius: 20,
  width: '100%'
}));

const Bifrost = () => {
  const navigate = useNavigate();
  const ref = useRef();
  const { scrollYProgress } = useScroll({ container: ref });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const keyframe00 = 0;
  const keyframe01 = 0.05;
  const keyframe02 = 0.1;
  const keyframe03 = 0.15;
  const keyframe04 = 0.2;
  const keyframe05 = 0.25;
  const keyframe06 = 0.3;
  const keyframe07 = 0.35;
  const keyframe08 = 0.4;
  const keyframe09 = 0.45;
  const keyframe10 = 0.5;
  const keyframe11 = 0.55;
  const keyframe12 = 0.6;
  const keyframe13 = 0.65;
  const keyframe14 = 0.7;
  const keyframe15 = 0.75;
  const keyframe16 = 0.8;
  const keyframe17 = 0.85;

  const paddingAnim1 = useTransform(
    scrollYProgress,
    [keyframe00, keyframe02],
    [isMobile ? '20px' : '80px', isMobile ? '10px' : '40px']
  );
  const paddingTopAnim1 = useTransform(
    scrollYProgress,
    [0, keyframe02],
    [isMobile ? '120px' : '140px', isMobile ? '10px' : '40px']
  );
  const scaleAnim1 = useTransform(
    scrollYProgress,
    [keyframe01, keyframe02, keyframe05, keyframe06],
    ['scale(0.8)', 'scale(3)', 'scale(3)', 'scale(0.8)']
  );
  const widthAnim1 = useTransform(
    scrollYProgress,
    [keyframe01, keyframe02, keyframe06, keyframe07, keyframe08],
    ['30%', '50%', '50%', '100%', '50%']
  );
  const widthAnim2 = useTransform(
    scrollYProgress,
    [keyframe11, keyframe12],
    ['0%', '100%']
  );
  const objectPosAnim1 = useTransform(
    scrollYProgress,
    [keyframe01, keyframe02, keyframe05, keyframe06],
    [
      '50% 0vh',
      isMobile ? '50% -28vh' : '50% -30vh',
      isMobile ? '50% -28vh' : '50% -30vh',
      '50% 0vh'
    ]
  );
  const leftAnim1 = useTransform(
    scrollYProgress,
    [keyframe07, keyframe08],
    ['0%', '50%']
  );
  const bottomAnim1 = useTransform(
    scrollYProgress,
    [keyframe16, keyframe17],
    ['50%', '-1%']
  );
  const bottomAnim2 = useTransform(
    scrollYProgress,
    [keyframe16, keyframe17],
    ['50%', '101%']
  );

  const opacityAnim1 = useTransform(
    scrollYProgress,
    [keyframe00, keyframe01],
    [1, 0]
  );
  const opacityAnim2 = useTransform(
    scrollYProgress,
    [keyframe01, keyframe02],
    [0, 1]
  );
  const opacityAnim3 = useTransform(
    scrollYProgress,
    [keyframe02, keyframe03],
    [0, 1]
  );
  const opacityAnim4 = useTransform(
    scrollYProgress,
    [keyframe03, keyframe04],
    [0, 1]
  );
  const opacityAnim5 = useTransform(
    scrollYProgress,
    [keyframe05, keyframe06],
    [1, 0]
  );
  const opacityAnim6 = useTransform(
    scrollYProgress,
    [keyframe07, keyframe08],
    [0, 1]
  );
  const opacityAnim7 = useTransform(
    scrollYProgress,
    [keyframe08, keyframe09, keyframe10, keyframe11],
    [0, 1, 1, 0]
  );
  const opacityAnim8 = useTransform(
    scrollYProgress,
    [keyframe10, keyframe11],
    [1, 0]
  );
  const opacityAnim9 = useTransform(
    scrollYProgress,
    [keyframe12, keyframe13, keyframe15, keyframe16],
    [0, 1, 1, 0]
  );
  const opacityAnim10 = useTransform(
    scrollYProgress,
    [keyframe13, keyframe14, keyframe15, keyframe16],
    [0, 1, 1, 0]
  );
  const opacityAnim11 = useTransform(
    scrollYProgress,
    [keyframe16, keyframe17],
    [1, 0]
  );

  return (
    <Fade in>
      <Box>
        {/*  Background */}
        <img
          src={getProjectBackground('Bifrost')}
          style={{
            position: 'fixed',
            top: 0,
            zIndex: -100,
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            background:
              'linear-gradient(0deg, rgba(230,230,230,1) 0%, rgba(255,255,255,1) 100%)'
          }}
        />
        {/*  Motion Box */}
        {isMobile ? (
          <motion.div
            style={{
              width: '100%',
              height: '90%',
              position: 'absolute',
              top: '45px',
              bottom: '0px',
              left: '0px',
              right: '0px',
              padding: paddingAnim1,
              paddingTop: paddingTopAnim1,
              opacity: opacityAnim11,
              zIndex: -99
            }}
          >
            <Box
              width="100%"
              height="100%"
              sx={{
                border: '2px solid white',
                borderRadius: 6,
                overflow: 'hidden',
                backdropFilter: 'blur(12px)',
                position: 'relative'
              }}
            >
              <motion.div
                className="ImageBox"
                style={{
                  position: 'absolute',
                  left: leftAnim1,
                  width: widthAnim1,
                  opacity: opacityAnim8,
                  height: '100%',
                  borderRight: '1px solid white',
                  borderLeft: '1px solid white',
                  overflow: 'hidden'
                }}
              >
                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%'
                  }}
                >
                  <motion.img
                    src={bifrostRocket}
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      objectPosition: objectPosAnim1,
                      transform: scaleAnim1
                    }}
                  />
                  <motion.img
                    src={bifrostRocket}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      objectPosition: objectPosAnim1,
                      transform: scaleAnim1,
                      opacity: opacityAnim2
                    }}
                  />
                  <motion.img
                    src={bifrostFigure}
                    style={{
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      height: '100%',
                      width: '100%',
                      objectFit: 'contain',
                      background: 'white',
                      padding: theme.spacing(2),
                      opacity: opacityAnim6
                    }}
                  />
                </div>
              </motion.div>
              <motion.div
                style={{
                  position: 'absolute',
                  display: 'flex',
                  flexDirection: 'column',
                  right: 0,
                  width: '70%',
                  opacity: opacityAnim1,
                  height: '100%'
                }}
              >
                <Box display="flex" borderBottom="1px solid white">
                  <Box
                    component="img"
                    src={bifrostLogo}
                    height={120}
                    p={2}
                    borderRight="1px solid white"
                  />
                  <Box
                    flex={1}
                    p={1}
                    gap={1}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Typography
                      variant="h1"
                      fontWeight={600}
                      fontSize="1rem"
                      color="white"
                    >
                      PROJECT <br />
                      BIFROST
                    </Typography>
                    <Typography
                      variant="h2"
                      fontWeight={600}
                      fontSize="0.7rem"
                      color="white"
                    >
                      TEAM 2023
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  variant="body1"
                  color="white"
                  fontSize="0.6rem"
                  sx={{ p: 1, borderBottom: '1px solid white' }}
                >
                  Bifrost represented a significant leap forward, not only being
                  our first rocket using a self-developed engine, but also our
                  first bi-propellant liquid engine. In October 2023, we
                  competed in EuRoC in Portugal in the 3000m Liquid category.
                  Bifrost shot off with an offrail velocity of 32 meters per
                  second and reached an apogee of ca 800 m, achieving the best
                  flight performance in it&apos;s category.
                </Typography>
                <Box flex={1} display="flex">
                  <Box width="50%" borderRight="1px solid white" height="100%">
                    <Box
                      height="50%"
                      borderBottom="1px solid white"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <RocketLaunchRoundedIcon
                        sx={{ fontSize: 20, color: 'white' }}
                      />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize="0.6rem"
                        sx={{ color: 'white', textAlign: 'center' }}
                      >
                        Ethanol & liquid
                        <br />
                        Nitrous Oxide
                        <br />
                        Propellants
                      </Typography>
                    </Box>
                    <Box
                      height="50%"
                      borderBottom="1px solid white"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <StraightenRoundedIcon
                        sx={{ fontSize: 20, color: 'white' }}
                      />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize="0.6rem"
                        sx={{ color: 'white', textAlign: 'center' }}
                      >
                        4.9 m
                        <br />
                        Length
                      </Typography>
                    </Box>
                  </Box>
                  <Box width="50%" height="100%">
                    <Box
                      height="50%"
                      borderBottom="1px solid white"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <LocalFireDepartmentRoundedIcon
                        sx={{ fontSize: 20, color: 'white' }}
                      />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize="0.6rem"
                        sx={{ color: 'white', textAlign: 'center' }}
                      >
                        5000 N
                        <br />
                        Thrust
                      </Typography>
                    </Box>
                    <Box
                      height="50%"
                      borderBottom="1px solid white"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <HeightRoundedIcon
                        sx={{ fontSize: 20, color: 'white' }}
                      />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize="0.6rem"
                        sx={{ color: 'white', textAlign: 'center' }}
                      >
                        800 m
                        <br />
                        Apogee
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </motion.div>
              <motion.div
                style={{
                  position: 'absolute',
                  display: 'flex',
                  flexDirection: 'column',
                  right: 0,
                  width: '50%',
                  height: '100%',
                  opacity: opacityAnim5
                }}
              >
                <motion.div
                  style={{
                    padding: theme.spacing(2),
                    height: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    color: 'white',
                    borderTop: '1px solid white',
                    opacity: opacityAnim3
                  }}
                >
                  <Typography variant="h2" fontWeight={600} fontSize="1rem">
                    Engineering breakdown
                  </Typography>
                  <Typography variant="body1" fontSize="0.6rem" mt={1}>
                    Bifrost&apos;s bi-liquid propulsion system was fully
                    researched and developed in-house, using knowledge and test
                    facilities from our previous Hybrid engine R&D project. The
                    airframe was in large a flight-proven design previously used
                    on Stetind (2021) and Birkeland (2022), with the SRAD
                    recovery system and avionics also built upon previous
                    years&apos; designs. You can find a more detailed
                    description below.
                  </Typography>
                </motion.div>
                <motion.div
                  style={{
                    padding: theme.spacing(2),
                    height: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    color: 'white',
                    borderTop: '1px solid white',
                    opacity: opacityAnim4
                  }}
                >
                  <Typography variant="h2" fontWeight={600} fontSize="1rem">
                    Hotfire #1
                  </Typography>
                  <Typography variant="body1" fontSize="0.6rem" mt={1}>
                    On 11th of March we conducted the first hotfire test on the
                    prototype bi-propellant liquid engine, shown in the video at
                    the end of the page. The prototype has a steel uncooled
                    chamber with throat, and the test was a success. <br />
                    <br /> Results: <br /> 3.5 kN Thrust <br />
                    1 second Preburn <br />2 seconds Full Burn
                  </Typography>
                </motion.div>
              </motion.div>
              <motion.div
                style={{
                  position: 'absolute',
                  padding: theme.spacing(2),
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  left: 0,
                  width: '50%',
                  height: '100%',
                  opacity: opacityAnim7,
                  color: 'white'
                }}
              >
                <Typography variant="h2" fontWeight={700} fontSize="0.8rem">
                  Outer Structure
                </Typography>
                <Typography variant="body1" fontSize="0.5rem" mb={1}>
                  The airframe is load-bearing and the lower half is filament
                  wound carbon fiber, while the upper sections are glass fiber
                  due to avionic’s requirement of radio transparency. Four
                  carbon-fiber fins ensure proper vehicle stability.
                </Typography>
                <Typography variant="h2" fontWeight={700} fontSize="0.8rem">
                  Inner Structure
                </Typography>
                <Typography variant="body1" fontSize="0.5rem" mb={1}>
                  Designed with modularity and assembly in mind, while still
                  handling flight forces and minimizing the mass.
                </Typography>
                <Typography variant="h2" fontWeight={700} fontSize="0.8rem">
                  Payload
                </Typography>
                <Typography variant="body1" fontSize="0.5rem" mb={1}>
                  Biosat, a 3U CubeSat made by our friends at Orbit NTNU.
                </Typography>
                <Typography variant="h2" fontWeight={700} fontSize="0.8rem">
                  Recovery
                </Typography>
                <Typography variant="body1" fontSize="0.5rem" mb={1}>
                  Ensures successful recovery of the rocket with two self-sewn
                  parachutes. The separation of the forward and aft airframe is
                  done by filling the airtight chute bay with pressurized gas.
                  The recovery functions can be activated by both our
                  self-designed system and a COTS system.
                </Typography>
                <Typography variant="h2" fontWeight={700} fontSize="0.8rem">
                  Feed System
                </Typography>
                <Typography variant="body1" fontSize="0.5rem" mb={1}>
                  Supplies the combustion chamber with fuel and oxidiser,
                  allowing the engine to generate thrust once ignited. The
                  system consists of propellant tanks, a nitrogen pressurization
                  system, and plenty of valves and piping to enable filling,
                  control and emptying of the tanks.
                </Typography>
                <Typography variant="h2" fontWeight={700} fontSize="0.8rem">
                  Combustion Chamber
                </Typography>
                <Typography variant="body1" fontSize="0.5rem">
                  As the most complex part of the rocket, the combustion chamber
                  is where the propellants are injected and burned to provide
                  thrust. The system consists of a 3D printed burn chamber and
                  bulkhead, an injector plate and an exit nozzle.
                </Typography>
              </motion.div>
              <motion.div
                style={{
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  top: 0,
                  width: '100%',
                  height: '50%',
                  opacity: opacityAnim9,
                  color: 'white'
                }}
              >
                <Box flex={1} p={2}>
                  <Typography
                    variant="h2"
                    fontWeight={700}
                    fontSize="1rem"
                    mb={1}
                  >
                    Ground Station & Radio Tracker
                  </Typography>
                  <Typography variant="body1" fontSize="0.6rem">
                    Receives and stores telemetry data from the rocket, as well
                    as arming the software and enabling the filling process. The
                    radio tracking system keeps the ground radio pointed to the
                    rocket, ensuring an optimal connection and an additional
                    source of position data to aid with recovering the rocket.
                  </Typography>
                </Box>
                <img
                  src={bifrostGroundStation}
                  width="40%"
                  height="100%"
                  style={{ objectFit: 'cover', borderLeft: '1px solid white' }}
                />
              </motion.div>
              <motion.div
                style={{
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  bottom: 0,
                  width: '100%',
                  height: '50%',
                  opacity: opacityAnim10,
                  borderBottom: '1px solid white',
                  color: 'white'
                }}
              >
                <img
                  src={bifrostTestSite}
                  width="40%"
                  height="100%"
                  style={{ objectFit: 'cover', borderRight: '1px solid white' }}
                />
                <Box flex={1} p={2}>
                  <Typography
                    variant="h2"
                    fontWeight={700}
                    fontSize="1rem"
                    mb={1}
                  >
                    Test Site
                  </Typography>
                  <Typography variant="body1" fontSize="0.6rem">
                    The facilities & logistics concerned with propulsion testing
                    in a controlled environment. Initial hardware is tested in a
                    horizontal configuration, with the combustion chamber
                    parallel to the ground. New generations of flight hardware
                    will eventually be tested in a vertical configuration.
                  </Typography>
                </Box>
              </motion.div>
              <motion.div
                style={{
                  position: 'absolute',
                  bottom: bottomAnim1,
                  width: widthAnim2,
                  height: '1px',
                  backgroundColor: 'white'
                }}
              />
              <motion.div
                style={{
                  position: 'absolute',
                  bottom: bottomAnim2,
                  width: widthAnim2,
                  height: '1px',
                  backgroundColor: 'white'
                }}
              />
            </Box>
          </motion.div>
        ) : (
          <motion.div
            style={{
              width: '100%',
              height: '95%',
              position: 'absolute',
              top: '20px',
              bottom: '0px',
              left: '0px',
              right: '0px',
              padding: paddingAnim1,
              paddingTop: paddingTopAnim1,
              opacity: opacityAnim11,
              zIndex: -99
            }}
          >
            <Box
              width="100%"
              height="100%"
              sx={{
                border: '2px solid white',
                borderRadius: isMobile ? 6 : 12,
                overflow: 'hidden',
                backdropFilter: 'blur(12px)',
                position: 'relative'
              }}
            >
              <motion.div
                className="ImageBox"
                style={{
                  position: 'absolute',
                  left: leftAnim1,
                  width: widthAnim1,
                  opacity: opacityAnim8,
                  height: '100%',
                  borderRight: '1px solid white',
                  borderLeft: '1px solid white',
                  overflow: 'hidden'
                }}
              >
                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%'
                  }}
                >
                  <motion.img
                    src={bifrostRocket}
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      objectPosition: objectPosAnim1,
                      transform: scaleAnim1
                    }}
                  />
                  <motion.img
                    src={bifrostRocket}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      objectPosition: objectPosAnim1,
                      transform: scaleAnim1,
                      opacity: opacityAnim2
                    }}
                  />
                  <motion.img
                    src={bifrostFigure}
                    style={{
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      height: '100%',
                      width: '100%',
                      objectFit: 'contain',
                      background: 'white',
                      padding: theme.spacing(2),
                      opacity: opacityAnim6
                    }}
                  />
                </div>
              </motion.div>
              <motion.div
                style={{
                  position: 'absolute',
                  display: 'flex',
                  flexDirection: 'column',
                  right: 0,
                  width: '70%',
                  opacity: opacityAnim1,
                  height: '100%'
                }}
              >
                <Box display="flex" borderBottom="1px solid white">
                  <Box
                    component="img"
                    src={bifrostLogo}
                    height={250}
                    p={{ xs: 2, xl: 4 }}
                    borderRight="1px solid white"
                  />
                  <Box
                    flex={1}
                    p={{ xs: 2, xl: 4 }}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    gap={2}
                  >
                    <Typography
                      variant="h1"
                      fontWeight={600}
                      fontSize="3rem"
                      color="white"
                    >
                      PROJECT <br />
                      BIFROST
                    </Typography>
                    <Typography
                      variant="h2"
                      fontWeight={600}
                      fontSize="1.5rem"
                      color="white"
                    >
                      TEAM 2023
                    </Typography>
                  </Box>
                </Box>
                <Grid container flex={1}>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    borderRight="1px solid white"
                    display="flex"
                    alignItems="center"
                  >
                    <Typography
                      variant="body1"
                      color="white"
                      fontSize={{ xs: '0.8rem', xl: '0.9rem' }}
                      sx={{ p: 2 }}
                    >
                      Bifrost represented a significant leap forward, not only
                      being our first rocket using a self-developed engine, but
                      also our first bi-propellant liquid engine. In October
                      2023, we competed in EuRoC in Portugal in the 3000m Liquid
                      category. Bifrost shot off with an offrail velocity of 32
                      meters per second and reached an apogee of ca 800 m,
                      achieving the best flight performance in it&apos;s
                      category.
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={4} borderRight="1px solid white">
                    <Box
                      height="50%"
                      borderBottom="1px solid white"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 4
                      }}
                    >
                      <RocketLaunchRoundedIcon
                        sx={{ fontSize: 60, color: 'white' }}
                      />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize="1rem"
                        sx={{ color: 'white', textAlign: 'center' }}
                      >
                        Ethanol & liquid
                        <br />
                        Nitrous Oxide
                        <br />
                        Propellants
                      </Typography>
                    </Box>
                    <Box
                      height="50%"
                      borderBottom="1px solid white"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 2
                      }}
                    >
                      <StraightenRoundedIcon
                        sx={{ fontSize: 60, color: 'white' }}
                      />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize="1rem"
                        sx={{ color: 'white', textAlign: 'center' }}
                      >
                        4.9 m
                        <br />
                        Length
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Box
                      height="50%"
                      borderBottom="1px solid white"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 2
                      }}
                    >
                      <LocalFireDepartmentRoundedIcon
                        sx={{ fontSize: 60, color: 'white' }}
                      />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize="1rem"
                        sx={{ color: 'white', textAlign: 'center' }}
                      >
                        5000 N
                        <br />
                        Thrust
                      </Typography>
                    </Box>
                    <Box
                      height="50%"
                      borderBottom="1px solid white"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 2
                      }}
                    >
                      <HeightRoundedIcon
                        sx={{ fontSize: 60, color: 'white' }}
                      />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize="1rem"
                        sx={{ color: 'white', textAlign: 'center' }}
                      >
                        800 m
                        <br />
                        Apogee
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </motion.div>
              <motion.div
                style={{
                  position: 'absolute',
                  display: 'flex',
                  flexDirection: 'column',
                  right: 0,
                  width: '50%',
                  height: '100%',
                  opacity: opacityAnim5
                }}
              >
                <motion.div
                  style={{
                    padding: theme.spacing(4),
                    height: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    color: 'white',
                    borderTop: '1px solid white',
                    opacity: opacityAnim3
                  }}
                >
                  <Typography variant="h2" fontWeight={600} fontSize="3rem">
                    Engineering breakdown
                  </Typography>
                  <Typography variant="body1" fontSize="1rem" mt={2}>
                    Bifrost&apos;s bi-liquid propulsion system was fully
                    researched and developed in-house, using knowledge and test
                    facilities from our previous Hybrid engine R&D project. The
                    airframe was in large a flight-proven design previously used
                    on Stetind (2021) and Birkeland (2022), with the SRAD
                    recovery system and avionics also built upon previous
                    years&apos; designs. You can find a more detailed
                    description below.
                  </Typography>
                </motion.div>
                <motion.div
                  style={{
                    padding: theme.spacing(4),
                    height: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    color: 'white',
                    borderTop: '1px solid white',
                    opacity: opacityAnim4
                  }}
                >
                  <Typography variant="h2" fontWeight={600} fontSize="3rem">
                    Hotfire #1
                  </Typography>
                  <Typography variant="body1" fontSize="1rem" mt={2}>
                    On 11th of March we conducted the first hotfire test on the
                    prototype bi-propellant liquid engine, shown in the video
                    above. The prototype has a steel uncooled chamber with
                    throat, and the test was a success. <br />
                    <br /> Results: <br /> 3.5 kN Thrust <br />
                    1 second Preburn <br />2 seconds Full Burn
                  </Typography>
                </motion.div>
              </motion.div>
              <motion.div
                style={{
                  position: 'absolute',
                  padding: theme.spacing(4),
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  left: 0,
                  width: '50%',
                  height: '100%',
                  opacity: opacityAnim7,
                  color: 'white'
                }}
              >
                <Typography variant="h2" fontWeight={700} fontSize="1rem">
                  Outer Structure
                </Typography>
                <Typography variant="body1" fontSize="0.9rem" mb={2}>
                  The airframe is load-bearing and the lower half is filament
                  wound carbon fiber, while the upper sections are glass fiber
                  due to avionic&apos;s requirement of radio transparency. Four
                  carbon-fiber fins ensures proper vehicle stability.
                </Typography>
                <Typography variant="h2" fontWeight={700} fontSize="1rem">
                  Inner Structure
                </Typography>
                <Typography variant="body1" fontSize="0.9rem" mb={2}>
                  Designed with modularity and assembly in mind, while still
                  handling flight forces and minimizing the mass.
                </Typography>
                <Typography variant="h2" fontWeight={700} fontSize="1rem">
                  Payload
                </Typography>
                <Typography variant="body1" fontSize="0.9rem" mb={2}>
                  Biosat, a 3U CubeSat made by our friends at Orbit NTNU.
                </Typography>
                <Typography variant="h2" fontWeight={700} fontSize="1rem">
                  Recovery
                </Typography>
                <Typography variant="body1" fontSize="0.9rem" mb={2}>
                  Designed to ensure successful recovery of the rocket with two
                  self-sewn parachutes. The separation of the forward and aft
                  airframe is done by filling the airtight chute bay with
                  pressurized gas. The recovery functions can be activated by
                  both our self-designed system and a COTS system.
                </Typography>
                <Typography variant="h2" fontWeight={700} fontSize="1rem">
                  Feed System
                </Typography>
                <Typography variant="body1" fontSize="0.9rem" mb={2}>
                  Supplies the combustion chamber with fuel and oxidiser,
                  allowing the engine to generate thrust once ignited. The
                  system consists of propellant tanks, a nitrogen pressurization
                  system, and plenty of valves and piping to enable filling,
                  control and emptying of the tanks.
                </Typography>
                <Typography variant="h2" fontWeight={700} fontSize="1rem">
                  Combustion Chamber
                </Typography>
                <Typography variant="body1" fontSize="0.9rem">
                  As the most complex part of the rocket, the combustion chamber
                  is where the propellants are injected and burned to provide
                  thrust. The system consists of a 3D printed burn chamber and
                  bulkhead, an injector plate and an exit nozzle.
                </Typography>
              </motion.div>
              <motion.div
                style={{
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  top: 0,
                  width: '100%',
                  height: '50%',
                  opacity: opacityAnim9,
                  color: 'white'
                }}
              >
                <Box flex={1} p={4}>
                  <Typography
                    variant="h2"
                    fontWeight={700}
                    fontSize="2rem"
                    mb={2}
                  >
                    Ground Station & Radio Tracker
                  </Typography>
                  <Typography variant="body1" fontSize="1rem">
                    Receives and stores telemetry data from the rocket, as well
                    as arming the software and enabling the filling process. The
                    radio tracking system keeps the ground radio pointed to the
                    rocket, ensuring an optimal connection and an additional
                    source of position data to aid with recovering the rocket.
                  </Typography>
                </Box>
                <img
                  src={bifrostGroundStation}
                  width="40%"
                  height="100%"
                  style={{ objectFit: 'cover', borderLeft: '1px solid white' }}
                />
              </motion.div>
              <motion.div
                style={{
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  bottom: 0,
                  width: '100%',
                  height: '50%',
                  opacity: opacityAnim10,
                  borderBottom: '1px solid white',
                  color: 'white'
                }}
              >
                <img
                  src={bifrostTestSite}
                  width="40%"
                  height="100%"
                  style={{ objectFit: 'cover', borderRight: '1px solid white' }}
                />
                <Box flex={1} p={4}>
                  <Typography
                    variant="h2"
                    fontWeight={700}
                    fontSize="2rem"
                    mb={2}
                  >
                    Test Site
                  </Typography>
                  <Typography variant="body1" fontSize="1rem">
                    The facilities & logistics concerned with propulsion testing
                    in a controlled environment. Initial hardware is tested in a
                    horizontal configuration, with the combustion chamber
                    parallel to the ground. New generations of flight hardware
                    will eventually be tested in a vertical configuration.
                  </Typography>
                </Box>
              </motion.div>
              <motion.div
                style={{
                  position: 'absolute',
                  bottom: bottomAnim1,
                  width: widthAnim2,
                  height: '1px',
                  backgroundColor: 'white'
                }}
              />
              <motion.div
                style={{
                  position: 'absolute',
                  bottom: bottomAnim2,
                  width: widthAnim2,
                  height: '1px',
                  backgroundColor: 'white'
                }}
              />
            </Box>
          </motion.div>
        )}

        {/* Content */}
        <Box
          ref={ref}
          sx={{
            height: '100vh',
            overflowY: 'scroll',
            '& .MuiTypography-root': {
              pointerEvents: 'none'
            }
          }}
        >
          <Header
            sx={{
              position: 'static',
              width: '100%',
              zIndex: -10
            }}
          />
          <Grid item xs={12}>
            <Button
              onClick={() => {
                navigate('/Projects');
              }}
              disableRipple
              variant="outlined"
              color="inherit"
              startIcon={<ArrowBackIosNewIcon />}
              sx={{
                ml: { xs: 3, md: 10 },
                mb: 4,
                borderRadius: 12,
                color: 'white'
              }}
            >
              Back to Projects
            </Button>
          </Grid>

          <Box sx={{ height: '600vh', width: '100%' }}></Box>

          {/*  Ending Card */}
          <Box sx={{ p: { xs: 4, md: 10 } }}>
            <Grid
              container
              sx={{
                width: '100%',
                border: '2px solid white',
                boxSizing: 'border-box',
                borderRadius: 10,
                minHeight: '90vh',
                overflow: 'hidden',
                backdropFilter: 'blur(12px)'
              }}
            >
              <Grid
                item
                xs={6}
                md={4}
                lg={4}
                display="flex"
                flexDirection="column"
                sx={{ borderRight: '1px solid white' }}
              >
                <Box
                  sx={{ p: isMobile ? 2 : 4, borderBottom: '1px solid white' }}
                >
                  <Typography
                    variant="h4"
                    color="white"
                    fontWeight={600}
                    fontSize={isMobile ? '1rem' : '2rem'}
                    sx={{ borderRight: '10px solid #7703fc' }}
                  >
                    PROJECT <br /> BIFROST
                  </Typography>
                </Box>
                <Box
                  flex={4}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ borderBottom: '1px solid white', p: isMobile ? 2 : 4 }}
                >
                  <img src={bifrostRocket} style={{ maxHeight: 600 }} />
                </Box>
              </Grid>

              <Grid
                item
                xs={6}
                md={4}
                lg={3}
                container
                direction="column"
                sx={{ borderRight: isMobile ? 'none' : '1px solid white' }}
              >
                <Grid
                  item
                  xs={4}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ borderBottom: '1px solid white', p: isMobile ? 2 : 4 }}
                >
                  <Box
                    sx={{
                      height: '100%',
                      width: '100%',
                      backgroundImage: `url(${bifrostPatch})`,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat'
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  gap={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    borderBottom: '1px solid white',
                    p: isMobile ? 1 : 1.5
                  }}
                >
                  <RocketLaunchRoundedIcon
                    sx={{ fontSize: isMobile ? 30 : 40, color: 'white' }}
                  />
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    fontSize={isMobile ? '0.7rem' : '1rem'}
                    sx={{ color: 'white', textAlign: 'center' }}
                  >
                    Ethanol & liquid
                    <br />
                    Nitrous Oxide
                    <br />
                    Propellants
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  gap={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    borderBottom: '1px solid white',
                    p: isMobile ? 1 : 1.5
                  }}
                >
                  <StraightenRoundedIcon
                    sx={{ fontSize: isMobile ? 30 : 40, color: 'white' }}
                  />
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    fontSize={isMobile ? '0.7rem' : '1rem'}
                    sx={{ color: 'white', textAlign: 'center' }}
                  >
                    4.9 m
                    <br />
                    Length
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  gap={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    borderBottom: '1px solid white',
                    p: isMobile ? 1 : 1.5
                  }}
                >
                  <LocalFireDepartmentRoundedIcon
                    sx={{ fontSize: isMobile ? 30 : 40, color: 'white' }}
                  />
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    fontSize={isMobile ? '0.7rem' : '1rem'}
                    sx={{ color: 'white', textAlign: 'center' }}
                  >
                    5000 N
                    <br />
                    Thrust
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  gap={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    borderBottom: '1px solid white',
                    p: isMobile ? 1 : 1.5
                  }}
                >
                  <HeightRoundedIcon
                    sx={{ fontSize: isMobile ? 30 : 40, color: 'white' }}
                  />
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    fontSize={isMobile ? '0.7rem' : '1rem'}
                    sx={{ color: 'white', textAlign: 'center' }}
                  >
                    800 m
                    <br />
                    Apogee
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} md={4} lg={5} container direction="column">
                <Grid
                  item
                  xs={4}
                  display="flex"
                  flexDirection="column"
                  sx={{ width: '100%', borderBottom: '1px solid white' }}
                >
                  <Typography
                    variant="h6"
                    color="white"
                    fontWeight={500}
                    fontSize={isMobile ? '1em' : '1.5rem'}
                    sx={{ borderBottom: '1px solid white', p: 2 }}
                  >
                    Hotfire #1 Video
                  </Typography>
                  <Box sx={{ height: '100%' }}>
                    <ReactPlayer
                      url="https://www.youtube.com/watch?v=CQllm9cbUIM"
                      width="100%"
                      height="100%"
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  display="flex"
                  flexDirection="column"
                  sx={{ width: '100%', borderBottom: '1px solid white' }}
                >
                  <Typography
                    variant="h6"
                    color="white"
                    fontWeight={500}
                    fontSize={isMobile ? '1em' : '1.5rem'}
                    sx={{ borderBottom: '1px solid white', p: 2 }}
                  >
                    Static Fire
                  </Typography>
                  <Box sx={{ height: '100%' }}>
                    <ReactPlayer
                      url="https://www.youtube.com/watch?v=ta3bBOKsZCY"
                      width="100%"
                      height="100%"
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={2}
                  component="a"
                  href="https://drive.google.com/file/d/1qoPLVJreVJVfzt83ld4Hf5VG7saDnWdd/view"
                  target="_blank"
                  gap={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    color: 'white',
                    borderBottom: '1px solid white',
                    p: 2,
                    cursor: 'pointer',
                    '&:hover': {
                      bgcolor: 'white',
                      '& .MuiTypography-root': { color: 'black' },
                      '& .MuiSvgIcon-root': { color: 'black' }
                    }
                  }}
                >
                  <OpenInNewIcon />
                  <Typography
                    variant="h5"
                    color="white"
                    fontWeight={600}
                    fontSize={isMobile ? '1em' : '1.5rem'}
                  >
                    Technical Report
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={2}
                  onClick={() => {
                    navigate('/Projects');
                  }}
                  gap={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    color: 'white',
                    p: isMobile ? 2 : 4,
                    cursor: 'pointer',
                    '&:hover': {
                      bgcolor: 'white',
                      color: 'black',
                      '& .MuiTypography-root': { color: 'black' },
                      '& .MuiSvgIcon-root': { color: 'black' }
                    }
                  }}
                >
                  <ArrowBackIosNewIcon color="inheret" />
                  <Typography
                    variant="h5"
                    color="white"
                    fontWeight={600}
                    fontSize={isMobile ? '1rem' : '1.5rem'}
                  >
                    Back to PROJECTS
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Footer color="#fff" />
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default Bifrost;
