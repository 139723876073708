import { Link, useTheme } from '@mui/material';
import React from 'react';

const PartnerLogo = ({ partner, standardWidth, style }) => {
  const [imgStateLight, setImgStateLight] = React.useState(null);
  const [imgStateDark, setImgStateDark] = React.useState(null);
  // const [imgError, setImgError] = React.useState(false);
  const theme = useTheme();

  const imageLoad = (imgSrc) => {
    import(`../../assets/images/graphical/sponsor-logos/${imgSrc}`)
      .then((image) => {
        theme.palette.mode === 'dark'
          ? setImgStateLight(image.default)
          : setImgStateDark(image.default);
      })
      .catch((error) => {
        console.error('Error loading image: ', error);
      });
  };

  return (
    <Link
      href={partner.url}
      target="_blank"
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="90%"
      sx={style}
    >
      {theme.palette.mode === 'dark' ? (
        <img
          src={imgStateLight ? imgStateLight : imageLoad(partner.logos.light)}
          alt={`${partner.name} logo`}
          width={partner.width ? partner.width : standardWidth}
        />
      ) : (
        <img
          src={imgStateDark ? imgStateDark : imageLoad(partner.logos.dark)}
          alt={`${partner.name} logo`}
          width={partner.width ? partner.width : standardWidth}
        />
      )}
    </Link>
  );
};

export default PartnerLogo;
