import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FastForwardRoundedIcon from '@mui/icons-material/FastForwardRounded';
import HeightRoundedIcon from '@mui/icons-material/HeightRounded';
import RocketLaunchRoundedIcon from '@mui/icons-material/RocketLaunchRounded';
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded';
import {
  Box,
  Button,
  Card,
  Container,
  Fade,
  Grid,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import { Stack } from '@mui/system';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import mjolnerRocket from '../../assets/images/rocket-projects/2020-mjoelner/mjolner_rocket.png';
import Video from '../../assets/videos/Birkeland-launch-short.mov';
import Header from '../../components/Header/Header';
import getProjectBackground from './getProjectImg';

const MjolnerCard = styled(Card)(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: '#ffffff00',
  backgroundImage: 'none',
  backdropFilter: 'blur(12px)',
  boxShadow: theme.shadows[0],
  border: 'solid #278EFF 1px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 4
}));

const Mjolner = () => {
  const navigate = useNavigate();
  return (
    <Fade in>
      <Box
        sx={{
          scrollSnapType: 'y mandatory',
          scrollSnapStop: 'always',
          height: '100vh',
          overflow: 'auto'
        }}
      >
        <Box
          component="img"
          src={getProjectBackground('Mjolner')}
          sx={{
            position: 'fixed',
            top: 0,
            zIndex: -1,
            objectFit: 'cover',
            width: '100%',
            height: '100vh',
            background:
              'linear-gradient(0deg, rgba(230,230,230,1) 0%, rgba(255,255,255,1) 100%)'
          }}
        />
        <Box sx={{ scrollSnapAlign: 'end' }}>
          <Header
            sx={{
              width: '100%',
              zIndex: 1000
            }}
          />
          <Container maxWidth="lg">
            <Grid container sx={{ px: 4, pb: 8 }}>
              <Grid xs={12}>
                <Button
                  onClick={() => {
                    navigate('/Projects');
                  }}
                  disableRipple
                  variant="outlined"
                  color="inherit"
                  startIcon={<ArrowBackIosNewIcon />}
                  sx={{ mb: 4, borderRadius: 12, color: '#278EFF' }}
                >
                  Back to Projects
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                component="img"
                src={mjolnerRocket}
                sx={{
                  height: 800,
                  objectFit: 'contain',
                  objectPosition: 'center'
                }}
              />
              <Grid item xs={12} md={8}>
                <Grid container spacing={{ xs: 2, md: 4 }}>
                  <Grid
                    item
                    xs={12}
                    component={Stack}
                    direction="column"
                    gap={2}
                  >
                    <Typography
                      variant="h2"
                      color="white"
                      fontWeight={700}
                      fontSize={{ xs: 40, md: 60 }}
                      sx={{ mt: { xs: 4, md: 0 } }}
                    >
                      PROJECT <br /> MJØLNER
                    </Typography>
                    <Typography variant="h4" color="white" fontWeight={600}>
                      TEAM 2020
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'white' }}>
                      Team 2020 of Propulse were to compete in the SRAD 30K
                      class of Spaceport America Cup 2020. The goal was to
                      integrate the ongoing hybrid-project into the competing
                      rocket, called Mjølner. This required a complete
                      restructuring of the organization to develop the rocket,
                      which would stand around 5 meters tall and 18cm wide when
                      finished. The rocket was designed to reach supersonic
                      speeds with 5000N of thrust over 8 seconds, utilizing
                      nitrous oxide and paraffin wax as fuel. Mjølner featured
                      many SRAD components, such as a five layered PCB-stack.
                      All components and subsystems were attached to a modular
                      load bearing skeleton structure of aluminium, allowing for
                      quick parallel development of components and subsystems.
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'white' }}>
                      Due to delayed development of the SRAD engine, the team
                      changed to compete in the 30K COTS class with an Cesaroni
                      Pro150 engine. This downscaled the size of the rocket
                      substantially. Due to the COVID-19 pandemic, the rocket
                      was never finished - despite many of the parts being
                      produced.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    component={Stack}
                    direction="column"
                    gap={2}
                  >
                    <Typography variant="h5" color="white" fontWeight={600}>
                      Payload
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'white' }}>
                      The payload was a reaction wheel experiment, investigating
                      the development of a control system that would manipulate
                      the orientation of a free standing body. This type of
                      system is often used in satellites.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </Fade>
  );
};

export default Mjolner;
