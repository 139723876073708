import { Card, styled } from '@mui/material';

import React from 'react';

const StyledCard = styled(Card)`
  border-radius: 20px;
  box-shadow: none;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
`;

const PropulseCard = (props) => {
  return <StyledCard {...props}>{props.children}</StyledCard>;
};

export default PropulseCard;
