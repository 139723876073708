import { useTheme } from '@mui/material';

import React from 'react';

function ApplicationBackground() {
  const theme = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
      <g id="Layer_5" data-name="Layer 5">
        <path
          d="m1201.4,338.08v-141.55l403.43-403.43h.21s140.28,1.06,140.28,1.06l-.84.84-543.07,543.07Zm1-141.14v138.73L1742.92-204.85l-137.68-1.04-402.84,402.84Z"
          fill={theme.palette.divider}
        />
        <path
          d="m16.62,1339.22h-191.1l732.35-732.35-1.34,192.44-.15.14L16.62,1339.22Zm-188.69-1H16.21l539.32-539.32,1.32-189.59L-172.06,1338.22Z"
          fill={theme.palette.divider}
        />
        <path
          d="m754.34,1183.85h-.25s-56.34-.36-56.34-.36l301.49-301.49v56.95l-244.9,244.9Zm-53.69-1.55l53.19.34,244.19-244.19v-53.53l-297.38,297.38Z"
          fill={theme.palette.divider}
        />
        <path
          d="m1803,656.56v-39.79l404.93-404.93.18-.02,44.22-4.58-449.32,449.32Zm1-39.38v36.97l445.62-445.63-41.24,4.27-404.39,404.39Z"
          fill={theme.palette.divider}
        />
        <path
          d="m-30.42,585.23v-61.91l.56.56,214.46-214.46.3-.41,30.6-30.6v61.91l-.56-.56L.48,554.22l-.3.41-30.6,30.6Zm1-60.38v57.96l28.54-28.54.3-.41,215.08-215.08v-57.96l-28.54,28.54-.3.41L-29.42,524.86Z"
          fill={theme.palette.divider}
        />
        <path
          d="m730.42,183.63v-73.76l.57.56,257.34-257.34.28-.42,36.53-36.53v73.77l-.57-.58-257.34,257.34-.29.42-36.53,36.53Zm1-72.21v69.79l34.47-34.47.29-.42,257.97-257.97v-69.8l-34.47,34.47-.28.42L731.42,111.42Z"
          fill={theme.palette.divider}
        />
        <path
          d="m-71.41,1144.83v-107.67l.6.6,379.99-379.99-.1-.1,53.84-53.84v107.67l-.6-.6L-17.67,1090.89l.1.1-53.84,53.84Zm1-106.05v103.64l51.42-51.42-.1-.1,381.01-381.01v-103.64l-51.42,51.42.1.1-381.01,381.01Z"
          fill={theme.palette.divider}
        />
        <path
          d="m1490.63,152.5v-26.75l.52.52,87.33-87.33.33-.38,405.54-405.54v25.75l-493.72,493.72Zm1-25.29v22.88l491.72-491.72v-22.93L1579.87,38.92l-.33.38-87.91,87.91Z"
          fill={theme.palette.divider}
        />
      </g>
      <g id="Layer_4" data-name="Layer 4">
        <g>
          <path
            d="m1668.8,1115.45c-31.41,0-63.89-8.61-64.23-23.04l-26.07-85.78,6.09,9.88c8.3,13.47,43.72,23.25,84.22,23.25s75.92-9.78,84.22-23.25l6.09-9.88-26.07,85.78c-.34,14.43-32.83,23.04-64.23,23.04Zm-82.06-92.01l20.82,68.51v.22c0,9.79,24.61,20.27,61.23,20.27s61.24-10.48,61.24-20.27v-.22l.06-.21,20.76-68.3c-13.48,11.61-45.31,19.32-82.06,19.32s-68.58-7.71-82.06-19.32Z"
            fill={theme.palette.divider}
          />
          <path
            d="m1668.8,1114.45c-34.87,0-63.23-9.99-63.23-22.27s28.37-22.27,63.23-22.27,63.24,9.99,63.24,22.27-28.37,22.27-63.24,22.27Zm0-43.54c-34.32,0-62.23,9.54-62.23,21.27s27.92,21.27,62.23,21.27,62.24-9.54,62.24-21.27-27.92-21.27-62.24-21.27Z"
            fill={theme.palette.divider}
          />
          <path
            d="m1668.81,958.97c-48.48,0-87.93-13.83-87.93-30.83s39.44-30.83,87.93-30.83,87.93,13.83,87.93,30.83-39.44,30.83-87.93,30.83Zm0-60.66c-47.93,0-86.93,13.38-86.93,29.83s39,29.83,86.93,29.83,86.93-13.38,86.93-29.83-39-29.83-86.93-29.83Z"
            fill={theme.palette.divider}
          />
          <path
            d="m1668.81,944.78c-48.48,0-87.93-13.83-87.93-30.83s39.44-30.83,87.93-30.83,87.93,13.83,87.93,30.83-39.44,30.83-87.93,30.83Zm0-60.66c-47.93,0-86.93,13.38-86.93,29.83s39,29.83,86.93,29.83,86.93-13.38,86.93-29.83-39-29.83-86.93-29.83Z"
            fill={theme.palette.divider}
          />
          <g>
            <path
              d="m1668.12,996.58c-6.9,0-13.53-1.12-19.72-3.33-17.47-6.16-28.77-19.95-28.77-35.15v-140.38c0-21.21,21.75-38.47,48.48-38.47s48.5,17.26,48.5,38.47v140.38c0,15.19-11.3,28.99-28.79,35.15-6.18,2.21-12.82,3.33-19.72,3.33Zm0-216.32c-26.18,0-47.48,16.81-47.48,37.47v140.38c0,14.76,11.03,28.19,28.1,34.21,6.08,2.17,12.6,3.28,19.38,3.28s13.31-1.1,19.38-3.27c17.08-6.02,28.12-19.45,28.12-34.21v-140.38c0-20.66-21.31-37.47-47.5-37.47Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1668.23,1107.28c-15.62,0-31.28-3.63-45.33-10.51-.14-.07-.24-.2-.27-.35-3.33-16.13-3.48-32.14-.42-47.58,2.62-13.24,7.09-23.3,9-27.6,4.46-10.04,10.17-19.72,16.97-28.76.13-.18.36-.24.57-.17,6.08,2.17,12.6,3.27,19.38,3.27s13.31-1.1,19.38-3.27c.2-.07.43,0,.57.17,6.8,9.04,12.51,18.72,16.97,28.76,2.23,5.01,6.87,15.45,9.37,29.47,2.68,15.01,2.31,30.47-1.11,45.96-.03.15-.13.28-.27.34-13.73,6.63-29.02,10.18-44.24,10.26-.19,0-.38,0-.57,0Zm-44.67-11.3c13.86,6.74,29.29,10.3,44.67,10.3.19,0,.38,0,.57,0,14.99-.08,30.05-3.56,43.59-10.06,3.34-15.28,3.7-30.53,1.05-45.33-2.49-13.9-7.09-24.26-9.3-29.24-4.38-9.86-9.98-19.37-16.64-28.27-6.08,2.13-12.6,3.21-19.37,3.21s-13.28-1.08-19.37-3.21c-6.66,8.9-12.25,18.41-16.64,28.27-1.9,4.26-6.34,14.25-8.93,27.38-3.01,15.24-2.88,31.03.37,46.95Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1667.85,1090.73c-.71,0-1.43,0-2.15-.03-21.57-.57-37.6-9.68-45.24-15.06l.29-.41c7.59,5.35,23.53,14.41,44.97,14.97,24.04.63,41.82-9.55,50.27-15.68l.29.4c-8.24,5.98-25.31,15.8-48.42,15.8Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1668.11,1081.35c-.71,0-1.43,0-2.15-.03-21.71-.57-37.79-9.77-45.44-15.21l.29-.41c7.61,5.4,23.59,14.55,45.17,15.12,23.61.62,41.13-9.2,49.47-15.11l.29.41c-8.13,5.76-24.94,15.23-47.62,15.23Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1692.44,1104.18l-.5-.02c.77-16.35,0-32.85-2.26-49.06-2.82-20.13-7.99-40.07-15.38-59.28l.47-.18c7.4,19.24,12.58,39.22,15.41,59.39,2.27,16.24,3.04,32.77,2.26,49.15Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1700.66,1100.83l-.5-.04c1.13-13.99.74-28.12-1.16-41.98-3-21.93-9.75-43.33-20.07-63.62l.45-.23c10.34,20.34,17.11,41.8,20.12,63.78,1.9,13.9,2.29,28.06,1.16,42.09Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1708.65,1098.59l-.5-.05c.91-9.11,1.02-18.35.32-27.47-.45-5.88-1.84-17.93-6.01-32.5-4.42-15.4-11.01-30.29-19.59-44.24l.43-.26c8.61,13.99,15.22,28.92,19.65,44.37,4.19,14.61,5.58,26.7,6.03,32.6.7,9.15.6,18.42-.32,27.56Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1644.32,1104.18c-.77-16.37,0-32.91,2.27-49.15,2.82-20.16,8.01-40.14,15.41-59.39l.47.18c-7.39,19.21-12.56,39.15-15.38,59.28-2.27,16.21-3.03,32.71-2.26,49.06l-.5.02Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1636.13,1101.48c-.84-10.05-.9-20.26-.17-30.35,0-.06.46-6.28,1.29-12.4,3.01-21.98,9.78-43.44,20.12-63.78l.45.23c-10.31,20.29-17.06,41.7-20.07,63.62-.83,6.1-1.29,12.3-1.29,12.37-.73,10.07-.67,20.25.17,30.27l-.5.04Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1628.9,1099.06c-1.32-9.26-1.69-18.67-1.11-27.98.93-14.83,4-25.56,6.04-32.65,4.43-15.44,11.04-30.37,19.64-44.37l.43.26c-8.58,13.96-15.17,28.84-19.59,44.24-2.03,7.08-5.1,17.77-6.02,32.55-.58,9.27-.21,18.65,1.11,27.88l-.49.07Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1716.03,817.96c-14.93-6.12-31.5-9.35-47.9-9.35h-.01c-16.4,0-32.96,3.24-47.88,9.35l-.19-.46c14.99-6.14,31.61-9.38,48.07-9.39h.01c16.47,0,33.1,3.25,48.09,9.39l-.19.46Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1668.13,968.68h-.08c-20.97-.01-37.69-5.63-48.02-10.35l.21-.46c10.29,4.69,26.93,10.29,47.81,10.3h.08c20.92,0,37.58-5.6,47.89-10.3l.21.46c-10.35,4.72-27.09,10.35-48.1,10.35Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1667.03,996.48c-6.31-.87-18.37-3.78-28.2-13.54-7.72-7.66-10.61-16.08-11.67-21.79l.49-.09c1.05,5.64,3.89,13.96,11.53,21.53,9.73,9.65,21.67,12.53,27.92,13.39l-.07.5Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1677.08,995.98c-5.97-.29-17.53-2.02-27.81-10.36-9.45-7.66-12.79-16.92-13.94-21.76l.49-.12c1.14,4.78,4.44,13.92,13.77,21.49,10.17,8.24,21.61,9.96,27.52,10.24l-.02.5Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1677.04,995.98c-5.03-1.09-14.68-4.09-22.71-12.02-6.45-6.37-9.26-13.28-10.49-17.96l.48-.13c1.21,4.62,3.98,11.45,10.35,17.73,7.94,7.84,17.48,10.81,22.46,11.88l-.11.49Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1669.73,996.48l-.07-.5c6.25-.86,18.19-3.75,27.92-13.39,7.63-7.57,10.48-15.88,11.53-21.53l.49.09c-1.06,5.71-3.94,14.13-11.67,21.79-9.83,9.75-21.89,12.67-28.2,13.54Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1659.68,995.98l-.02-.5c5.91-.29,17.35-2,27.52-10.24,9.33-7.56,12.63-16.71,13.77-21.49l.49.12c-1.15,4.84-4.49,14.1-13.94,21.76-10.28,8.33-21.84,10.06-27.81,10.36Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1659.72,995.98l-.11-.49c4.98-1.07,14.52-4.05,22.46-11.88,6.37-6.29,9.15-13.11,10.35-17.73l.48.13c-1.22,4.68-4.04,11.6-10.49,17.96-8.03,7.93-17.68,10.93-22.71,12.02Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1667.96,986.58c-13.88,0-27.93-2.65-40.65-7.67l.18-.46c12.67,5,26.64,7.64,40.46,7.64.22,0,.43,0,.65,0,13.77-.08,27.15-2.65,39.78-7.64l.18.46c-12.69,5.01-26.13,7.59-39.96,7.67-.22,0-.44,0-.65,0Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1668.13,990h-.23c-11.84-.02-23.49-1.87-34.63-5.5l.15-.47c11.09,3.61,22.68,5.45,34.47,5.47h.23c11.86,0,23.53-1.84,34.69-5.47l.15.47c-11.21,3.65-22.93,5.5-34.84,5.5Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1668.12,993.22c-9.91,0-19.67-1.54-29-4.58l.15-.47c9.28,3.02,18.98,4.56,28.84,4.56h.26c9.78-.03,19.4-1.56,28.6-4.56l.15.47c-9.25,3.01-18.92,4.56-28.75,4.58h-.26Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1668.13,982.8c-22.44,0-38.64-8.86-46.33-14.17l.28-.41c7.65,5.28,23.75,14.08,46.05,14.08.17,0,.34,0,.51,0,22.07-.13,38.01-8.88,45.55-14.08l.28.41c-7.58,5.23-23.62,14.03-45.83,14.16-.17,0-.34,0-.51,0Z"
              fill={theme.palette.divider}
            />
            <g>
              <path
                d="m1708.62,815.57c-1.05-5.64-3.89-13.96-11.53-21.53-9.73-9.65-21.67-12.53-27.92-13.39l.07-.5c6.31.87,18.37,3.78,28.2,13.54,7.72,7.66,10.61,16.08,11.67,21.79l-.49.09Z"
                fill={theme.palette.divider}
              />
              <path
                d="m1700.44,812.88c-1.14-4.78-4.44-13.92-13.77-21.49-10.17-8.24-21.61-9.96-27.52-10.24l.02-.5c5.97.29,17.53,2.02,27.81,10.36,9.45,7.66,12.79,16.92,13.94,21.76l-.49.12Z"
                fill={theme.palette.divider}
              />
              <path
                d="m1691.93,810.76c-1.21-4.62-3.98-11.45-10.35-17.73-7.94-7.84-17.48-10.81-22.46-11.88l.11-.49c5.03,1.08,14.68,4.09,22.71,12.02,6.45,6.37,9.26,13.28,10.49,17.96l-.48.13Z"
                fill={theme.palette.divider}
              />
              <path
                d="m1627.15,815.57l-.49-.09c1.06-5.71,3.94-14.13,11.67-21.79,9.83-9.75,21.89-12.67,28.2-13.54l.07.5c-6.25.86-18.19,3.75-27.92,13.39-7.63,7.57-10.48,15.88-11.53,21.53Z"
                fill={theme.palette.divider}
              />
              <path
                d="m1635.33,812.88l-.49-.12c1.15-4.84,4.49-14.1,13.94-21.76,10.28-8.33,21.84-10.06,27.81-10.36l.02.5c-5.91.29-17.35,2-27.52,10.24-9.33,7.56-12.63,16.71-13.77,21.49Z"
                fill={theme.palette.divider}
              />
              <path
                d="m1643.84,810.76l-.48-.13c1.22-4.68,4.04-11.6,10.49-17.96,8.03-7.93,17.68-10.93,22.71-12.02l.11.49c-4.98,1.07-14.52,4.05-22.46,11.88-6.37,6.29-9.15,13.11-10.35,17.73Z"
                fill={theme.palette.divider}
              />
              <path
                d="m1627.88,798.19l-.18-.46c12.68-5.01,26.13-7.59,39.96-7.67,14.09-.09,28.38,2.57,41.3,7.67l-.18.46c-12.67-5-26.64-7.64-40.46-7.64-.22,0-.43,0-.65,0-13.57.08-27.33,2.72-39.78,7.64Z"
                fill={theme.palette.divider}
              />
              <path
                d="m1702.83,792.6c-11.09-3.61-22.69-5.45-34.47-5.47h-.23c-11.86,0-23.53,1.84-34.69,5.47l-.15-.47c11.21-3.65,22.93-5.5,34.84-5.5h.23c11.84.02,23.49,1.87,34.63,5.5l-.15.47Z"
                fill={theme.palette.divider}
              />
              <path
                d="m1696.99,788.47c-9.28-3.02-18.98-4.56-28.84-4.56h-.26c-9.78.03-19.4,1.56-28.6,4.56l-.15-.47c9.25-3.01,18.92-4.56,28.75-4.58h.26c9.91,0,19.67,1.54,29,4.58l-.15.47Z"
                fill={theme.palette.divider}
              />
              <path
                d="m1714.19,808.41c-7.65-5.28-23.75-14.08-46.05-14.08-.17,0-.34,0-.51,0-22.07.13-38.01,8.88-45.55,14.08l-.28-.41c7.58-5.23,23.62-14.03,45.83-14.16.17,0,.34,0,.51,0,22.44,0,38.64,8.86,46.33,14.17l-.28.41Z"
                fill={theme.palette.divider}
              />
            </g>
            <rect
              x="1633.11"
              y="813.04"
              width=".5"
              height="150.38"
              fill={theme.palette.divider}
            />
            <rect
              x="1628.39"
              y="814.6"
              width=".5"
              height="146.96"
              fill={theme.palette.divider}
            />
            <rect
              x="1624.29"
              y="816.02"
              width=".5"
              height="143.98"
              fill={theme.palette.divider}
            />
            <rect
              x="1702.97"
              y="813.04"
              width=".5"
              height="150.38"
              fill={theme.palette.divider}
            />
            <rect
              x="1707.69"
              y="814.6"
              width=".5"
              height="146.96"
              fill={theme.palette.divider}
            />
            <rect
              x="1711.79"
              y="816.02"
              width=".5"
              height="143.98"
              fill={theme.palette.divider}
            />
          </g>
          <path
            d="m1668.81,1042.76c-17.55,0-34.49-1.79-48.99-5.16-6.71-1.56-12.77-3.44-18.02-5.58-3.84-1.54-7.33-3.28-10.37-5.18-7.56-4.74-11.55-10.24-11.55-15.91s3.99-11.17,11.55-15.91c3.03-1.9,6.52-3.64,10.38-5.18,5.24-2.14,11.3-4.01,18.01-5.58,14.5-3.38,31.44-5.16,48.99-5.16s34.51,1.79,48.99,5.18c6.71,1.55,12.78,3.42,18.03,5.57,3.93,1.59,7.41,3.33,10.35,5.18,7.55,4.75,11.55,10.26,11.55,15.91s-3.99,11.16-11.55,15.91c-2.94,1.85-6.43,3.59-10.36,5.18-5.24,2.14-11.31,4.02-18.03,5.56-14.47,3.39-31.41,5.18-48.99,5.18Zm0-60.67c-17.32,0-34.03,1.76-48.31,5.08-6.55,1.53-12.46,3.36-17.56,5.44-3.7,1.48-7.03,3.14-9.91,4.94-4.63,2.9-10.14,7.58-10.14,13.37s5.52,10.47,10.14,13.37c2.88,1.8,6.21,3.46,9.9,4.94,5.11,2.08,11.02,3.91,17.57,5.44,14.28,3.33,30.99,5.08,48.31,5.08s34.05-1.76,48.31-5.1c6.57-1.51,12.48-3.33,17.58-5.42,3.77-1.52,7.1-3.19,9.89-4.94,4.63-2.91,10.15-7.6,10.15-13.37s-5.52-10.46-10.15-13.37c-2.79-1.76-6.12-3.42-9.88-4.94-5.1-2.09-11.02-3.91-17.58-5.42-14.26-3.34-30.97-5.1-48.31-5.1Z"
            fill={theme.palette.divider}
          />
          <path
            d="m1668.81,774.98c-48.48,0-87.93-13.83-87.93-30.83s39.44-30.83,87.93-30.83,87.93,13.83,87.93,30.83-39.44,30.83-87.93,30.83Zm0-60.66c-47.93,0-86.93,13.38-86.93,29.83s39,29.83,86.93,29.83,86.93-13.38,86.93-29.83-39-29.83-86.93-29.83Z"
            fill={theme.palette.divider}
          />
          <path
            d="m1668.81,761.62c-48.48,0-87.93-13.83-87.93-30.83s39.44-30.83,87.93-30.83,87.93,13.83,87.93,30.83-39.44,30.83-87.93,30.83Zm0-60.66c-47.93,0-86.93,13.38-86.93,29.83s39,29.83,86.93,29.83,86.93-13.38,86.93-29.83-39-29.83-86.93-29.83Z"
            fill={theme.palette.divider}
          />
          <path
            d="m1668.81,1027.35c-48.48,0-87.93-13.83-87.93-30.83s39.44-30.83,87.93-30.83,87.93,13.83,87.93,30.83-39.44,30.83-87.93,30.83Zm0-60.66c-47.93,0-86.93,13.38-86.93,29.83s39,29.83,86.93,29.83,86.93-13.38,86.93-29.83-39-29.83-86.93-29.83Z"
            fill={theme.palette.divider}
          />
          <g>
            <g>
              <path
                d="m1669.67,753.27c-.11,0-.21-.03-.3-.1l-67.59-50.7c-.13-.09-.2-.24-.2-.4s.07-.31.2-.4l67.59-50.7c.18-.13.42-.13.6,0l67.59,50.7c.13.09.2.24.2.4s-.07.31-.2.4l-67.59,50.7c-.09.07-.19.1-.3.1Zm-66.76-51.2l66.76,50.07,66.76-50.07-66.76-50.07-66.76,50.07Z"
                fill={theme.palette.divider}
              />
              <path
                d="m1711.4,721.64l-30.34-23.44c-.11-.08-.18-.21-.19-.34l-1.98-18.25-23.6-17.27.59-.81,23.77,17.4c.11.08.19.21.2.35l1.98,18.26,30.17,23.31-.61.79Z"
                fill={theme.palette.divider}
              />
              <path
                d="m1700.89,728.86l-30.34-23.44c-.11-.08-.18-.21-.19-.34l-1.98-18.25-23.6-17.27.59-.81,23.77,17.4c.11.08.19.21.2.35l1.98,18.26,30.17,23.31-.61.79Z"
                fill={theme.palette.divider}
              />
              <path
                d="m1689.88,737.62l-30.34-23.44c-.11-.08-.18-.21-.19-.34l-1.98-18.25-23.59-17.27.59-.81,23.77,17.4c.11.08.19.21.2.35l1.98,18.26,30.17,23.31-.61.79Z"
                fill={theme.palette.divider}
              />
            </g>
            <g>
              <path
                d="m1669.67,725.7c-.11,0-.21-.03-.3-.1l-67.59-50.7c-.13-.09-.2-.24-.2-.4s.07-.31.2-.4l67.59-50.7c.18-.13.42-.13.6,0l67.59,50.7c.13.09.2.24.2.4s-.07.31-.2.4l-67.59,50.7c-.09.07-.19.1-.3.1Zm-66.76-51.2l66.76,50.07,66.76-50.07-66.76-50.07-66.76,50.07Z"
                fill={theme.palette.divider}
              />
              <path
                d="m1649.46,710.05l-.61-.79,30.17-23.31,1.98-18.26c.01-.14.09-.27.2-.35l23.25-17.01-10.2-8.11-23.29,17.04-1.98,18.25c-.01.14-.08.26-.19.34l-30.34,23.44c-.17.13-.41.14-.59.02l-10.51-7.22c-.13-.09-.21-.24-.22-.4,0-.16.07-.31.19-.41l30.17-23.31,1.98-18.26c.01-.14.09-.27.2-.35l23.77-17.4.59.81-23.59,17.27-1.98,18.25c-.01.14-.08.26-.19.34l-29.79,23.02,9.64,6.63,29.88-23.09,1.98-18.26c.01-.14.09-.27.2-.35l23.77-17.4c.18-.13.43-.13.61.01l11.01,8.76c.12.1.19.25.19.4,0,.16-.08.3-.21.39l-23.6,17.27-1.98,18.25c-.01.14-.08.26-.19.34l-30.34,23.44Z"
                fill={theme.palette.divider}
              />
            </g>
            <g>
              <path
                d="m1669.67,698.3c-.11,0-.21-.03-.3-.1l-67.59-50.7c-.13-.09-.2-.24-.2-.4s.07-.31.2-.4l67.59-50.7c.18-.13.42-.13.6,0l67.59,50.7c.13.09.2.24.2.4s-.07.31-.2.4l-67.59,50.7c-.09.07-.19.1-.3.1Zm-66.76-51.2l66.76,50.07,66.76-50.07-66.76-50.07-66.76,50.07Z"
                fill={theme.palette.divider}
              />
              <path
                d="m1711.4,666.68l-30.34-23.44c-.11-.08-.18-.21-.19-.34l-1.98-18.25-23.6-17.27.59-.81,23.77,17.4c.11.08.19.21.2.35l1.98,18.26,30.17,23.31-.61.79Z"
                fill={theme.palette.divider}
              />
              <path
                d="m1700.89,673.9l-30.34-23.44c-.11-.08-.18-.21-.19-.34l-1.98-18.25-23.6-17.27.59-.81,23.77,17.4c.11.08.19.21.2.35l1.98,18.26,30.17,23.31-.61.79Z"
                fill={theme.palette.divider}
              />
              <path
                d="m1689.88,682.65l-30.34-23.44c-.11-.08-.18-.21-.19-.34l-1.98-18.25-23.59-17.27.59-.81,23.77,17.4c.11.08.19.21.2.35l1.98,18.26,30.17,23.31-.61.79Z"
                fill={theme.palette.divider}
              />
            </g>
            <g>
              <path
                d="m1669.67,670.73c-.11,0-.21-.03-.3-.1l-67.59-50.7c-.13-.09-.2-.24-.2-.4s.07-.31.2-.4l67.59-50.7c.18-.13.42-.13.6,0l67.59,50.7c.13.09.2.24.2.4s-.07.31-.2.4l-67.59,50.7c-.09.07-.19.1-.3.1Zm-66.76-51.2l66.76,50.07,66.76-50.07-66.76-50.07-66.76,50.07Z"
                fill={theme.palette.divider}
              />
              <path
                d="m1649.46,655.08l-.61-.79,30.17-23.31,1.98-18.26c.01-.14.09-.27.2-.35l23.25-17.01-10.2-8.11-23.29,17.04-1.98,18.25c-.01.14-.08.26-.19.34l-30.34,23.44c-.17.13-.41.14-.59.02l-10.51-7.22c-.13-.09-.21-.24-.22-.4,0-.16.07-.31.19-.41l30.17-23.31,1.98-18.26c.01-.14.09-.27.2-.35l23.77-17.4.59.81-23.59,17.27-1.98,18.25c-.01.14-.08.26-.19.34l-29.79,23.02,9.64,6.63,29.88-23.09,1.98-18.26c.01-.14.09-.27.2-.35l23.77-17.4c.18-.13.43-.13.61.01l11.01,8.76c.12.1.19.25.19.4,0,.16-.08.3-.21.39l-23.6,17.27-1.98,18.25c-.01.14-.08.26-.19.34l-30.34,23.44Z"
                fill={theme.palette.divider}
              />
            </g>
          </g>
          <path
            d="m1668.81,566.3c-48.48,0-87.93-13.83-87.93-30.83s39.44-30.83,87.93-30.83,87.93,13.83,87.93,30.83-39.44,30.83-87.93,30.83Zm0-60.66c-47.93,0-86.93,13.38-86.93,29.83s39,29.83,86.93,29.83,86.93-13.38,86.93-29.83-39-29.83-86.93-29.83Z"
            fill={theme.palette.divider}
          />
          <path
            d="m1668.81,551.66c-48.48,0-87.93-13.83-87.93-30.83s39.44-30.83,87.93-30.83,87.93,13.83,87.93,30.83-39.44,30.83-87.93,30.83Zm0-60.66c-47.93,0-86.93,13.38-86.93,29.83s39,29.83,86.93,29.83,86.93-13.38,86.93-29.83-39-29.83-86.93-29.83Z"
            fill={theme.palette.divider}
          />
          <g>
            <path
              d="m1671.21,422.15c-13.56,0-26.75-2.05-37.16-5.78-2.49-.87-4.88-1.88-7.1-3-9.16-4.61-14.2-10.53-14.2-16.68,0-14.04,26.23-25.46,58.47-25.46s58.48,11.42,58.48,25.46c0,6.15-5.04,12.07-14.2,16.68-2.2,1.1-4.59,2.11-7.11,3-10.42,3.73-23.62,5.78-37.17,5.78Zm0-49.93c-31.69,0-57.47,10.97-57.47,24.46,0,5.75,4.85,11.36,13.65,15.78,2.19,1.1,4.54,2.09,6.99,2.95,10.31,3.69,23.39,5.73,36.83,5.73s26.52-2.03,36.84-5.73c2.48-.88,4.83-1.87,6.99-2.95,8.8-4.43,13.65-10.03,13.65-15.78,0-13.49-25.78-24.46-57.48-24.46Zm0,43.93c-8.8,0-17.26-1.1-24.48-3.18l-.08-.02c-12.52-3.59-19.99-9.67-19.99-16.26,0-10.74,19.98-19.48,44.54-19.48s44.55,8.74,44.55,19.48c0,6.6-7.48,12.68-20,16.26l-.08.02c-7.22,2.08-15.68,3.18-24.47,3.18Zm-24.34-4.18l.08.02c7.14,2.07,15.53,3.16,24.26,3.16s17.12-1.09,24.26-3.16l.08-.02c12.03-3.46,19.21-9.17,19.21-15.28,0-10.19-19.54-18.48-43.55-18.48s-43.54,8.29-43.54,18.48c0,6.11,7.18,11.82,19.2,15.28Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1671.21,416.15c-8.8,0-17.26-1.1-24.48-3.18l-.08-.02c-12.52-3.59-19.99-9.67-19.99-16.26,0-10.74,19.98-19.48,44.54-19.48s44.55,8.74,44.55,19.48c0,6.6-7.48,12.68-20,16.26l-.08.02c-7.22,2.08-15.68,3.18-24.47,3.18Zm-24.34-4.18l.08.02c7.14,2.07,15.53,3.16,24.26,3.16s17.12-1.09,24.26-3.16l.08-.02c12.03-3.46,19.21-9.17,19.21-15.28,0-10.19-19.54-18.48-43.55-18.48s-43.54,8.29-43.54,18.48c0,6.11,7.18,11.82,19.2,15.28Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1671.21,416.15c-8.8,0-17.26-1.1-24.48-3.18l-.08-.02c-12.52-3.59-19.99-9.67-19.99-16.26,0-10.74,19.98-19.48,44.54-19.48s44.55,8.74,44.55,19.48c0,6.6-7.48,12.68-20,16.26l-.08.02c-7.22,2.08-15.68,3.18-24.47,3.18Zm-24.34-4.18l.08.02c7.14,2.07,15.53,3.16,24.26,3.16s17.12-1.09,24.26-3.16l.08-.02c12.03-3.46,19.21-9.17,19.21-15.28,0-10.19-19.54-18.48-43.55-18.48s-43.54,8.29-43.54,18.48c0,6.11,7.18,11.82,19.2,15.28Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1671.21,537.4c-24.56,0-44.54-8.74-44.54-19.48v-67.4l.72.36c10.95,5.52,26.92,8.68,43.82,8.68s32.88-3.16,43.83-8.68l.72-.36v67.4c0,10.74-19.99,19.48-44.55,19.48Zm-43.54-85.27v65.79c0,10.19,19.53,18.48,43.54,18.48s43.55-8.29,43.55-18.48v-65.79c-11.06,5.36-26.87,8.43-43.55,8.43s-32.49-3.07-43.54-8.43Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1671.21,416.15c-8.82,0-17.31-1.11-24.54-3.2l-1.96-.57,2-.4c7.7-1.55,15.94-2.34,24.5-2.34s16.8.79,24.5,2.34l2,.4-1.96.57c-7.23,2.09-15.72,3.2-24.54,3.2Zm-22.18-3.59c6.67,1.7,14.29,2.59,22.18,2.59s15.51-.89,22.18-2.59c-7.02-1.27-14.47-1.92-22.18-1.92s-15.16.64-22.18,1.92Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1671.21,460.57c-17.05,0-33.18-3.2-44.27-8.79-9.16-4.59-14.2-10.51-14.2-16.68v-38.42h1c0,5.75,4.85,11.36,13.65,15.78,2.19,1.1,4.54,2.09,6.99,2.95,10.31,3.69,23.39,5.73,36.83,5.73s26.52-2.03,36.84-5.73c2.48-.88,4.83-1.87,6.99-2.95,8.8-4.43,13.65-10.03,13.65-15.78h1v38.42c0,6.16-5.04,12.09-14.2,16.68-11.08,5.58-27.22,8.79-44.28,8.79Zm-57.47-59.17v33.7c0,5.77,4.85,11.37,13.65,15.79,10.95,5.52,26.92,8.68,43.82,8.68s32.88-3.16,43.83-8.68c8.8-4.41,13.65-10.02,13.65-15.79v-33.7c-1.89,4.41-6.42,8.55-13.2,11.96-2.2,1.1-4.59,2.11-7.11,3-10.42,3.73-23.62,5.78-37.17,5.78s-26.75-2.05-37.16-5.78c-2.49-.87-4.88-1.88-7.1-3-6.78-3.41-11.31-7.55-13.2-11.96Z"
              fill={theme.palette.divider}
            />
            <rect
              x="1709.7"
              y="453.67"
              width=".5"
              height="73.3"
              fill={theme.palette.divider}
            />
            <rect
              x="1705.02"
              y="455.31"
              width=".5"
              height="74.65"
              fill={theme.palette.divider}
            />
            <rect
              x="1699.07"
              y="456.94"
              width=".5"
              height="75.6"
              fill={theme.palette.divider}
            />
            <rect
              x="1693.13"
              y="458.18"
              width=".5"
              height="76.15"
              fill={theme.palette.divider}
            />
            <rect
              x="1631.72"
              y="454.41"
              width=".5"
              height="73.3"
              fill={theme.palette.divider}
            />
            <rect
              x="1636.39"
              y="456.05"
              width=".5"
              height="74.65"
              fill={theme.palette.divider}
            />
            <rect
              x="1642.34"
              y="457.68"
              width=".5"
              height="75.6"
              fill={theme.palette.divider}
            />
            <rect
              x="1648.29"
              y="458.92"
              width=".5"
              height="76.15"
              fill={theme.palette.divider}
            />
            <rect
              x="1725.81"
              y="404.77"
              width=".5"
              height="39.3"
              fill={theme.palette.divider}
            />
            <rect
              x="1721.59"
              y="408.84"
              width=".5"
              height="38.43"
              fill={theme.palette.divider}
            />
            <rect
              x="1715.02"
              y="412.92"
              width=".5"
              height="38.42"
              fill={theme.palette.divider}
            />
            <rect
              x="1703.31"
              y="417.39"
              width=".5"
              height="38.65"
              fill={theme.palette.divider}
            />
            <rect
              x="1616.63"
              y="405.58"
              width=".5"
              height="39.3"
              fill={theme.palette.divider}
            />
            <rect
              x="1620.84"
              y="409.65"
              width=".5"
              height="38.43"
              fill={theme.palette.divider}
            />
            <rect
              x="1627.42"
              y="413.73"
              width=".5"
              height="38.42"
              fill={theme.palette.divider}
            />
            <rect
              x="1639.13"
              y="418.2"
              width=".5"
              height="38.65"
              fill={theme.palette.divider}
            />
            <g>
              <path
                d="m1617.74,494.91h-12.33c-.28,0-.5-.22-.5-.5v-42.63c0-.28.22-.5.5-.5h12.33c.28,0,.5.22.5.5v42.63c0,.28-.22.5-.5.5Zm-11.83-1h11.33v-41.63h-11.33v41.63Z"
                fill={theme.palette.divider}
              />
              <path
                d="m1615.45,546.9h-8.45c-2.9,0-5.26-2.36-5.26-5.26v-42.35c0-2.9,2.36-5.26,5.26-5.26h8.45c2.9,0,5.26,2.36,5.26,5.26v42.35c0,2.9-2.36,5.26-5.26,5.26Zm-8.45-51.88c-2.35,0-4.26,1.91-4.26,4.26v42.35c0,2.35,1.91,4.26,4.26,4.26h8.45c2.35,0,4.26-1.91,4.26-4.26v-42.35c0-2.35-1.91-4.26-4.26-4.26h-8.45Z"
                fill={theme.palette.divider}
              />
              <path
                d="m1626.91,481.79h-9.16c-.28,0-.5-.22-.5-.5v-18.37c0-.28.22-.5.5-.5h9.16c.28,0,.5.22.5.5v18.37c0,.28-.22.5-.5.5Zm-8.66-1h8.16v-17.37h-8.16v17.37Z"
                fill={theme.palette.divider}
              />
              <path
                d="m1614.75,451.91h-6.34c-.28,0-.5-.22-.5-.5v-22.11c0-.28.22-.5.5-.5h6.34c.28,0,.5.22.5.5v22.11c0,.28-.22.5-.5.5Zm-5.84-1h5.34v-21.11h-5.34v21.11Z"
                fill={theme.palette.divider}
              />
              <rect
                x="1602.24"
                y="537"
                width="17.97"
                height="1"
                fill={theme.palette.divider}
              />
              <rect
                x="1602.24"
                y="502.92"
                width="17.97"
                height="1"
                fill={theme.palette.divider}
              />
              <path
                d="m1625.9,535.29c-1.65,0-3.41-.34-5.24-1.01-.2-.07-.33-.26-.33-.47v-4.83c0-.16.08-.31.21-.41.13-.1.3-.12.45-.07,2.1.71,4.02.72,5.73.01,7.15-2.97,12.56-19.34,13.79-41.69.7-12.72-.13-25.32-2.34-35.49-2.46-11.35-6.44-18.82-11.5-21.61-3.22-1.78-7.17-1.77-11.74.03-.06.02-.12.03-.18.03h-4.8c-.22,0-.42-.15-.48-.37-.7-2.48-.69-4.51.03-6.03.7-1.49,2.08-2.55,4.11-3.15,5.15-1.52,12.86.34,17.04,2.94,6.02,3.74,10.73,12.58,13.64,25.56,2.59,11.57,3.57,25.64,2.75,39.61-1.11,18.98-6.02,41.01-16.44,45.91-1.46.68-3.02,1.02-4.69,1.02Zm-4.57-1.83c3.3,1.14,6.27,1.1,8.84-.1,8.39-3.94,14.47-21.2,15.87-45.06,2.11-36.04-6.77-58.57-15.92-64.26-4.02-2.5-11.5-4.23-16.23-2.83-1.74.51-2.91,1.39-3.49,2.62-.58,1.22-.6,2.89-.07,4.96h4.32c4.82-1.87,9.02-1.85,12.5.06,11.7,6.44,15.64,34.6,14.35,58.03-.95,17.26-5.29,38.77-14.4,42.56-1.75.73-3.69.8-5.77.22v3.8Z"
                fill={theme.palette.divider}
              />
            </g>
          </g>
          <path
            d="m1668.81,338.61c-48.48,0-87.92-13.83-87.92-30.83s39.44-30.83,87.92-30.83,87.92,13.83,87.92,30.83-39.44,30.83-87.92,30.83Zm0-60.67c-47.93,0-86.92,13.38-86.92,29.83s38.99,29.83,86.92,29.83,86.92-13.38,86.92-29.83-38.99-29.83-86.92-29.83Z"
            fill={theme.palette.divider}
          />
          <path
            d="m1668.81,376.66c-48.48,0-87.93-13.83-87.93-30.83s39.44-30.83,87.93-30.83,87.93,13.83,87.93,30.83-39.44,30.83-87.93,30.83Zm0-60.66c-47.93,0-86.93,13.38-86.93,29.83s39,29.83,86.93,29.83,86.93-13.38,86.93-29.83-39-29.83-86.93-29.83Z"
            fill={theme.palette.divider}
          />
          <path
            d="m1668.81,359.68c-48.48,0-87.93-13.83-87.93-30.83s39.44-30.83,87.93-30.83,87.93,13.83,87.93,30.83-39.44,30.83-87.93,30.83Zm0-60.66c-47.93,0-86.93,13.38-86.93,29.83s39,29.83,86.93,29.83,86.93-13.38,86.93-29.83-39-29.83-86.93-29.83Z"
            fill={theme.palette.divider}
          />
          <g>
            <path
              d="m1668.8,235.12c-.09,0-.17-.02-.25-.07-.16-.09-.25-.25-.25-.43v-39.73c0-.15.07-.3.19-.39.12-.09.28-.13.42-.1l49.16,11.17c.2.05.35.21.38.41s-.07.4-.24.51l-49.16,28.56c-.08.05-.16.07-.25.07Zm.5-39.6v38.24l47.31-27.48-47.31-10.75Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1619.67,206.57c-.2,0-.39-.12-.46-.32-.09-.23,0-.49.21-.62l49.13-28.57c.15-.09.34-.09.5,0s.25.25.25.43v17.4c0,.23-.16.44-.39.49l-49.13,11.17s-.08.01-.11.01Zm48.63-28.21l-45.55,26.49,45.55-10.36v-16.14Z"
              fill={theme.palette.divider}
            />
            <g>
              <path
                d="m1668.8,332.44c-.09,0-.17-.02-.25-.07-.16-.09-.25-.25-.25-.43v-97.31c0-.18.09-.34.25-.43l49.16-28.56c.15-.09.34-.09.5,0s.25.25.25.43v97.31c0,.18-.09.34-.25.43l-49.16,28.56c-.08.05-.16.07-.25.07Zm.5-97.52v96.16l48.16-27.98v-96.16l-48.16,27.98Z"
                fill={theme.palette.divider}
              />
              <path
                d="m1668.8,332.44c-.09,0-.17-.02-.25-.07l-49.13-28.56c-.15-.09-.25-.25-.25-.43v-97.31c0-.18.1-.34.25-.43.16-.09.35-.09.5,0l49.13,28.56c.15.09.25.25.25.43v97.31c0,.18-.1.34-.25.43-.08.04-.16.07-.25.07Zm-48.63-29.35l48.13,27.98v-96.15l-48.13-27.98v96.15Z"
                fill={theme.palette.divider}
              />
            </g>
            <path
              d="m1668.8,235.12c-.09,0-.17-.02-.25-.07l-49.13-28.56c-.18-.1-.27-.3-.24-.51.03-.2.18-.37.38-.41l49.13-11.17c.15-.03.3,0,.42.1.12.09.19.24.19.39v39.73c0,.18-.1.34-.25.43-.08.04-.16.07-.25.07Zm-47.78-28.85l47.28,27.48v-38.23l-47.28,10.75Z"
              fill={theme.palette.divider}
            />
            <path
              d="m1717.96,206.57s-.07,0-.11-.01l-49.16-11.17c-.23-.05-.39-.25-.39-.49v-17.4c0-.18.1-.34.25-.43.16-.09.35-.09.5,0l49.16,28.57c.21.12.3.39.21.62-.08.19-.26.32-.46.32Zm-48.66-12.07l45.58,10.36-45.58-26.49v16.14Z"
              fill={theme.palette.divider}
            />
            <rect
              x="1631.53"
              y="213.1"
              width=".5"
              height="98.02"
              fill={theme.palette.divider}
            />
            <rect
              x="1644.11"
              y="221.2"
              width=".25"
              height="96.45"
              fill={theme.palette.divider}
            />
            <rect
              x="1657.76"
              y="228.28"
              width=".25"
              height="96.78"
              fill={theme.palette.divider}
            />
            <rect
              x="1643.98"
              y="212.7"
              width=".5"
              height="57.02"
              transform="translate(601.84 1535.47) rotate(-59.5)"
              fill={theme.palette.divider}
            />
            <rect
              x="1643.98"
              y="232.42"
              width=".5"
              height="57.02"
              transform="translate(584.85 1545.18) rotate(-59.5)"
              fill={theme.palette.divider}
            />
            <rect
              x="1643.98"
              y="270.42"
              width=".5"
              height="57.02"
              transform="translate(552.11 1563.89) rotate(-59.5)"
              fill={theme.palette.divider}
            />
            <rect
              x="1643.98"
              y="251.52"
              width=".5"
              height="57.02"
              transform="translate(568.39 1554.59) rotate(-59.5)"
              fill={theme.palette.divider}
            />
            <rect
              x="1705.58"
              y="213.03"
              width=".5"
              height="98.02"
              fill={theme.palette.divider}
            />
            <rect
              x="1693.13"
              y="221.12"
              width=".5"
              height="96.45"
              fill={theme.palette.divider}
            />
            <rect
              x="1679.61"
              y="228.2"
              width=".25"
              height="96.78"
              fill={theme.palette.divider}
            />
            <rect
              x="1664.87"
              y="240.88"
              width="57.02"
              height=".5"
              transform="translate(112.19 893.43) rotate(-30.52)"
              fill={theme.palette.divider}
            />
            <rect
              x="1664.87"
              y="260.6"
              width="57.02"
              height=".5"
              transform="translate(102.17 896.17) rotate(-30.52)"
              fill={theme.palette.divider}
            />
            <rect
              x="1664.87"
              y="298.6"
              width="57.02"
              height=".5"
              transform="translate(82.87 901.43) rotate(-30.52)"
              fill={theme.palette.divider}
            />
            <rect
              x="1664.87"
              y="279.7"
              width="57.02"
              height=".5"
              transform="translate(92.47 898.81) rotate(-30.52)"
              fill={theme.palette.divider}
            />
          </g>
          <path
            d="m1757.72,1010.93h-3c0-15.63-39.35-28.83-85.92-28.83s-85.92,13.2-85.92,28.83h-3V307.77c-1.62-129.72,32.08-223.67,64.08-256.07,8.54-8.65,16.9-13.03,24.84-13.03s16.3,4.39,24.84,13.03c31.99,32.39,65.69,126.35,64.08,256.08v703.15Zm-88.92-31.83c41.72,0,75.88,9.79,85.92,23.44V307.76c1.6-128.88-31.64-221.98-63.21-253.94-7.84-7.94-15.7-12.14-22.71-12.14s-14.86,4.2-22.71,12.14c-31.57,31.97-64.82,125.07-63.21,253.94v694.78c10.04-13.65,44.2-23.44,85.92-23.44Z"
            fill={theme.palette.divider}
          />
          <path
            d="m1668.8,1041.51c-17.45,0-34.29-1.77-48.7-5.13-6.65-1.55-12.65-3.41-17.83-5.52l-.16-.06V318.79c-.68-74.73,7.02-146.01,21.68-200.68,6.34-23.65,13.83-43.37,21.65-57.03,7.68-13.4,15.52-20.71,22.67-21.15h.2c.28-.03.66-.03.98,0h.16c7.17.43,15.01,7.74,22.69,21.15,7.83,13.66,15.32,33.38,21.66,57.03,14.66,54.68,22.37,125.95,21.69,200.68v712.01l-.16.06c-5.19,2.12-11.19,3.97-17.84,5.51-14.38,3.36-31.23,5.14-48.7,5.14Zm-66.19-11.05c5.12,2.08,11.04,3.91,17.6,5.43,14.37,3.35,31.18,5.12,48.59,5.12s34.24-1.77,48.59-5.13c6.56-1.51,12.49-3.33,17.61-5.42V318.79c.68-74.69-7.02-145.91-21.67-200.55-12.64-47.12-29.45-76.93-43.88-77.8h-.17c-.17-.02-.31-.01-.48-.02-.14,0-.31,0-.45.01h-.21c-14.41.87-31.23,30.68-43.86,77.8-14.65,54.63-22.34,125.86-21.67,200.55v711.67Z"
            fill={theme.palette.divider}
          />
          <path
            d="m1668.8,1041.51c-17.45,0-34.29-1.77-48.7-5.13l-.19-.04V318.79c-.5-74.74,5.14-146,15.87-200.66,9.44-48.06,21.59-77.29,32.53-78.19.32-.03.68-.03,1,0,10.93.9,23.09,30.13,32.52,78.19,10.73,54.66,16.37,125.93,15.87,200.67v717.53l-.19.04c-14.38,3.37-31.23,5.14-48.71,5.14Zm-48.4-5.57c14.33,3.32,31.06,5.07,48.4,5.07s34.09-1.76,48.4-5.09V318.79c.5-74.71-5.14-145.93-15.86-200.57-9.25-47.11-21.54-76.92-32.08-77.79-.15-.02-.33-.01-.46-.01-.14,0-.31,0-.45.01-10.54.87-22.83,30.67-32.08,77.79-10.73,54.63-16.36,125.86-15.86,200.57v717.15Z"
            fill={theme.palette.divider}
          />
          <path
            d="m1668.8,1041.51c-17.45,0-34.29-1.77-48.7-5.13-6.65-1.55-12.65-3.41-17.83-5.52-3.78-1.51-7.21-3.22-10.17-5.08l-.12-.07V318.79c-.78-74.81,8.11-146.14,25.03-200.83,7.32-23.66,15.96-43.37,24.99-57.01,8.86-13.38,17.89-20.64,26.13-21.02h.2c.28-.03.66-.03.98,0h.16c8.25.38,17.28,7.64,26.14,21.02,9.03,13.64,17.67,33.35,24.99,57.01,16.92,54.69,25.81,126.01,25.03,200.83v706.91l-.12.07c-2.88,1.81-6.3,3.52-10.16,5.08-5.18,2.12-11.19,3.97-17.84,5.51-14.38,3.36-31.23,5.14-48.7,5.14Zm-76.32-16.08c2.91,1.81,6.27,3.48,9.98,4.97,5.16,2.1,11.13,3.95,17.75,5.5,14.37,3.35,31.18,5.12,48.59,5.12s34.24-1.77,48.59-5.13c6.63-1.53,12.61-3.37,17.77-5.48,3.78-1.53,7.14-3.2,9.97-4.97V318.79c.78-74.77-8.1-146.04-25.01-200.68-14.59-47.15-34-76.91-50.67-77.67h-.17c-.17-.02-.31-.01-.48-.02-.14,0-.31,0-.45.01h-.21c-16.65.76-36.07,30.52-50.65,77.67-16.91,54.64-25.79,125.91-25.01,200.68v706.64Z"
            fill={theme.palette.divider}
          />
          <g>
            <path
              d="m1433.25,1084.05v-57.69l.38-.3,148.75-118.64v104.13s-149.13,72.5-149.13,72.5Zm2-56.73v53.53l145.13-70.56v-98.73s-145.13,115.75-145.13,115.75Z"
              fill={theme.palette.divider}
            />
            <rect
              x="1413.67"
              y="978.18"
              width="188.29"
              height="1"
              transform="translate(-280.97 1156.38) rotate(-38.66)"
              fill={theme.palette.divider}
            />
            <polygon
              points="1488.56 1056.3 1487.56 1056.3 1487.56 1027.22 1458.17 1018.7 1458.45 1017.74 1488.56 1026.47 1488.56 1056.3"
              fill={theme.palette.divider}
            />
            <rect
              x="1479.07"
              y="996.01"
              width="111.3"
              height="1"
              transform="translate(-295.14 997.85) rotate(-33.04)"
              fill={theme.palette.divider}
            />
            <rect
              x="1503.74"
              y="1016.33"
              width="1"
              height="32.1"
              fill={theme.palette.divider}
            />
            <rect
              x="1518.49"
              y="1006.74"
              width="1"
              height="34.52"
              fill={theme.palette.divider}
            />
          </g>
          <g>
            <path
              d="m1904.37,1084.05l-1.44-.7-147.69-71.8v-104.13s1.63,1.29,1.63,1.29l147.5,117.65v57.69Zm-147.13-73.75l145.13,70.56v-53.53l-145.13-115.75v98.73Z"
              fill={theme.palette.divider}
            />
            <rect
              x="1829.31"
              y="884.54"
              width="1"
              height="188.29"
              transform="translate(-76.81 1797.73) rotate(-51.39)"
              fill={theme.palette.divider}
            />
            <polygon
              points="1850.06 1056.3 1849.06 1056.3 1849.06 1026.47 1879.18 1017.74 1879.46 1018.7 1850.06 1027.22 1850.06 1056.3"
              fill={theme.palette.divider}
            />
            <rect
              x="1802.41"
              y="940.86"
              width="1"
              height="111.3"
              transform="translate(-15.23 1964.97) rotate(-56.97)"
              fill={theme.palette.divider}
            />
            <rect
              x="1832.88"
              y="1016.33"
              width="1"
              height="32.1"
              fill={theme.palette.divider}
            />
            <rect
              x="1818.13"
              y="1006.74"
              width="1"
              height="34.52"
              fill={theme.palette.divider}
            />
          </g>
          <path
            d="m1563.29,1172.53l.03-71.99,55.92-168.8-.03,104.72-55.91,136.08Zm1-71.75v66.69l53.94-131.3v-98.23s-53.94,162.84-53.94,162.84Z"
            fill={theme.palette.divider}
          />
          <rect
            x="1500.5"
            y="1032.75"
            width="182.01"
            height="1"
            transform="translate(122.71 2234.56) rotate(-72.27)"
            fill={theme.palette.divider}
          />
          <rect
            x="1553.13"
            y="1043.02"
            width="98.18"
            height="1"
            transform="translate(80.92 2201.82) rotate(-70.36)"
            fill={theme.palette.divider}
          />
          <rect
            x="1591"
            y="1073.54"
            width="1"
            height="25.8"
            fill={theme.palette.divider}
          />
          <rect
            x="1596.88"
            y="1057.07"
            width="1"
            height="29.37"
            fill={theme.palette.divider}
          />
          <g>
            <path
              d="m1774.34,1172.53l-55.94-136.27v-104.53s55.94,168.96,55.94,168.96v71.83Zm-54.94-136.37l53.94,131.3v-66.69l-53.94-162.84v98.23Z"
              fill={theme.palette.divider}
            />
            <rect
              x="1745.62"
              y="942.24"
              width="1"
              height="182.01"
              transform="translate(-231.72 580.84) rotate(-17.73)"
              fill={theme.palette.divider}
            />
            <rect
              x="1734.9"
              y="994.43"
              width="1"
              height="98.18"
              transform="translate(-249.84 644.33) rotate(-19.65)"
              fill={theme.palette.divider}
            />
            <rect
              x="1745.62"
              y="1073.54"
              width="1"
              height="25.8"
              fill={theme.palette.divider}
            />
            <rect
              x="1739.74"
              y="1057.07"
              width="1"
              height="29.37"
              fill={theme.palette.divider}
            />
          </g>
        </g>
      </g>
      <g id="Layer_6" data-name="Layer 6">
        <g>
          <path
            d="m506.48,182.62h-68.63c-.4,0-.77-.24-.92-.62-.15-.37-.07-.8.22-1.09l94.59-94.59L437.14-8.5c-.29-.29-.37-.72-.22-1.09.16-.37.52-.62.92-.62h68.63c.26,0,.52.1.71.29l91.59,91.37,3.95,4.17c.37.39.36,1.01-.02,1.39l-3.93,3.93-91.59,91.37c-.19.19-.44.29-.71.29Zm-66.22-2h65.81l94.54-94.32-3.26-3.45L506.07-8.2h-65.81l93.6,93.81c.39.39.39,1.02,0,1.41l-93.59,93.59Z"
            fill={theme.palette.divider}
          />
          <path
            d="m243.63,182.62h-68.63c-.4,0-.77-.24-.92-.62-.15-.37-.07-.8.22-1.09l94.59-94.59L174.29-8.5c-.29-.29-.37-.72-.22-1.09.16-.37.52-.62.92-.62h68.63c.26,0,.52.1.71.29l91.59,91.37,3.95,4.17c.37.39.36,1.01-.02,1.39l-3.93,3.93-91.59,91.37c-.19.19-.44.29-.71.29Zm-66.22-2h65.81l94.54-94.32-3.26-3.45L243.22-8.2h-65.81l93.6,93.81c.39.39.39,1.02,0,1.41l-93.59,93.59Z"
            fill={theme.palette.divider}
          />
          <path
            d="m375.06,182.62h-68.63c-.4,0-.77-.24-.92-.62-.15-.37-.07-.8.22-1.09l94.59-94.59L305.71-8.5c-.29-.29-.37-.72-.22-1.09.16-.37.52-.62.92-.62h68.63c.26,0,.52.1.71.29l91.59,91.37,3.95,4.17c.37.39.36,1.01-.02,1.39l-3.93,3.93-91.59,91.37c-.19.19-.44.29-.71.29Zm-66.22-2h65.81l94.54-94.32-3.26-3.45L374.64-8.2h-65.81l93.6,93.81c.39.39.39,1.02,0,1.41l-93.59,93.59Z"
            fill={theme.palette.divider}
          />
        </g>
        <g>
          <path
            d="m1183.9,1034.89h-52.22c-.26,0-.52-.11-.71-.29l-69.68-69.52-3.01-3.18c-.37-.39-.36-1.01.02-1.39l2.99-2.99,69.68-69.52c.19-.19.44-.29.71-.29h52.22c.4,0,.77.24.92.62.16.37.07.8-.22,1.09l-71.8,71.8,71.81,71.97c.29.29.37.72.22,1.09-.16.37-.52.62-.92.62Zm-51.81-2h49.4l-70.81-70.97c-.39-.39-.39-1.02,0-1.41l70.8-70.8h-49.39l-71.69,71.53,2.32,2.45,69.37,69.21Z"
            fill={theme.palette.divider}
          />
          <path
            d="m1383.89,1034.89h-52.22c-.26,0-.52-.11-.71-.29l-69.68-69.52-3.01-3.18c-.37-.39-.36-1.01.02-1.39l2.99-2.99,69.68-69.52c.19-.19.44-.29.71-.29h52.22c.4,0,.77.24.92.62.16.37.07.8-.22,1.09l-71.8,71.8,71.81,71.97c.29.29.37.72.22,1.09-.16.37-.52.62-.92.62Zm-51.81-2h49.4l-70.81-70.97c-.39-.39-.39-1.02,0-1.41l70.8-70.8h-49.39l-71.69,71.53,2.32,2.45,69.37,69.21Z"
            fill={theme.palette.divider}
          />
          <path
            d="m1283.89,1034.89h-52.22c-.26,0-.52-.11-.71-.29l-69.68-69.52-3.01-3.18c-.37-.39-.36-1.01.02-1.39l2.99-2.99,69.68-69.52c.19-.19.44-.29.71-.29h52.22c.4,0,.77.24.92.62.16.37.07.8-.22,1.09l-71.8,71.8,71.81,71.97c.29.29.37.72.22,1.09-.16.37-.52.62-.92.62Zm-51.81-2h49.4l-70.81-70.97c-.39-.39-.39-1.02,0-1.41l70.8-70.8h-49.39l-71.69,71.53,2.32,2.45,69.37,69.21Z"
            fill={theme.palette.divider}
          />
        </g>
      </g>
    </svg>
  );
}

export default ApplicationBackground;
