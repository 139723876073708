import HeightRoundedIcon from '@mui/icons-material/HeightRounded';
import RocketLaunchRoundedIcon from '@mui/icons-material/RocketLaunchRounded';
import LocalFireDepartmentRoundedIcon from '@mui/icons-material/LocalFireDepartmentRounded';
import StraightenRoundedIcon from '@mui/icons-material/StraightenRounded';
import {
  Box,
  Card,
  Container,
  Fade,
  Grid,
  styled,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';

import React from 'react';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import getProjectBackground from './getProjectImg';
import TimelineLayout from '../../components/timeline/TimelineLayout/TimelineLayout';
import TimelineTile from '../../components/timeline/TimelineTile/TimelineTile';
import InformationCard from '../../components/timeline/InformationCard/InformationCard';
import RocketCarousel from '../../components/Projects/RocketCarousel';

import birkelandGroundStation from '../../assets/images/rocket-projects/2022-birkeland/groundstation.webp';
import valemonPatch from '../../assets/images/rocket-projects/mission-patches/valemon_patch.png';
import valemonRocket from '../../assets/images/rocket-projects/2024-valemon/ValemonClosed.png';
import valemonLogo from '../../assets/images/rocket-projects/2024-valemon/Valemon-logo.png';
import valemonOuter from '../../assets/images/rocket-projects/2024-valemon/ValemonClosed-horizontal.png';
import valemonInner from '../../assets/images/rocket-projects/2024-valemon/ValemonOpen-horizontal.png';
import recovery from '../../assets/images/rocket-projects/2024-valemon/Recovery.jpg';
import thrustChamber from '../../assets/images/rocket-projects/2024-valemon/Thrust_chamber/chamber_valemon_13.jpg';
import injector from '../../assets/images/rocket-projects/2024-valemon/Thrust_chamber/Injector.PNG';
import tanksSetup from '../../assets/images/rocket-projects/2024-valemon/Thrust_chamber/tanks.webp';
import thrustChamberSetup from '../../assets/images/rocket-projects/2024-valemon/Thrust_chamber/thrust_chamber_setup.webp';
import testContainer from '../../assets/images/rocket-projects/2024-valemon/Testing/DSC_0126.webp';
import testSetup from '../../assets/images/rocket-projects/2024-valemon/Testing/DSC_0141.webp';
import preTest from '../../assets/images/rocket-projects/2024-valemon/Testing/DSC_0161.webp';
import hotfire from '../../assets/images/rocket-projects/2024-valemon/Testing/pic5.png';
import compositeCooking from '../../assets/images/rocket-projects/2024-valemon/Fins/Composite_cooking.png';
import currentlyCuring from '../../assets/images/rocket-projects/2024-valemon/Fins/IMG_1616.webp';
import compositeMould from '../../assets/images/rocket-projects/2024-valemon/Fins/IMG_1622.jpg';
import finishedFins from '../../assets/images/rocket-projects/2024-valemon/Fins/IMG_2227.PNG';
import Feedsystem_tank from '../../assets/images/rocket-projects/2024-valemon/Feedsystem_tank.png';
import { Feed } from '@mui/icons-material';

const BirkelandCard = styled(Card)(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: '#ffffff00',
  backgroundImage: 'none',
  backdropFilter: 'blur(12px)',
  boxShadow: theme.shadows[0],
  border: 'solid white 1px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 4
}));

const Valemon = () => {
  const carouselSlides = [
    {
      title: 'Outer Structure',
      content:
        'The outer structure serves as the load-bearing component and ensures the rocket is passively stable and aerodynamic. New this year are the 100% carbon fiber fins, hand-molded in a custom mold and post-processed in a CNC mill. The airframes are filament-wound fiber composites, where the forward airframe is made of radio-transparent fiberglass, while the aft airframe is made of carbon fiber.',
      image: valemonOuter
    },
    {
      title: 'Inner Structure',
      content:
        'The inner structure provides a sturdy framework for mounting the different components of the rocket. The upper half, or forward, houses different sections consisting of: Payload, recovery and pressurizing as well as avionics components such as cameras and PCBs in the different sections. The lower half of the rocket, or aft, houses the pre-engine section as well as the LOX tank and fuel tank. This lower half therefore has components related to the motor of the rocket. The inner-structure section design allows for a modular design which makes it easy to connect or remove the different sections from the rocket.',
      image: valemonInner
    }
    // {
    //   title: 'Propulsion System',
    //   content: 'This is the fuel tank of the rocket',
    //   image: valemonPropulsion
    // }
  ];

  // const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = React.useState('');

  const handleOpen = (modalID) => {
    setOpen(modalID);
  };
  const handleClose = () => {
    setOpen('');
  };

  return (
    <Fade in>
      <Box
        sx={{
          scrollSnapType: { xs: 'none', md: 'y mandatory' },
          scrollSnapStop: 'normal',
          height: '100vh',
          overflow: 'auto'
        }}
      >
        <Box
          component="img"
          src={getProjectBackground('Valemon')}
          sx={{
            position: 'fixed',
            top: 0,
            zIndex: -1,
            objectFit: 'cover',
            width: '100%',
            height: '100vh',
            background:
              'linear-gradient(0deg, rgba(230,230,230,1) 0%, rgba(255,255,255,1) 100%)'
          }}
        />
        <Box>
          <Box
            sx={{
              scrollSnapAlign: 'start',
              height: { xs: 'fit-content', md: '100vh' }
            }}
          >
            <Header
              sx={{
                width: '100%',
                zIndex: 1000
              }}
            />
            <Container maxWidth="lg">
              <Grid container justifyContent="center">
                <Grid
                  item
                  // xs={12}
                  md={4}
                  display={{ xs: 'none', sm: 'flex' }}
                  component="img"
                  src={valemonRocket}
                  sx={{
                    height: '600px',
                    objectFit: 'contain',
                    objectPosition: 'center'
                  }}
                />
                <Grid item xs={12} sm={8}>
                  <Grid container padding={4} spacing={{ xs: 2, md: 4 }}>
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      <Box display="flex" gap={4} alignItems="center">
                        <Box
                          component="img"
                          src={valemonLogo}
                          height={{ xs: 100, sm: 150, md: 180 }}
                        />
                        <Box>
                          <Typography
                            variant="h2"
                            color="white"
                            fontWeight={700}
                            fontSize={{
                              xs: '1.5rem',
                              sm: '2.7rem',
                              md: '3rem'
                            }}
                            // fontSize={{ xs: 28, md: 40 }}
                            sx={{ mt: { xs: 4, md: 0 } }}
                          >
                            PROJECT <br /> VALEMON
                          </Typography>
                          <Typography
                            variant="h4"
                            color="white"
                            fontWeight={600}
                            // fontSize={{ xs: 23, md: 25 }}
                            fontSize={{ xs: '1rem', sm: '1.5rem', md: '2rem' }}
                          >
                            TEAM 2024
                          </Typography>
                        </Box>
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{ color: 'white' }}
                        // fontWeight={300}
                        fontSize={{ xs: 16, md: 18 }}
                        // fontSize={{ xs: '0.8rem', md: '1rem' }}
                      >
                        Valemon is Propulse’s sixth rocket, and is named after a
                        white bear king from a Norwegian fairytale. It further
                        builds upon the bi-propellant liquid engine of our last
                        rocket, Bifrost, and was going to launch from Norwegian
                        soil the summer of 2024. Due to technical difficulties
                        and a short time frame, the rocket was never launched.
                        The knowledge and experience gained from the project is
                        still essential for the success of future projects.
                        <br />
                        <br />
                        {/* The objective of this project was to develop a reliable and capable rocket
                        and to enable members to enrich their engineering capabilities alongside
                        their studies. */}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <BirkelandCard
                        sx={{ p: 2, height: isMobile ? 150 : 140 }}
                      >
                        <RocketLaunchRoundedIcon
                          sx={{ fontSize: 40, color: 'white' }}
                        />
                        <Typography
                          variant="h6"
                          fontWeight={600}
                          fontSize={isMobile ? '0.7rem' : '0.8rem'}
                          sx={{ color: 'white', textAlign: 'center' }}
                        >
                          Ethanol & Liquid
                          <br />
                          Oxygen
                          <br />
                          Propellants
                        </Typography>
                      </BirkelandCard>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <BirkelandCard
                        sx={{ p: 2, height: isMobile ? 150 : 140 }}
                      >
                        <StraightenRoundedIcon
                          sx={{ fontSize: 40, color: 'white' }}
                        />
                        <Typography
                          variant="h6"
                          fontWeight={600}
                          fontSize={isMobile ? '0.7rem' : '0.8rem'}
                          sx={{ color: 'white', textAlign: 'center' }}
                        >
                          4.5 m <br /> Length
                        </Typography>
                      </BirkelandCard>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <BirkelandCard
                        sx={{ p: 2, height: isMobile ? 150 : 140 }}
                      >
                        <LocalFireDepartmentRoundedIcon
                          sx={{ fontSize: 40, color: 'white' }}
                        />
                        <Typography
                          variant="h6"
                          fontWeight={600}
                          fontSize={isMobile ? '0.7rem' : '0.8rem'}
                          sx={{ color: 'white', textAlign: 'center' }}
                        >
                          8000 N
                          <br />
                          Thrust
                        </Typography>
                      </BirkelandCard>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <BirkelandCard
                        sx={{ p: 2, height: isMobile ? 150 : 140 }}
                      >
                        <HeightRoundedIcon
                          sx={{ fontSize: 40, color: 'white' }}
                        />
                        <Typography
                          variant="h6"
                          fontWeight={600}
                          fontSize={isMobile ? '0.7rem' : '0.8rem'}
                          sx={{ color: 'white', textAlign: 'center' }}
                        >
                          2000 m <br /> Estimated <br /> Apogee
                        </Typography>
                      </BirkelandCard>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box
            sx={{
              scrollSnapAlign: 'center',
              height: { md: '100vh' },
              py: { xs: 8, md: 0 },
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <RocketCarousel slides={carouselSlides} />
          </Box>
          <Box
            px={{ xs: 4, md: 10 }}
            pt={{ xs: 10, md: 10 }}
            sx={{
              width: '100%',
              scrollSnapAlign: 'start',
              scrollSnapStop: 'always'
            }}
          >
            <TimelineLayout title="Avionics">
              <InformationCard
                title={'Avionics System'}
                content={
                  "The avionics system is divided into two computers: the flight computer and the engine computer. The flight computer collects navigation data using IMUs, GNSS, barometers, and accelerometers. This data is processed through a Kalman filter to estimate the rocket's altitude, which is used to activate the recovery system. The engine computer gathers pressure and temperature data from various parts of the propulsion system and controls all the valves related to the filling procedure, ignition, full burn, and shutdown sequence. These computers operate independently and were extensively tested during the test campaigns for both the propulsion and recovery systems over the summer. All data collected, along with video footage from one of the onboard cameras, is streamed in real time to the ground station, where it is processed, displayed, and stored. All the software and PCBs were researched and developed by students at Propulse."
                }
              />
              <InformationCard
                title={'Ground Station - Telemetry and radio tracker'}
                content={
                  'The avionics system transmits all data to the ground station in real time throughout the flight. In addition to storing telemetry, the ground stations also used an independent and redundant tracking method for the rocket.'
                }
              />
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-around',
                  paddingLeft: { xs: 0, md: '120px' }
                }}
              >
                <TimelineTile
                  ImageURL={birkelandGroundStation}
                  open={open}
                  handleClose={handleClose}
                  handleOpen={handleOpen}
                />
              </Box>
            </TimelineLayout>
            <TimelineLayout title="Mechanical">
              <InformationCard
                title={'Recovery System'}
                content={
                  'The recovery system of Valemon ensures a safe and controlled recovery of the rocket. The system contains a total of three chutes deployed in two independent phases. In order to increase reliability and redundancy, each phase deploys by filling a volume with pressurized gas, compressing the chute and ejects radially from Valemon. Both phases can be deployed independently by both our in-house flight computer and a commercial system simultaneously.'
                }
              />
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-around',
                  paddingLeft: { xs: 0, md: '120px' }
                }}
              >
                <TimelineTile
                  ImageURL={recovery}
                  open={open}
                  handleClose={handleClose}
                  handleOpen={handleOpen}
                  caption={'Mortars - canons for ejecting the chutes'}
                />
              </Box>
              <InformationCard
                title={'Fins'}
                content={
                  'The fins play a crucial role in ensuring stability during both launch and flight. The profile and size of the fin was iterated upon with tools in RocketPy and CFD analyses. This year, we introduced a fully CFRP (Carbon Fiber Reinforced Polymer) fin design—a first for Propulse. To ensure proper production prosess, we conducted tests with GFRP (Glass Fiber Reinforced Polymer) and 3D printed molds, before making a custom steel mold. After production the fins was machined to the desired profile by our parter NORBIT. To achieve precise alignment of the fins, we designed and manufactured a custom in-house CNC jig specifically for drilling holes in the carbon and fiberglass airframe.'
                }
              />
              <Grid
                container
                gap={2}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  paddingLeft: { xs: 0, md: '90px', lg: '120px' }
                }}
              >
                <Grid item xs={5}>
                  <TimelineTile
                    ImageURL={compositeCooking}
                    open={open}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    caption={'Cooking up fin composites'}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TimelineTile
                    ImageURL={currentlyCuring}
                    open={open}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    caption={'Patience is key when curing composites'}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TimelineTile
                    ImageURL={compositeMould}
                    open={open}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TimelineTile
                    ImageURL={finishedFins}
                    open={open}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    caption={'Finished fin'}
                  />
                </Grid>
              </Grid>
            </TimelineLayout>
            <TimelineLayout title="Propulsion">
              <InformationCard
                title={'Feed System'}
                content={
                  'The feed system provides propellant to the thrust chamber during engine operation. Using pressurized nitrogen at 300 bars it creates a controlled and consistent supply of ethanol and liquid oxygen to the combustion chamber, ensuring the generation of thrust. The feed system components include propellant tanks, valves, sensors, and pipelines responsible for storing, transferring, and regulating the flow of propellants to the combustion chamber.'
                }
              />
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-around',
                  paddingLeft: { xs: 0, md: '120px' }
                }}
              >
                <TimelineTile
                  ImageURL={Feedsystem_tank}
                  open={open}
                  handleClose={handleClose}
                  handleOpen={handleOpen}
                  caption={'Fuel tank'}
                />
              </Box>
              <InformationCard
                title={'Thrust chamber'}
                content={
                  'The thrust chamber is in many ways the heart of our rocket, consisting of a regeneratively cooled combustion chamber and coaxial swirl injector, both 3D printed in inconel 625. The thrust chamber is responsible for facilitating and converting the combustion reaction into usable thrust. Efficient combustion is achieved using a coaxial swirl injector while the converging diverging nozzle converts the thermal energy into kinetic energy propelling the rocket forward. Cooling channels running along the combustion chamber allows the chamber to survive flame temperatures above 2900C for an extended amount of time.'
                }
              />
              <Grid
                container
                gap={2}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  paddingLeft: { xs: 0, md: '90px', lg: '120px' }
                }}
              >
                <Grid item xs={5}>
                  <TimelineTile
                    ImageURL={thrustChamber}
                    open={open}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    caption={'Thrust Chamber'}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TimelineTile
                    ImageURL={injector}
                    open={open}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    caption={'Injector'}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TimelineTile
                    ImageURL={tanksSetup}
                    open={open}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    caption={'Setting up for a test'}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TimelineTile
                    ImageURL={thrustChamberSetup}
                    open={open}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    caption={'A view of the of the injector through the nozzle'}
                  />
                </Grid>
              </Grid>
            </TimelineLayout>
            <TimelineLayout title="Testing">
              <InformationCard
                title={'Test site'}
                content={
                  'Ensuring the reliability and safety of our rocket propulsion systems is important at Propulse NTNU. To achieve this, we conduct a lot of tests at our dedicated test site, located just a 15-minute drive from Trondheim. This privately owned land provides a secure and controlled environment for high-pressure tests. We currently have one large shipping container that acts as the main test station and storage for equipment and fuel.'
                }
              />
              <Grid
                container
                gap={2}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  paddingLeft: { xs: 0, md: '90px', lg: '120px' }
                }}
              >
                <Grid item xs={5}>
                  <TimelineTile
                    ImageURL={testContainer}
                    open={open}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TimelineTile
                    ImageURL={testSetup}
                    open={open}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                  />
                </Grid>
              </Grid>
              <InformationCard
                title={''}
                content={
                  'The test site container is partitioned into two sections by a self-built blast shield. One side is simply storage, where we keep our fuel, pressurized gases, tools, gas-driven generators, PPE, and first aid equipment. The other, reinforced section, is where the testing occurs. The walls are reinforced, and the floor is removed and replaced with metal plates in case of LOX leaks. The test stand is secured to the frame of the container with welded bolts. With this setup, we are able to conduct static hot fire tests. This is crucial to verify our designs and gather essential performance data, such as thrust force, fluid pressure throughout the feed system, and validating valve control.'
                }
              />
              <Grid
                container
                gap={2}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  paddingLeft: { xs: 0, md: '90px', lg: '120px' }
                }}
              >
                <Grid item xs={5}>
                  <TimelineTile
                    ImageURL={preTest}
                    open={open}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TimelineTile
                    ImageURL={hotfire}
                    open={open}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                  />
                </Grid>
              </Grid>
            </TimelineLayout>
          </Box>

          <Box sx={{ scrollSnapAlign: 'start', minHeight: '100vh', pb: 2 }}>
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                pt: 2
              }}
            >
              <Box sx={{ px: { xs: 4, md: 8 }, width: '100%' }}>
                <Grid
                  container
                  sx={{
                    width: '100%',
                    border: '2px solid white',
                    boxSizing: 'border-box',
                    borderRadius: 10,
                    minHeight: '90vh',
                    overflow: 'hidden',
                    backdropFilter: 'blur(12px)'
                  }}
                >
                  <Grid
                    item
                    xs={6}
                    md={6}
                    lg={6}
                    display="flex"
                    flexDirection="column"
                    sx={{ borderRight: '1px solid white' }}
                  >
                    <Box
                      sx={{
                        p: isMobile ? 2 : 4,
                        borderBottom: '1px solid white'
                      }}
                    >
                      <Typography
                        variant="h4"
                        color="white"
                        fontWeight={600}
                        fontSize={isMobile ? '1rem' : '2rem'}
                        sx={{ borderRight: '10px solid #0035f7' }}
                      >
                        PROJECT <br /> VALEMON
                      </Typography>
                    </Box>
                    <Box
                      flex={4}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        borderBottom: '1px solid white',
                        p: isMobile ? 2 : 4
                      }}
                    >
                      <img src={valemonRocket} style={{ maxHeight: 600 }} />
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    md={6}
                    lg={6}
                    container
                    direction="column"
                    sx={{ borderRight: isMobile ? 'none' : '1px solid white' }}
                  >
                    <Grid
                      item
                      xs={4}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        borderBottom: '1px solid white',
                        p: isMobile ? 2 : 4
                      }}
                    >
                      <Box
                        sx={{
                          height: '100%',
                          width: '100%',
                          backgroundImage: `url(${valemonPatch})`,
                          backgroundSize: 'contain',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat'
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      gap={1}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        borderBottom: '1px solid white',
                        p: isMobile ? 1 : 1.5
                      }}
                    >
                      <RocketLaunchRoundedIcon
                        sx={{ fontSize: isMobile ? 30 : 40, color: 'white' }}
                      />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={isMobile ? '0.7rem' : '1rem'}
                        sx={{ color: 'white', textAlign: 'center' }}
                      >
                        Ethanol & Liquid
                        <br />
                        Oxygen
                        <br />
                        Propellants
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      gap={1}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        borderBottom: '1px solid white',
                        p: isMobile ? 1 : 1.5
                      }}
                    >
                      <StraightenRoundedIcon
                        sx={{ fontSize: isMobile ? 30 : 40, color: 'white' }}
                      />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={isMobile ? '0.7rem' : '1rem'}
                        sx={{ color: 'white', textAlign: 'center' }}
                      >
                        4.5 m
                        <br />
                        Length
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      gap={1}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        borderBottom: '1px solid white',
                        p: isMobile ? 1 : 1.5
                      }}
                    >
                      <LocalFireDepartmentRoundedIcon
                        sx={{ fontSize: isMobile ? 30 : 40, color: 'white' }}
                      />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={isMobile ? '0.7rem' : '1rem'}
                        sx={{ color: 'white', textAlign: 'center' }}
                      >
                        8000 N
                        <br />
                        Thrust
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      gap={1}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        borderBottom: '1px solid white',
                        p: isMobile ? 1 : 1.5
                      }}
                    >
                      <HeightRoundedIcon
                        sx={{ fontSize: isMobile ? 30 : 40, color: 'white' }}
                      />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={isMobile ? '0.7rem' : '1rem'}
                        sx={{ color: 'white', textAlign: 'center' }}
                      >
                        2000 m
                        <br />
                        Estimated <br /> Apogee
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* <Grid item xs={12} md={4} lg={5} container direction="column">
                    <Grid
                      item
                      xs={4}
                      display="flex"
                      flexDirection="column"
                      sx={{ width: '100%', borderBottom: '1px solid white' }}>
                      <Typography
                        variant="h6"
                        color="white"
                        fontWeight={500}
                        fontSize={isMobile ? '1em' : '1.5rem'}
                        sx={{ borderBottom: '1px solid white', p: 2 }}>
                        Hotfire #1 Video
                      </Typography>
                      <Box sx={{ height: '100%' }}>
                        
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      display="flex"
                      flexDirection="column"
                      sx={{ width: '100%', borderBottom: '1px solid white' }}>
                      <Typography
                        variant="h6"
                        color="white"
                        fontWeight={500}
                        fontSize={isMobile ? '1em' : '1.5rem'}
                        sx={{ borderBottom: '1px solid white', p: 2 }}>
                        Static Fire
                      </Typography>
                      <Box sx={{ height: '100%' }}>
                        
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      md={2}
                      component="a"
                      href="https://drive.google.com/file/d/1qoPLVJreVJVfzt83ld4Hf5VG7saDnWdd/view"
                      target="_blank"
                      gap={2}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        color: 'white',
                        borderBottom: '1px solid white',
                        p: 2,
                        cursor: 'pointer',
                        '&:hover': {
                          bgcolor: 'white',
                          '& .MuiTypography-root': { color: 'black' },
                          '& .MuiSvgIcon-root': { color: 'black' }
                        }
                      }}>
                      <Typography
                        variant="h5"
                        color="white"
                        fontWeight={600}
                        fontSize={isMobile ? '1em' : '1.5rem'}>
                        Technical Report
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      md={2}
                      onClick={() => {
                        navigate('/Projects');
                      }}
                      gap={2}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        color: 'white',
                        p: isMobile ? 2 : 4,
                        cursor: 'pointer',
                        '&:hover': {
                          bgcolor: 'white',
                          color: 'black',
                          '& .MuiTypography-root': { color: 'black' },
                          '& .MuiSvgIcon-root': { color: 'black' }
                        }
                      }}>
                      <ArrowBackIosNewIcon color="inheret" />
                      <Typography
                        variant="h5"
                        color="white"
                        fontWeight={600}
                        fontSize={isMobile ? '1rem' : '1.5rem'}>
                        Back to PROJECTS
                      </Typography>
                    </Grid>
                  </Grid> */}
                </Grid>
                <Footer color="#fff" />
              </Box>
            </Box>
          </Box>
          <Box sx={{ scrollSnapAlign: 'end' }}></Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default Valemon;
