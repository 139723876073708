import {
  Box,
  Button,
  Card,
  Container,
  Fade,
  Grid,
  Icon,
  useTheme,
  CardMedia,
  Typography,
  IconButton,
  styled
} from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import BurnChamberIcon from '@mui/icons-material/LocalFireDepartment';
import FuelCastingIcon from '@mui/icons-material/OilBarrel';
import OxidizerSystemIcon from '@mui/icons-material/Route';
import IgnitionSystemIcon from '@mui/icons-material/Whatshot';
import SESystemIcon from '@mui/icons-material/OfflineBolt';
import CombustionSystemIcon from '@mui/icons-material/Celebration';
import TestSiteIcon from '@mui/icons-material/Handyman';

import hybridLogo from '../../assets/images/hybrid/hybridLogo.png';
import hybrid_bg from '../../assets/images/hybrid/hybrid_bg.jpg';
import stock_engine from '../../assets/images/hybrid/hybrid_go_brr.jpg';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const Specifications = [
  {
    name: 'Burn Chamber',
    icon: BurnChamberIcon,
    description:
      'This was where the NO2 was sprayed into and the combustion occurred. Designing this area was challenging due to the extreme internal temperatures of up to 3000°C, which required us to consider insulation solutions to prevent the aluminum housing from melting.'
  },
  {
    name: 'Fuel Casting',
    icon: FuelCastingIcon,
    description:
      'The fuel grain, shaped like a hollow cylinder, was made by melting a wax and carbon mixture, poured into a custom mold, and cured in an industrial-grade oven over an extended period to ensure solidity and uniformity.'
  },
  {
    name: 'Oxidizer System',
    icon: OxidizerSystemIcon,
    description:
      'An advanced tank holder was developed for the oxidizer system, which was tested using CO2 as an imitation of NO2 in the coldfire test, including a multitude of sensors and actuators.'
  },
  {
    name: 'Ignition System',
    icon: IgnitionSystemIcon,
    description:
      'Tasked with solving the problem of ignition, this team explored several creative solutions to achieve the high ignition temperature required.'
  },
  {
    name: 'Software and Electrical System',
    icon: SESystemIcon,
    description:
      'This team handled sensors, actuators, microcontrollers, and the development of software to operate all components and collect data.'
  },
  {
    name: 'Combustion System',
    icon: CombustionSystemIcon,
    description:
      'The combustion team evaluated the actions inside the burn chamber and suggested changes to the fuel grain shape and oxidizer supply rate for optimal burn rate and thrust profile.'
  },
  {
    name: 'Test Site',
    icon: TestSiteIcon,
    description:
      'The test site served as the staging ground for our various engine tests, encompassing an advanced tank holder, blast shield, and test bench within a refurbished shipping container. It also housed what is probably the world’s first camper-transformed mission control center, optimizing practicality and safety for the testing team while maintaining feng shui.'
  }
];

const TimelineItems = [
  {
    year: '2019',
    items: [
      {
        item: 'Planning the project',
        description:
          'Our journey began in 2018, with the formation of the organization and the ambitious goal to develop a rocket and self-made hybrid engine entirely from scratch. Our plan was to launch a rocket powered by a Commercial-Off-The-Shelf (COTS) motor in 2019 and follow it with a launch powered by our in-house developed engine in 2020.'
      },
      {
        item: 'Sleipner launch data',
        description:
          'Throughout 2019, as we successfully launched Sleipner, our first rocket, in the New Mexico desert, the engine development team was immersed in research and calculations. The foundational work performed during this period guided our design choices in the subsequent stages. Concurrently, we developed a small test engine with an estimated thrust of 1 kN, using NO2 as an oxidizer and polyethylene as solid fuel.'
      }
    ]
  },
  {
    year: '2020',
    items: [
      {
        item: 'Prototype testing',
        description:
          'Entering 2020, our focus shifted to testing the small engine and initiating the design and development of a full-scale engine prototype. The large engine, estimated to produce a thrust of 5 kN, was to be divided into test hardware and flight hardware, whereas the test hardware and the first generation test bench was built. '
      },
      {
        item: 'Re-evaluation of the 2021 timeline',
        description:
          "As the year progressed, we realized that our original timeline was too ambitious, and we wouldn't meet the 2020 deadline set prior. In response to this setback, we re-evaluated our plan for 2021 and extended the development timeline by two years, focusing on thorough testing and construction of more versatile and streamlined testing facilities. The new goal was to integrate the 5 kN hybrid engine into the Stetind rocket for the EuRoC 2022 launch in Portugal."
      }
    ]
  },
  {
    year: '2021',
    items: [
      {
        item: 'Test site and construction',
        description:
          'Over the course of these two additional years, we made significant strides. We established a suitable test site and started initial construction. Efforts were also invested in setting up a comfortable and practical mission control center'
      },
      {
        item: 'Oxidizer station',
        description:
          'During 2021, a more advanced oxidizer station was developed. Simultaneously, we started the construction of our workshop to expedite part production and conduct fuel casting to mention a few.'
      },
      {
        item: 'Successful cold fire test',
        description:
          "One of the major milestones during 2021 was the execution of a successful cold fire test to verify the functionality of the oxidizer system and the associated components. It provided us with valuable insights into our system's performance under operational conditions, albeit with CO2 substituting for NO2."
      }
    ]
  },
  {
    year: '2022',
    items: [
      {
        item: 'Complications and setbacks',
        description:
          'As 2022 rolled in, one of our main challenges was designing an effective burn chamber that could withstand extreme temperatures while ensuring optimal combustion. Although we made significant progress, some challenges remained unsolved, specifically related to ignition and the casting of the fuel grain. This led to the difficult decision not to integrate the hybrid engine into the Stetind rocket, marking the end of the hybrid engine project to avoid becoming a victim of the sunk-cost fallacy.'
      },
      {
        item: 'Retrospect',
        description:
          'Our four-year journey, from 2018 to 2022, was a path laden with challenges, but each hurdle provided us with invaluable lessons that will guide our future initiatives. Despite not fully realizing our original ambitions, this venture has nonetheless established a robust foundation upon which future projects will build.'
      },
      {
        item: 'The End?',
        description:
          'The knowledge acquired, the relationships nurtured, and the infrastructure established during this period have become invaluable assets for Propulse. As we look forward, the Hybrid Engine project is not seen as a closed chapter, but rather as a pivotal stepping stone in our trajectory of rocket development.'
      }
    ]
  }
];

const GlassCard = styled(Card)(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: 'rgba(10,10,10, 0.50)',
  backgroundImage: 'none',
  backdropFilter: 'blur(12px)',
  boxShadow: theme.shadows[0],
  gap: 4
}));

const HybridTitle = ({ title }) => {
  return (
    <Box className="animate-slide-right">
      <Box
        my={2}
        sx={{
          width: { xs: '25vw', md: '10vw' },
          height: { xs: '1vh', md: '1.2vh' },
          backgroundColor: 'primary.main'
        }}
      />
      <Typography
        mb={3}
        variant="h1"
        fontWeight={700}
        fontSize={{ xs: 42, md: 58 }}
        sx={{
          color: 'white',
          textAlign: 'left'
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

const Hybrid = () => {
  const navigate = useNavigate();
  const scrolldown = useRef('');
  const [spec, setSpec] = useState('Burn Chamber');
  const [fadeIn, setFadeIn] = useState(false);
  const bgRef = useRef(null);

  const getNameDescription = (name) => {
    const result = Specifications.find((spec) => spec.name === name);
    return result ? result.description : '';
  };

  const executeScroll = () =>
    scrolldown.current.scrollIntoView({
      behavior: 'smooth'
    });

  useEffect(() => {
    setFadeIn(true);
  }, [spec]);

  useEffect(() => {
    const gridItems = document.querySelectorAll(
      '.animate-slide-in, .animate-slide-right, .animate-slide-left'
    );
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
        } else {
          entry.target.classList.remove('animate');
        }
      });
    });
    gridItems.forEach((item) => observer.observe(item));

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleSpecChange = (newSpec) => {
    setFadeIn(false);
    setTimeout(() => {
      setSpec(newSpec);
      setFadeIn(true);
    }, 300);
  };

  return (
    <Fade in style={{ transitionDelay: '500ms' }}>
      <Box
        sx={{
          scrollSnapType: { xs: 'none', md: 'y mandatory' },
          scrollSnapStop: 'always',
          height: '100vh',
          width: '100vw',
          overflow: 'auto',
          overscrollBehavior: 'contain'
        }}
      >
        <Box
          sx={{
            scrollSnapAlign: 'end',
            overflow: 'hidden'
          }}
        >
          <Box
            component="img"
            src={hybrid_bg}
            sx={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100vh',
              transition: 'transform .5s ease-out',
              objectFit: { xs: 'cover', md: 'fill' },
              zIndex: '-1'
            }}
          />
          <Header
            sx={{
              width: '100%',
              zIndex: 1000
            }}
          />
          <Box
            sx={{
              width: '100%',
              height: '85vh'
            }}
          >
            <Grid
              container
              sx={{ px: 4, pb: 8, width: '100%', height: '100%' }}
            >
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    navigate('/Projects');
                  }}
                  disableRipple
                  variant="outlined"
                  color="inherit"
                  startIcon={<ArrowBackIosNewIcon />}
                  sx={{ mb: 4, borderRadius: 12, color: 'white' }}
                >
                  Back to Projects
                </Button>
                <Grid
                  pb={10}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    position: 'relative'
                  }}
                >
                  <Typography
                    variant="h1"
                    fontWeight={600}
                    fontSize={{ xs: 46, md: 96 }}
                    sx={{
                      color: 'white',
                      textAlign: 'center',
                      lineHeight: '120%',
                      letterSpacing: '0px',
                      fontFamily: 'D-DIN'
                    }}
                  >
                    HYBRID ENGINE
                  </Typography>
                  <Box
                    component="img"
                    src={hybridLogo}
                    sx={{
                      height: { xs: '20vh', md: '20vh' },
                      width: 'auto',
                      backgroundSize: { xs: 'cover', md: 'contain' }
                    }}
                  />
                  <Box
                    onClick={executeScroll}
                    className="animate-scrolldown"
                    mb={2}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      height: '22%',
                      width: '5vw',
                      position: 'absolute',
                      bottom: '0'
                    }}
                  >
                    <Typography
                      variant="p"
                      fontWeight={400}
                      fontSize={{ xs: 24, md: 20 }}
                      sx={{
                        color: 'white',
                        textAlign: 'Left',
                        lineHeight: '120%',
                        letterSpacing: '2px',
                        fontFamily: 'D-DIN'
                      }}
                    >
                      SCROLL
                    </Typography>
                    <Box
                      mt={2}
                      sx={{
                        height: '100%',
                        width: '.10vw',
                        backgroundColor: 'white'
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          ref={scrolldown}
          sx={{
            height: { xs: 'auto', md: '100vh' },
            minHeight: { xs: '85vh' },
            width: '100%',
            // bgcolor: 'background.default',
            objectFit: { xs: 'contain', md: 'contain' },
            scrollSnapAlign: 'center',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'center'
          }}
        >
          <Grid
            container
            py={10}
            px={{ xs: 3, md: 10 }}
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' }
            }}
          >
            <Grid
              item
              className="animate-slide-right"
              xs={12}
              md={5.5}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start'
              }}
            >
              <Box
                my={2}
                sx={{
                  width: { xs: '25vw', md: '10vw' },
                  height: { xs: '1vh', md: '1.2vh' },
                  backgroundColor: 'primary.main'
                }}
              />
              <Typography
                mb={5}
                variant="h1"
                fontWeight={700}
                fontSize={{ xs: 32, md: 52 }}
                sx={{
                  color: 'white',
                  textAlign: 'Left',
                  lineHeight: '120%',
                  letterSpacing: '0px'
                }}
              >
                Project Goals.
              </Typography>
              <Typography
                mb={3}
                variant="p"
                fontWeight={300}
                fontSize={{ xs: 16, md: 18 }}
                sx={{
                  color: 'white',
                  textAlign: 'left'
                }}
              >
                The goal for the Hybrid project was to design, develop, test,
                and fly a fully self-developed hybrid rocket engine. With this
                engine being the first self-developed engine project by
                Propulse, it was a great undertaking at the time with only one
                rocket under our belt.
              </Typography>
              <Typography
                mb={3}
                variant="p"
                fontWeight={300}
                fontSize={{ xs: 16, md: 18 }}
                sx={{
                  color: 'white',
                  textAlign: 'left'
                }}
              >
                The engine development extended past the rocket project
                timeframe and became an R&D project.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          px={{ xs: 3, md: 10 }}
          py={10}
          sx={{
            height: { xs: 'auto', md: '100vh' },
            minHeight: { xs: '100vh' },
            width: '100%',
            // background: 'radial-gradient(circle, #181818, #151515, #111111, #0d0d0d, #070707)',
            objectFit: { xs: 'contain', md: 'contain' },
            scrollSnapAlign: 'center',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box className="animate-slide-right">
            <Box
              my={2}
              sx={{
                width: { xs: '25vw', md: '9vw' },
                height: { xs: '1vh', md: '1.2vh' },
                backgroundColor: 'primary.main'
              }}
            />
            <Typography
              mb={3}
              variant="h1"
              fontWeight={700}
              fontSize={{ xs: 32, md: 56 }}
              sx={{
                color: 'white',
                textAlign: 'left'
              }}
            >
              Engine Breakdown
            </Typography>
          </Box>
          <Grid
            container
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: { xs: 'column-reverse', md: 'row' },
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
          >
            <Grid className="animate-slide-right" item md={6}>
              <GlassCard
                sx={{
                  backdropFilter: { xs: 'none', md: 'blur(6px)' },
                  padding: '4% 8%',
                  borderRadius: { xs: '10px', md: '20px' }
                }}
              >
                <Box
                  mb={3}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: { xs: 'center', md: 'flex-start' }
                  }}
                >
                  {Specifications.map((item, index) => (
                    <IconButton
                      key={index}
                      onClick={() => handleSpecChange(item.name)}
                      sx={[
                        {
                          '&:hover': {
                            bgcolor: spec === item.name ? 'primary.main' : ''
                          }
                        },
                        {
                          marginRight: '3%',
                          bgcolor: spec === item.name ? 'primary.main' : '',
                          color: spec === item.name ? 'white' : 'white'
                        }
                      ]}
                    >
                      <Icon component={item.icon} />
                    </IconButton>
                  ))}
                </Box>
                <Fade
                  in={fadeIn}
                  timeout={600}
                  onExited={() => setFadeIn(false)}
                >
                  <Box>
                    <Typography
                      mb={4}
                      variant="h1"
                      fontWeight={600}
                      fontSize={{ xs: 28, md: 42 }}
                      sx={{
                        color: 'white',
                        textAlign: 'left'
                      }}
                    >
                      {spec}
                    </Typography>
                    <Typography
                      variant="h1"
                      fontWeight={300}
                      fontSize={{ xs: 16, md: 18 }}
                      sx={{
                        color: 'white',
                        textAlign: 'left'
                      }}
                    >
                      {getNameDescription(spec)}
                    </Typography>
                  </Box>
                </Fade>
              </GlassCard>
            </Grid>
            <Grid
              md={6}
              my={{ xs: 5, md: 0 }}
              item
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Box
                className={'animate-slide-left'}
                sx={{
                  background: {
                    md: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('${stock_engine}')`
                  },
                  borderRadius: { md: '20px' },
                  width: { xs: '40vh', md: '60vh' },
                  height: { md: '45vh' },
                  backgroundSize: { md: 'contain' }
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          px={{ xs: 3, md: 10 }}
          pt={{ xs: 5, md: 10 }}
          sx={{
            width: '100%',
            scrollSnapAlign: 'start'
          }}
        >
          <HybridTitle title="The Timeline." />
          {TimelineItems.map((item, index) => (
            <Box
              key={index}
              py={{ xs: 4, md: 15 }}
              sx={{
                zIndex: '5'
              }}
            >
              <Grid container sx={{ position: 'relative' }}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }}
                >
                  <GlassCard
                    className="animate-slide-right"
                    sx={{
                      position: 'sticky',
                      top: { xs: '100vh', md: '40vh' },
                      padding: '3%, 5%',
                      marginY: { xs: '5%', md: '13%' }
                    }}
                  >
                    <Typography
                      px={2}
                      py={2}
                      variant="h1"
                      fontWeight={700}
                      fontSize={{ xs: 42, md: 72 }}
                      sx={{
                        color: 'white',
                        textAlign: 'center',
                        transition: 'opacity 1s ease-out'
                      }}
                    >
                      Year{' '}
                      <span style={{ color: 'var(--propulse-blue)' }}>
                        {item.year}
                      </span>
                    </Typography>
                  </GlassCard>
                </Grid>
                <Grid
                  item
                  pl={{ xs: 0, md: 5 }}
                  xs={12}
                  md={8}
                  my={1}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  {item.items.map((subItem, index) => (
                    <GlassCard
                      key={index}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: { xs: '100%', md: '85%' },
                        padding: '3% 5%',
                        margin: { xs: '8%', md: '6%' }
                      }}
                    >
                      <Box
                        my={2}
                        sx={{
                          width: { xs: '20vw', md: '6vw' },
                          height: { xs: '1.5vw', md: '.4vw' },
                          bgcolor: 'primary.main'
                        }}
                      />
                      <Typography
                        mb={3}
                        variant="h1"
                        fontWeight={600}
                        fontSize={{ xs: 30, md: 32 }}
                        sx={{
                          color: 'white',
                          transition: 'opacity 1s ease-out'
                        }}
                      >
                        {subItem.item}
                      </Typography>
                      <Typography
                        variant="p"
                        fontWeight={300}
                        fontSize={{ xs: 18, md: 18 }}
                        sx={{
                          color: 'white',
                          transition: 'opacity 1s ease-out'
                        }}
                      >
                        {subItem.description}
                      </Typography>
                    </GlassCard>
                  ))}
                </Grid>
              </Grid>
            </Box>
          ))}
          <Footer style={{ margin: 'auto' }} />
        </Box>
        <div style={{ height: '1px', scrollSnapAlign: 'start' }}></div>
      </Box>
    </Fade>
  );
};

export default Hybrid;
