import {
  Box,
  Card,
  CardActionArea,
  Fade,
  Grid,
  Typography,
  useTheme
} from '@mui/material';
import React from 'react';
import { ReactComponent as TeamLogo } from '../../assets/images/graphical/icons/teamlogo.svg';
import './PositionCard.css';

const PositionCard = ({
  title,
  division,
  desc,
  setPosition,
  handlePopupOpen,
  obj
}) => {
  const theme = useTheme();
  return (
    <Fade in timeout={1000}>
      <Card
        elevation={8}
        sx={{
          width: '100%',
          height: { xs: 250, md: 250 },
          borderRadius: 8,
          position: 'relative',
          '&:hover': {
            transform: 'scale(1.02)'
          }
        }}
      >
        <Box
          component={CardActionArea}
          disableRipple
          disableTouchRipple
          width="100%"
          height="100%"
          p={2}
          onClick={() => {
            setPosition(title);
            handlePopupOpen(obj, division, title);
          }}
        >
          <Grid
            container
            width="100%"
            height="100%"
            sx={{
              borderRadius: 4,
              overflow: 'hidden',
              border: `2px solid ${theme.palette.divider}`
            }}
          >
            <Grid
              item
              xs={4}
              sx={{
                display: { xs: 'none', sm: 'flex' },
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                borderRight: `1px solid ${theme.palette.divider}`
              }}
            >
              <TeamLogo width="60%" fill={theme.palette.secondary.main} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Typography
                variant="h1"
                color="primary.main"
                fontSize={{ xs: '1.2rem', sm: '1.5rem' }}
                fontWeight={700}
                py={1}
                px={2}
                sx={{
                  bgcolor: 'primary.main',
                  color: 'primary.contrastText',
                  width: '100%',
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  justifySelf: 'center'
                }}
              >
                {title}
              </Typography>

              <Typography
                variant="h1"
                color="text.primary"
                py={1}
                px={2}
                fontSize={{ xs: '0.9rem', sm: '1rem' }}
                fontWeight={600}
                sx={{
                  bgcolor: 'secondary.main',
                  color: 'secondary.contrastText',
                  width: '100%',
                  borderBottom: `1px solid ${theme.palette.divider}`
                }}
              >
                {division}
              </Typography>

              <Typography
                variant="body1"
                fontSize={{ xs: '0.8rem', sm: '0.8rem' }}
                py={1}
                px={2}
                fontWeight={300}
                sx={{ flex: 1, overflow: 'auto' }}
              >
                {desc}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Fade>
  );
};

export default PositionCard;
