import { Button, styled } from '@mui/material';

import React from 'react';

const PropulseButtonStyle = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  boxShadow: 'none',
  padding: '8px 20px',
  fontWeight: 700,

  '&:hover': {
    color: theme.palette.secondary.main,
    boxShadow: 'none'
  }
}));

function PropulseButton(props) {
  return (
    <PropulseButtonStyle variant="contained" disableRipple {...props}>
      {props.children}
    </PropulseButtonStyle>
  );
}

export default PropulseButton;
