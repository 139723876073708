import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FastForwardRoundedIcon from '@mui/icons-material/FastForwardRounded';
import HeightRoundedIcon from '@mui/icons-material/HeightRounded';
import RocketLaunchRoundedIcon from '@mui/icons-material/RocketLaunchRounded';
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  Card,
  Container,
  Button,
  Fade,
  Grid,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useRef } from 'react';
import getProjectBackground from './getProjectImg';
import birkelandRocket from '../../assets/images/rocket-projects/2022-birkeland/birkeland_rocket.webp';
import birkelandRocketInner from '../../assets/images/rocket-projects/2022-birkeland/birkeland_rocket_inner.webp';
import birkelandPatch from '../../assets/images/rocket-projects/mission-patches/Birkeland_missionPatch.png';
import birkelandLogo from '../../assets/images/rocket-projects/2022-birkeland/birkeland_logo_edited.png';
import birkelandGroundStation from '../../assets/images/rocket-projects/2022-birkeland/groundstation.webp';
import birkelandMCRS from '../../assets/images/rocket-projects/2022-birkeland/mcrs.png';

import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import { motion, useScroll, useTransform } from 'framer-motion';
import Footer from '../../components/Footer/Footer';
import ReactPlayer from 'react-player';

const BirkelandCard = styled(Card)(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: '#ffffff00',
  backgroundImage: 'none',
  backdropFilter: 'blur(12px)',
  boxShadow: theme.shadows[0],
  border: 'solid #00DEE7 1px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 4
}));

const Birkeland = () => {
  const navigate = useNavigate();
  const ref = useRef();
  const { scrollYProgress } = useScroll({ container: ref });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const bottomAnim = useTransform(
    scrollYProgress,
    [0.1, 0.16, 0.42, 0.44],
    [
      isMobile ? '0vh' : '0%',
      isMobile ? '-100vh' : '-120%',
      isMobile ? '100vh' : '120%',
      isMobile ? '-30vh' : '-20%'
    ]
  );
  const leftAnim = useTransform(
    scrollYProgress,
    [0.1, 0.16, 0.42, 0.44, 0.48, 0.62, 0.64, 0.68, 0.72],
    [
      isMobile ? '70vw' : '20vw',
      isMobile ? '10vw' : '20vw',
      isMobile ? '10vw' : '20vw',
      isMobile ? '-170vw' : '-60vw',
      isMobile ? '-170vw' : '-60vw',
      isMobile ? '-50vw' : '20vw',
      isMobile ? '-50vw' : '20vw',
      isMobile ? '0vw' : '40vw',
      isMobile ? '50vw' : '60vw'
    ]
  );
  const rotateAnim = useTransform(scrollYProgress, [0.42, 0.44], [0, 90]);
  const scaleAnim = useTransform(
    scrollYProgress,
    [0.1, 0.16, 0.42, 0.44, 0.48],
    [
      isMobile ? 0.8 : 0.85,
      isMobile ? 3 : 4,
      isMobile ? 3 : 4,
      isMobile ? 3 : 6,
      isMobile ? 3 : 6
    ]
  );
  const opacityAnim = useTransform(
    scrollYProgress,
    [0.32, 0.42, 0.44, 0.48, 0.76, 0.8],
    [1, 0, 0, 1, 1, 0]
  );
  const opacityAnim2 = useTransform(
    scrollYProgress,
    [0.16, 0.2, 0.24, 0.28, 0.44, 0.48, 0.76, 0.8],
    [0, 1, 1, 0, 0, 1, 1, 0]
  );

  const inner_opacity = useTransform(
    scrollYProgress,
    [0.16, 0.2, 0.24, 0.28],
    [0, 1, 1, 0]
  );
  const outer_opacity = useTransform(
    scrollYProgress,
    [0.24, 0.28, 0.32, 0.42],
    [0, 1, 1, 0]
  );
  const payload_opacity = useTransform(
    scrollYProgress,
    [0.44, 0.48, 0.52, 0.56],
    [0, 1, 1, 0]
  );
  const avionics_opacity = useTransform(
    scrollYProgress,
    [0.52, 0.56, 0.62, 0.64],
    [0, 1, 1, 0]
  );
  const avionics2_opacity = useTransform(
    scrollYProgress,
    [0.62, 0.64, 0.68, 0.72],
    [0, 1, 1, 0]
  );
  const recover_opacity = useTransform(
    scrollYProgress,
    [0.68, 0.72, 0.76, 0.8],
    [0, 1, 1, 0]
  );

  React.useEffect(() => {
    console.log(scrollYProgress);
  }, [scrollYProgress]);

  return (
    <Fade in>
      <Box
        ref={ref}
        sx={{
          height: '100vh',
          overflowY: 'auto',
          '& .MuiTypography-root': {
            pointerEvents: 'none'
          }
        }}
      >
        <Box
          component="img"
          src={getProjectBackground('Birkeland')}
          sx={{
            position: 'fixed',
            top: 0,
            zIndex: -1000,
            objectFit: 'cover',
            width: '100%',
            height: '100vh',
            background:
              'linear-gradient(0deg, rgba(230,230,230,1) 0%, rgba(255,255,255,1) 100%)'
          }}
        />
        <motion.img
          src={birkelandRocket}
          style={{
            position: 'fixed',
            zIndex: -100,
            bottom: bottomAnim,
            left: leftAnim,
            rotateZ: rotateAnim,
            scale: scaleAnim,
            opacity: opacityAnim,
            height: '90%',
            objectFit: 'contain',
            objectPosition: 'center'
          }}
        />
        <motion.img
          src={birkelandRocketInner}
          style={{
            position: 'fixed',
            zIndex: -100,
            bottom: bottomAnim,
            left: leftAnim,
            rotateZ: rotateAnim,
            scale: scaleAnim,
            opacity: opacityAnim2,
            height: '90%',
            objectFit: 'contain',
            objectPosition: 'center'
          }}
        />
        <Box>
          <Header
            sx={{
              position: 'static',
              width: '100%',
              zIndex: -10
            }}
          />
          <Grid item xs={12}>
            <Button
              onClick={() => {
                navigate('/Projects');
              }}
              disableRipple
              variant="outlined"
              // color="inherit"
              startIcon={<ArrowBackIosNewIcon />}
              sx={{
                ml: { xs: 3, md: 10 },
                mb: 4,
                borderRadius: 12,
                color: '#00DEE7',
                borderColor: '#00DEE7'
              }}
            >
              Back to Projects
            </Button>
          </Grid>
          <Container maxWidth="lg">
            <Grid container sx={{ px: 4, pb: 8 }}>
              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={8}>
                <Grid container spacing={{ xs: 2, md: 4 }}>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                  >
                    <Box display="flex" gap={4} alignItems="center">
                      <Box
                        component="img"
                        src={birkelandLogo}
                        height={{ xs: 100, md: 200 }}
                      />
                      <Box>
                        <Typography
                          variant="h2"
                          color="black"
                          fontWeight={700}
                          fontSize={{ xs: '1.5rem', md: '3rem' }}
                          sx={{ mt: { xs: 4, md: 0 } }}
                        >
                          PROJECT <br /> BIRKELAND
                        </Typography>
                        <Typography
                          variant="h4"
                          color="black"
                          fontWeight={600}
                          fontSize={{ xs: '1rem', md: '2rem' }}
                        >
                          TEAM 2022
                        </Typography>
                      </Box>
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{ color: 'black' }}
                      fontSize={{ xs: '0.8rem', md: '1rem' }}
                    >
                      Named after the Norwegian scientist Kristian Birkeland,
                      the “father” of Aurora Borealis, the rocket Birkeland was
                      developed in 10 months and launched at Spaceport America
                      Cup 2022. The objective of this project was to develop a
                      reliable and capable rocket and to enable members to
                      enrich their engineering capabilities alongside their
                      studies. <br />
                      <br /> Birkeland was highly influenced by our previous
                      flight-proven rocket, Stetind.
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <BirkelandCard sx={{ height: isMobile ? 150 : 180 }}>
                      <RocketLaunchRoundedIcon
                        sx={{ fontSize: 60, color: 'black' }}
                      />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={isMobile ? '0.7rem' : '1rem'}
                        sx={{ color: 'black', textAlign: 'center' }}
                      >
                        Solid Motor
                        <br />
                        (Cesaroni Pro 98)
                      </Typography>
                    </BirkelandCard>
                  </Grid>
                  <Grid item xs={6}>
                    <BirkelandCard sx={{ height: isMobile ? 150 : 180 }}>
                      <HeightRoundedIcon
                        sx={{ fontSize: 60, color: 'black' }}
                      />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={isMobile ? '0.7rem' : '1rem'}
                        sx={{ color: 'black' }}
                      >
                        9014 m <br /> Apogee
                      </Typography>
                    </BirkelandCard>
                  </Grid>
                  <Grid item xs={6}>
                    <BirkelandCard sx={{ height: isMobile ? 150 : 180 }}>
                      <SpeedRoundedIcon sx={{ fontSize: 60, color: 'black' }} />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={isMobile ? '0.7rem' : '1rem'}
                        sx={{ color: 'black', textAlign: 'center' }}
                      >
                        Mach 1.5 (1850km/h)
                        <br />
                        Top speed
                      </Typography>
                    </BirkelandCard>
                  </Grid>
                  <Grid item xs={6}>
                    <BirkelandCard sx={{ height: isMobile ? 150 : 180 }}>
                      <FastForwardRoundedIcon
                        sx={{ fontSize: 60, color: 'black' }}
                      />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={isMobile ? '0.7rem' : '1rem'}
                        sx={{ color: 'black', textAlign: 'center' }}
                      >
                        0-100 km/h
                        <br />
                        in 0.21 seconds
                      </Typography>
                    </BirkelandCard>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box sx={{ height: '600vh' }}></Box>
        <motion.div
          style={{
            position: 'fixed',
            zIndex: -10,
            top: isMobile ? '15vh' : '30%',
            left: '40%',
            right: isMobile ? '10vw' : '10%',
            color: 'black',
            opacity: outer_opacity
          }}
        >
          <Box
            sx={{
              borderRadius: 4,
              p: isMobile ? 1 : 2,
              backdropFilter: 'blur(12px)',
              WebkitBackdropFilter: 'blur(12px)'
            }}
          >
            <Typography
              variant="h2"
              fontWeight={600}
              fontSize={isMobile ? '2rem' : '4rem'}
            >
              Outer Structure
            </Typography>
            <Typography
              variant="h4"
              fontWeight={600}
              fontSize={isMobile ? '1.5rem' : '2rem'}
            >
              Von Kármán shape
            </Typography>
            <Typography
              variant="body1"
              maxWidth={500}
              mt={2}
              fontSize={isMobile ? '0.7rem' : '1rem'}
            >
              The airframe is constructed of two filament wound fiber composite
              sections that are optimized for low drag at supersonic speeds, by
              replicating the Von Kármán shape towards both the rocket&apos;s
              nose tip and the boat tail. The forward airframe is made out of
              radio transparent fiberglass, whereas the aft is made out of
              carbon fiber. The four trapezoidal fins are also made of carbon
              fiber, and mounted with aluminum fin brackets.
            </Typography>
          </Box>
        </motion.div>
        <motion.div
          style={{
            position: 'fixed',
            top: isMobile ? '20vh' : '30%',
            zIndex: -10,
            left: '40%',
            right: isMobile ? '10vw' : '10%',
            color: 'black',
            opacity: inner_opacity
          }}
        >
          <Box
            sx={{
              borderRadius: 4,
              p: isMobile ? 1 : 2,
              backdropFilter: 'blur(12px)',
              WebkitBackdropFilter: 'blur(12px)'
            }}
          >
            <Typography
              variant="h2"
              fontWeight={600}
              fontSize={isMobile ? '2rem' : '4rem'}
            >
              Inner structure
            </Typography>
            <Typography
              variant="h4"
              fontWeight={600}
              fontSize={isMobile ? '1.5rem' : '2rem'}
            >
              Module based
            </Typography>
            <Typography
              variant="body1"
              maxWidth={500}
              mt={2}
              fontSize={isMobile ? '0.7rem' : '1rem'}
            >
              The inner structure provides a framework and mounting points for
              the internal components of the rocket. The upper half, housing the
              payload, avionics, and recovery modules, is module-based and
              allows for parallel, independent, and thus faster assembly. The
              structural components within the lower half are related to the
              motor, the camera shell, and recovery of the lower half.
            </Typography>
          </Box>
        </motion.div>
        <motion.div
          style={{
            position: 'fixed',
            top: isMobile ? '10vh' : '25%',
            zIndex: -10,
            left: isMobile ? '10vw' : '40%',
            right: isMobile ? '10vw' : '10%',
            color: 'black',
            opacity: payload_opacity
          }}
        >
          <Box
            sx={{
              borderRadius: 4,
              p: isMobile ? 1 : 2,
              backdropFilter: 'blur(12px)',
              WebkitBackdropFilter: 'blur(12px)'
            }}
          >
            <Typography
              variant="h2"
              fontWeight={600}
              fontSize={isMobile ? '2rem' : '4rem'}
            >
              Payload
            </Typography>
            <Typography
              variant="h4"
              fontWeight={600}
              fontSize={isMobile ? '1.5rem' : '2rem'}
            >
              Measuring oscillations
            </Typography>
            <Typography
              variant="body1"
              maxWidth={500}
              mt={2}
              fontSize={isMobile ? '0.7rem' : '1rem'}
            >
              The 3U CanSat experimental payload measures the in-flight
              oscillations and compares the recorded vibrational noise from IMU
              measurements for a damped versus undamped set of electronics.
            </Typography>
          </Box>
        </motion.div>
        <motion.div
          style={{
            position: 'fixed',
            top: isMobile ? '10vh' : '25%',
            zIndex: -10,
            left: isMobile ? '10vw' : '40%',
            right: isMobile ? '10vw' : '10%',
            color: 'black',
            opacity: avionics_opacity
          }}
        >
          <Box
            sx={{
              borderRadius: 4,
              p: isMobile ? 1 : 2,
              backdropFilter: 'blur(12px)',
              WebkitBackdropFilter: 'blur(12px)'
            }}
          >
            <Typography
              variant="h2"
              fontWeight={600}
              fontSize={isMobile ? '2rem' : '4rem'}
            >
              Avionics
            </Typography>
            <Typography
              variant="h4"
              fontWeight={600}
              fontSize={isMobile ? '1.5rem' : '2rem'}
            >
              Flight computer and electronics
            </Typography>
            <Typography
              variant="body1"
              maxWidth={900}
              mt={2}
              fontSize={isMobile ? '0.7rem' : '1rem'}
            >
              The avionics system controls the recovery system, and monitors the
              flight using IMUs, a barometer, magnetometer, and GNSS, which is
              passed through a multiplicative extended Kalman filter (MEKF). All
              collected data, as well as one of five captured video streams from
              the onboard cameras, is transmitted down to the ground station.
              The flight computer software and the majority of the PCBs were
              student researched and developed..
            </Typography>
          </Box>
        </motion.div>
        <motion.div
          style={{
            position: 'fixed',
            top: isMobile ? '10vh' : '10%',
            zIndex: -10,
            left: isMobile ? '10vw' : '40%',
            right: isMobile ? '10vw' : '10%',
            color: 'black',
            opacity: avionics2_opacity
          }}
        >
          <Box
            sx={{
              borderRadius: 4,
              p: isMobile ? 1 : 2,
              backdropFilter: 'blur(12px)',
              WebkitBackdropFilter: 'blur(12px)'
            }}
          >
            <Typography
              variant="h2"
              fontWeight={600}
              fontSize={isMobile ? '2rem' : '4rem'}
            >
              Ground Station
            </Typography>
            <Typography
              variant="h4"
              fontWeight={600}
              fontSize={isMobile ? '1.5rem' : '2rem'}
            >
              Telemetry and radio tracker
            </Typography>
            <Box
              display="flex"
              mt={2}
              gap={2}
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems="center"
            >
              <Typography
                variant="body1"
                maxWidth={900}
                mt={2}
                fontSize={isMobile ? '0.7rem' : '1rem'}
              >
                The avionics system transmits all data to the ground station in
                real time throughout the flight. In addition to storing
                telemetry, the ground stations also used an independent and
                redundant tracking method for the rocket.
              </Typography>
              <img
                src={birkelandGroundStation}
                height={isMobile ? 200 : 280}
                style={{
                  marginTop: theme.spacing(2),
                  borderRadius: theme.spacing(4)
                }}
              />
            </Box>
          </Box>
        </motion.div>
        <motion.div
          style={{
            position: 'fixed',
            top: isMobile ? '10vh' : '10%',
            zIndex: -10,
            left: isMobile ? '10vw' : '10%',
            right: isMobile ? '10vw' : '10%',
            color: 'black',
            opacity: recover_opacity
          }}
        >
          <Box
            sx={{
              borderRadius: 4,
              p: isMobile ? 1 : 2,
              backdropFilter: 'blur(12px)',
              WebkitBackdropFilter: 'blur(12px)'
            }}
          >
            <Typography
              variant="h2"
              fontWeight={600}
              fontSize={isMobile ? '2rem' : '4rem'}
            >
              Recovery
            </Typography>
            <Typography
              variant="h4"
              fontWeight={600}
              fontSize={isMobile ? '1.5rem' : '2rem'}
            >
              Dual-event parachute recovery
            </Typography>
            <Box
              display="flex"
              mt={2}
              gap={2}
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems="center"
            >
              <img src={birkelandMCRS} height={isMobile ? 150 : 300} />
              <Typography
                variant="body1"
                maxWidth={isMobile ? 'none' : 750}
                fontSize={isMobile ? '0.7rem' : '1rem'}
              >
                The recovery system includes both a commercial and
                student-developed parachute deployment systems for redundancy,
                consisting of two parachutes. The drogue chute is deployed at
                apogee as the rocket is separated into two halves by a surge of
                gas within the coupler section. The main chute is then deployed
                at 450 meters above ground level and slows down the rocket to
                using our student developed main chute release system (MCRS).
              </Typography>
            </Box>
          </Box>
        </motion.div>

        <Box sx={{ p: { xs: 4, md: 10 } }}>
          <Grid
            container
            sx={{
              width: '100%',
              border: '2px solid black',
              boxSizing: 'border-box',
              borderRadius: 10,
              minHeight: '90vh',
              overflow: 'hidden',
              backdropFilter: 'blur(12px)'
            }}
          >
            <Grid
              item
              xs={6}
              md={3}
              display="flex"
              flexDirection="column"
              sx={{ borderRight: '1px solid black' }}
            >
              <Box
                sx={{ p: isMobile ? 2 : 4, borderBottom: '1px solid black' }}
              >
                <Typography
                  variant="h4"
                  color="black"
                  fontWeight={600}
                  fontSize={isMobile ? '1rem' : '2rem'}
                  sx={{ borderRight: '10px solid #00DEE7' }}
                >
                  PROJECT <br /> BIRKELAND
                </Typography>
              </Box>
              <Box
                flex={4}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  p: isMobile ? 2 : 4,
                  borderBottom: isMobile ? '1px solid black' : 'none'
                }}
              >
                <img src={birkelandRocket} style={{ maxHeight: 600 }} />
              </Box>
            </Grid>

            <Grid
              item
              xs={6}
              md={3}
              display="flex"
              flexDirection="column"
              sx={{ borderRight: isMobile ? 'none' : '1px solid black' }}
            >
              <Box
                flex={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ borderBottom: '1px solid black', p: 2 }}
              >
                <img
                  src={birkelandPatch}
                  style={{
                    aspectRatio: '1/1',
                    maxHeight: isMobile ? 150 : 120
                  }}
                />
              </Box>
              <Box
                flex={1}
                gap={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ borderBottom: '1px solid black' }}
              >
                <RocketLaunchRoundedIcon
                  sx={{ fontSize: 60, color: 'black' }}
                />
                <Typography
                  variant="h6"
                  fontWeight={600}
                  fontSize={isMobile ? '0.7rem' : '1rem'}
                  sx={{ color: 'black', textAlign: 'center' }}
                >
                  Solid Motor
                  <br />
                  (Cesaroni Pro 98)
                </Typography>
              </Box>
              <Box
                flex={1}
                gap={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ borderBottom: '1px solid black' }}
              >
                <HeightRoundedIcon sx={{ fontSize: 60, color: 'black' }} />
                <Typography
                  variant="h6"
                  fontWeight={600}
                  fontSize={isMobile ? '0.7rem' : '1rem'}
                  sx={{ color: 'black' }}
                >
                  9162 m <br /> Apogee
                </Typography>
              </Box>
              <Box
                flex={1}
                gap={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ borderBottom: '1px solid black' }}
              >
                <SpeedRoundedIcon sx={{ fontSize: 60, color: 'black' }} />
                <Typography
                  variant="h6"
                  fontWeight={600}
                  fontSize={isMobile ? '0.7rem' : '1rem'}
                  sx={{ color: 'black', textAlign: 'center' }}
                >
                  1.5 Mach (1850 km/h)
                  <br />
                  Top speed
                </Typography>
              </Box>
              <Box
                flex={1}
                gap={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ borderBottom: isMobile ? '1px solid black' : 'none' }}
              >
                <FastForwardRoundedIcon sx={{ fontSize: 60, color: 'black' }} />
                <Typography
                  variant="h6"
                  fontWeight={600}
                  fontSize={isMobile ? '0.7rem' : '1rem'}
                  sx={{ color: 'black', textAlign: 'center' }}
                >
                  0-100 km/h
                  <br />
                  in 0.21 seconds
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={6}
              md={3}
              display="flex"
              flexDirection="column"
              sx={{ borderRight: '1px solid black' }}
            >
              <Box
                flex={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ borderBottom: '1px solid black', p: 2 }}
              >
                <Typography
                  variant="h4"
                  color="black"
                  textAlign="center"
                  fontWeight={500}
                  fontSize={isMobile ? '1.25rem' : '2.5rem'}
                >
                  2nd Place
                </Typography>
                <Typography
                  variant="body1"
                  textAlign="center"
                  color="black"
                  fontSize={isMobile ? '0.7rem' : '1rem'}
                >
                  30&apos;K COTS Category
                </Typography>
              </Box>
              <Box
                flex={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ borderBottom: '1px solid black', p: 2 }}
              >
                <Typography
                  variant="h4"
                  color="black"
                  textAlign="center"
                  fontWeight={500}
                  fontSize={isMobile ? '1.25rem' : '2.5rem'}
                >
                  2nd Team Spirit Award
                </Typography>
                <Typography
                  variant="body1"
                  textAlign="center"
                  color="black"
                  fontSize={isMobile ? '0.7rem' : '1rem'}
                >
                  Nancy Squires
                </Typography>
              </Box>
              <Box
                flex={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ borderBottom: '1px solid black', p: 2 }}
              >
                <Typography
                  variant="h4"
                  color="black"
                  textAlign="center"
                  fontWeight={500}
                  fontSize={isMobile ? '1.25rem' : '2.5rem'}
                >
                  2nd Team Video
                </Typography>
                <Typography
                  variant="body1"
                  textAlign="center"
                  color="black"
                  fontSize={isMobile ? '0.7rem' : '1rem'}
                >
                  GoPro Challenge
                </Typography>
              </Box>
              <Box
                flex={2}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ p: 2 }}
              >
                <Typography
                  variant="h4"
                  color="black"
                  textAlign="center"
                  fontWeight={500}
                  fontSize={isMobile ? '1.25rem' : '2.5rem'}
                >
                  3rd Overall
                </Typography>
                <Typography
                  variant="body1"
                  textAlign="center"
                  color="black"
                  fontSize={isMobile ? '0.7rem' : '1rem'}
                >
                  Spaceport America Cup 2022
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={6} md={3} display="flex" flexDirection="column">
              <Typography
                variant="h6"
                color="black"
                fontWeight={500}
                fontSize={isMobile ? '1em' : '1.5rem'}
                sx={{ borderBottom: '1px solid black', p: 2 }}
              >
                Project Video
              </Typography>
              <Box
                sx={{
                  aspectRatio: '16/9',
                  borderBottom: '1px solid black',
                  pb: '1px'
                }}
              >
                <ReactPlayer
                  url="https://www.youtube.com/watch?v=uqYXnqTVpcU&t=29s"
                  width="100%"
                  height="100%"
                />
              </Box>

              <Typography
                variant="h6"
                color="black"
                fontWeight={500}
                fontSize={isMobile ? '1em' : '1.5rem'}
                sx={{ borderBottom: '1px solid black', p: 2 }}
              >
                SA CUP 2022
              </Typography>
              <Box
                sx={{
                  aspectRatio: '16/9',
                  borderBottom: '1px solid black',
                  pb: '1px'
                }}
              >
                <ReactPlayer
                  url="https://www.youtube.com/watch?v=7vV388FC1Rw"
                  width="100%"
                  height="100%"
                />
              </Box>

              <Box
                component="a"
                href="https://drive.google.com/file/d/1Byni1ETKsQToTn2DUVB22vVI9b0enwhq/view"
                target="_blank"
                flex={1}
                gap={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: 'black',
                  borderBottom: '1px solid black',
                  p: 2,
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: 'black',
                    '& .MuiTypography-root': { color: 'white' },
                    '& .MuiSvgIcon-root': { color: 'white' }
                  }
                }}
              >
                <OpenInNewIcon />
                <Typography
                  variant="h5"
                  color="black"
                  fontWeight={600}
                  fontSize={isMobile ? '1em' : '1.5rem'}
                >
                  Technical Report
                </Typography>
              </Box>

              <Box
                onClick={() => {
                  navigate('/Projects');
                }}
                flex={1}
                gap={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  p: 2,
                  color: 'black',
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: 'black',
                    '& .MuiTypography-root': { color: 'white' },
                    '& .MuiSvgIcon-root': { color: 'white' }
                  }
                }}
              >
                <ArrowBackIosNewIcon />
                <Typography
                  variant="h5"
                  color="black"
                  fontWeight={600}
                  fontSize={isMobile ? '1em' : '1.5rem'}
                >
                  Back to PROJECTS
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Footer color="#000000" />
        </Box>
      </Box>
    </Fade>
  );
};

export default Birkeland;
