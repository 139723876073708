import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid
} from '@mui/material';
import axios from 'axios';

import React, { useState, useEffect } from 'react';

import PropulseCard from '../Universal/PropulseCard';

function InstagramFeed(props) {
  // State to hold the user's media
  const [media, setMedia] = useState([]);

  // Effect to fetch the user's media on component mount
  useEffect(() => {
    // Function to fetch the user's media
    const fetchMedia = async () => {
      try {
        // Make a GET request to the Instagram API to retrieve the user's media
        const response = await axios.get(
          `https://graph.instagram.com/me/media?fields=id,media_type,media_url,thumbnail_url,permalink&limit=${
            props.limit || 4
          }&access_token=${process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN}`
        );
        // Update the media state with the retrieved media
        setMedia(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchMedia();
  }, []);

  // Render the user's media
  return (
    <Grid
      component={CardContent}
      container
      spacing={2}
      sx={{ p: 4, '&:last-child': { pb: 4 } }}
    >
      {media.map((post) => (
        <Grid item key={post.id} xs={6} md={3}>
          <PropulseCard
            sx={{
              boxShadow: 8,
              transition: '0.5s',
              transitionTimingFunction: 'cubic-bezier(.08,.92,0,1)',
              '&:hover': { transform: 'scale(1.05)' }
            }}
          >
            {/* {post.media_type ? console.log(post.media_type) : console.log('no media type')} */}
            <Box component="a" href={post.permalink} target="_blank">
              {post.media_type === 'VIDEO' ? (
                <CardMedia
                  component="video"
                  src={post.media_url}
                  alt={post.id}
                  key={post.id}
                  sx={{ width: '100%', aspectRatio: '1', objectFit: 'cover' }}
                />
              ) : (
                <CardMedia
                  component="img"
                  image={post.media_url}
                  alt={post.id}
                  key={post.id}
                  sx={{ width: '100%', aspectRatio: '1', objectFit: 'cover' }}
                />
              )}
            </Box>
          </PropulseCard>
        </Grid>
      ))}
    </Grid>
  );
}

export default InstagramFeed;
