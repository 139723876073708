import React from 'react';

import './PositionPopup.css';
import {
  Box,
  Fade,
  Typography,
  Backdrop,
  Card,
  Grid,
  Button,
  useTheme,
  Modal,
  Link,
  Icon
} from '@mui/material';

import { ReactComponent as TeamLogo } from '../../assets/images/graphical/icons/teamlogo.svg';
import PropulseButton from '../core/Universal/PropulseButton';

import DoubleArrowRounded from '@mui/icons-material/DoubleArrowRounded';

import avionics_bg from '../../assets/images/backgrounds/ApplicationBackgrounds/avionics_bg.jpg';
import propulsion_bg from '../../assets/images/backgrounds/ApplicationBackgrounds/propulsion_bg.jpg';
import bf_bg from '../../assets/images/backgrounds/ApplicationBackgrounds/bf_bg.jpg';
import marketing_bg from '../../assets/images/backgrounds/ApplicationBackgrounds/marketing_bg.jpg';
import devops_bg from '../../assets/images/backgrounds/ApplicationBackgrounds/devops-2.jpg';
import mechanical_bg from '../../assets/images/backgrounds/ApplicationBackgrounds/mechanical_bg.jpg';

const backdrops = {
  Marketing: marketing_bg,
  Avionics: avionics_bg,
  DevOps: devops_bg,
  'Business & Finance': bf_bg,
  Propulsion: propulsion_bg,
  Mechanical: mechanical_bg
};

const PositionPopup = ({ positionInformationObject, openPopup, close }) => {
  const theme = useTheme();
  const [imageState, setImageState] = React.useState(null);
  // const positionImage = positionInformationObject.subGroupImage;
  const imageLoad = (positionInformationObject) => {
    switch (positionInformationObject.subGroupImage) {
      case '':
        setImageState(
          `url(${backdrops[positionInformationObject.subGroupDivision]})`
        );
        break;
      default:
        import(
          `../../assets/images/backgrounds/ApplicationBackgrounds/${positionInformationObject.subGroupImage}`
        )
          .then((image) => {
            setImageState(`url(${image.default})`);
          })
          .catch((error) => {});
    }
  };

  // console.log(positionInformationObject);

  return (
    <Modal
      disableAutoFocus
      open={openPopup}
      onClose={close}
      closeAfterTransition
      sx={{
        px: { xs: 2, sm: 10, md: 24 },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(6px)',
        border: 'none'
      }}
    >
      <Fade in={openPopup} timeout={500}>
        <Box
          sx={{
            height: '80vh',
            zIndex: 100,
            bgcolor: 'background.paper',
            borderRadius: 8,
            overflow: 'hidden',
            overflowY: 'scroll',
            '::-webkit-scrollbar': {
              display: 'none'
            },
            '-ms-overflow-style': 'none'
          }}
        >
          <Box
            sx={{
              positions: 'relative',
              width: '100%',
              height: '32%',
              backgroundColor: 'black',
              background: imageState
                ? imageState
                : imageLoad(positionInformationObject),
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <Box
              px={{ xs: 4, md: 10 }}
              py={{ xs: 4, md: 5 }}
              sx={{
                width: '100%',
                background:
                  'linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.6) 40%,rgba(0,0,0,0) 100%)'
              }}
            >
              <Box
                sx={{
                  width: '12vw',
                  height: '1.2vh',
                  backgroundColor: 'primary.main',
                  display: { xs: 'none', md: 'block' }
                }}
              />
              <Box
                display="flex"
                alignItems="center"
                gap={4}
                py={{ xs: 0, sm: 3 }}
              >
                <TeamLogo height={100} fill={theme.palette.secondary.main} />
                <Box>
                  <Typography
                    variant="h1"
                    color="white"
                    fontSize={{ xs: '2rem', md: '2rem' }}
                    fontWeight={700}
                  >
                    {positionInformationObject.subGroupName}
                  </Typography>
                  <Typography
                    mt={1}
                    variant="h2"
                    color="white"
                    fontSize={{ xs: '1.2rem', md: '1.5rem' }}
                    fontWeight={500}
                  >
                    {positionInformationObject.subGroupDivision}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            px={{ xs: 4, md: 10 }}
            pt={{ xs: 4, md: 6 }}
            pb={{ xs: 4, md: 4 }}
          >
            <Typography
              variant="h3"
              color="primary.main"
              fontSize={{ xs: '1.6rem', md: '1.3rem' }}
              fontWeight={{ xs: 600, md: 500 }}
              mb={2}
            >
              Description
            </Typography>
            <Typography
              variant="body2"
              color=""
              fontSize="1.05rem"
              fontWeight={300}
              mb={3}
            >
              Propulse NTNU has since 2018 designed, produced and launched
              rockets, and with that broken the sound barrier several times, won
              competitions, and created tight bonds to the aerospace industry.
              We develop our own high-powered rocket engines, and we now seek to
              fly faster, higher and louder.
            </Typography>
            <Typography
              variant="body2"
              color=""
              fontSize="1.05rem"
              fontWeight={300}
              mb={3}
            >
              Project 2025 will deliver a payload from Norwegian soil to
              altitudes surpassing that of commercial airlines. This space
              adventure is an ambitious undertaking, and we&apos;re therefore
              looking for a motivated {positionInformationObject.subGroupName}{' '}
              to join our team.
            </Typography>
            <Typography
              variant="body2"
              color=""
              fontSize="1.05rem"
              fontWeight={300}
              mb={3}
              style={{ whiteSpace: 'pre-line' }}
            >
              {positionInformationObject.Description}
            </Typography>
            <Typography
              variant="body2"
              color=""
              fontSize="1.05rem"
              fontWeight={300}
              mb={6}
            >
              The duration of this position is a{' '}
              <span style={{ color: '#2d3afe', fontWeight: '500' }}>
                minimum
              </span>{' '}
              of{' '}
              <span style={{ color: '#2d3afe', fontWeight: '500' }}>two</span>{' '}
              semesters. Working hours will be around{' '}
              <span style={{ color: '#2d3afe', fontWeight: '500' }}>
                {positionInformationObject.EstTime} hours per week
              </span>
              , with flexible options. Be aware that it is not the hours that
              matter, but the work you get done.
            </Typography>

            <Grid container spacing={4}>
              <Grid item xs={12} lg={4}>
                <Typography
                  variant="h2"
                  fontSize="1.3rem"
                  fontWeight={500}
                  mb={2}
                  color="primary.main"
                >
                  Responsibilities
                </Typography>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    zIndex: 10,
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  {positionInformationObject.Responsibilities &&
                    positionInformationObject.Responsibilities.map((item) => (
                      <Grid
                        key={item}
                        item
                        xs={12}
                        my={0.3}
                        display="flex"
                        sx={{ zIndex: 10 }}
                      >
                        <DoubleArrowRounded
                          color="secondary"
                          sx={{ width: { xs: '3vw', md: '.8vw' } }}
                        />
                        <Typography
                          pl={1}
                          variant="body2"
                          color=""
                          fontSize={{ xs: '1.1rem', md: '1rem' }}
                          fontWeight={300}
                        >
                          {item}
                        </Typography>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography
                  variant="h2"
                  fontSize="1.3rem"
                  fontWeight={500}
                  mb={2}
                  color="primary.main"
                >
                  What You Will Get
                </Typography>
                <Grid container spacing={2}>
                  {positionInformationObject.WYWG &&
                    positionInformationObject.WYWG.map((item) => (
                      <Grid
                        key={item}
                        item
                        xs={12}
                        my={0.3}
                        sx={{
                          zIndex: 10,
                          display: 'flex',
                          flexDirection: 'row'
                        }}
                      >
                        <DoubleArrowRounded
                          color="secondary"
                          sx={{ width: { xs: '3vw', md: '.8vw' } }}
                        />
                        <Typography
                          pl={1}
                          variant="body2"
                          color=""
                          fontSize={{ xs: '1.1rem', md: '1rem' }}
                          fontWeight={300}
                        >
                          {item}
                        </Typography>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography
                  variant="h2"
                  fontSize="1.3rem"
                  fontWeight={500}
                  mb={2}
                  color="primary.main"
                >
                  We Expect You To
                </Typography>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    zIndex: 10,
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  {positionInformationObject.WEYT &&
                    positionInformationObject.WEYT.map((item) => (
                      <Grid
                        key={item}
                        item
                        xs={12}
                        my={0.3}
                        display="flex"
                        sx={{ zIndex: 10 }}
                      >
                        <DoubleArrowRounded
                          color="secondary"
                          sx={{ width: { xs: '3vw', md: '.8vw' } }}
                        />
                        <Typography
                          pl={1}
                          variant="body2"
                          color=""
                          fontSize={{ xs: '1.1rem', md: '1rem' }}
                          fontWeight={300}
                        >
                          {item}
                        </Typography>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Grid>
            <Typography
              variant="body2"
              color=""
              fontSize="1.05rem"
              fontWeight={300}
              mt={6}
            >
              If you have any questions, feel free to send an email to{' '}
              <Link
                color="primary.main"
                href="mailto:
              {positionInformationObject.email}"
              >
                {positionInformationObject.email}
              </Link>
              .
            </Typography>
          </Box>

          <Box
            p={{ xs: 2, md: 4 }}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'sticky',
              bottom: 0,
              width: '100%',
              height: 'max-content',
              zIndex: '2000',
              bgcolor: `${theme.palette.background.default}aa`,
              borderTop: `1px solid ${theme.palette.divider}`,
              backdropFilter: 'blur(12px)',
              WebkitBackdropFilter: 'blur(12px)'
            }}
          >
            <PropulseButton
              variant="outlined"
              onClick={close}
              sx={{ px: { xs: 1.5, sm: 2 } }}
            >
              Back to positions
            </PropulseButton>
            {process.env.REACT_APP_APPLY_FOR_POSITION === 'true' && (
              <PropulseButton
                href="https://docs.google.com/forms/d/e/1FAIpQLScj8PWlBb8Sm0zltNk4lWDWjhUmXV6tIPIqD4JctCILRohiWA/viewform?usp=sf_link"
                target="_blank"
                sx={{ px: { xs: 1.5, sm: 2 } }}
              >
                Apply Now
              </PropulseButton>
            )}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default PositionPopup;
