import { useState } from 'react';

import './TimelinePopup.css';
import {
  Box,
  Fade,
  useTheme,
  Modal,
  Typography,
  Container,
  Grid
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

const TimelinePopup = ({ imageURL, openPopup, closePopup, caption }) => {
  const theme = useTheme();
  const [image, setImage] = useState(imageURL);

  return (
    <Modal
      disableAutoFocus
      open={openPopup}
      onClose={closePopup}
      closeAfterTransition
      sx={{
        px: { xs: 2, sm: 10, md: 24 },
        width: '100%',
        height: '100%',
        display: 'flex',
        maxWidth: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(6px)',
        border: 'none'
      }}
    >
      <Fade in={openPopup} timeout={500}>
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Container
            onClick={closePopup}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '5px',
              width: 'auto',
              position: 'absolute',
              right: '12%',
              top: { xs: '10%', md: '14%' },
              transition: 'all .3s',
              '&:hover': {
                opacity: 0.8,
                cursor: 'pointer',
                transition: 'all .3s'
              }
            }}
          >
            <CloseIcon
              sx={{
                fontSize: '1.5rem'
              }}
            />
            <Typography variant={'p'} sx={{ fontSize: '1.2rem' }}>
              Close
            </Typography>
          </Container>
          <Grid container justifyContent="center" alignItems="center">
            {/* <Grid item xs={1}></Grid> */}
            <Grid item xs={10}>
              <Box
                sx={{
                  marginTop: '40px',
                  height: '65vh',
                  width: { xs: '90vw', md: '60vw' },
                  zIndex: 100,
                  background: `url(${image})`,
                  backgroundColor: 'rgba(220,220,220, 0.06)',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backdropFilter: 'blur(5px)',
                  boxShadow: theme.shadows[0],
                  backgroundPosition: 'center',
                  borderRadius: 8
                }}
              />
            </Grid>
            {/* <Grid item xs={1}></Grid> */}
          </Grid>
          <Typography
            variant="p"
            sx={{
              color: 'white',
              fontSize: '1.0rem',
              fontWeight: 400,
              textAlign: 'center',
              marginTop: '20px'
            }}
          >
            {caption}
          </Typography>
        </Container>
      </Fade>
    </Modal>
  );
};

export default TimelinePopup;
