import React from 'react';

import './TimelineLayout.css';
import { Box, Typography, Card, Grid, useTheme, styled } from '@mui/material';

const GlassCard = styled(Card)(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: 'rgba(255,255,255, 0.1)',
  backgroundImage: 'none',
  backdropFilter: 'blur(5px)',
  boxShadow: theme.shadows[0],
  gap: 4
}));

const TimelineLayout = ({ title, children }) => {
  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        position: 'relative',
        scrollSnapAlign: 'none',
        scrollSnapType: 'none'
      }}
    >
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          zIndex: '100'
        }}
      >
        <GlassCard
          sx={{
            position: 'sticky',
            top: { xs: '100vh', md: '40vh' },
            padding: '3%, 5%',
            marginY: { xs: '5%', md: '13%' }
          }}
          theme={theme}
        >
          <Typography
            px={2}
            py={2}
            variant="h1"
            fontWeight={700}
            fontSize={{ xs: 32, md: 48 }}
            sx={{
              color: 'white',
              textAlign: 'center',
              transition: 'opacity 1s ease-out'
            }}
          >
            {title}
          </Typography>
        </GlassCard>
      </Grid>
      <Grid
        item
        pl={{ xs: 0, md: 5 }}
        xs={12}
        md={8}
        my={1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-end'
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default TimelineLayout;
