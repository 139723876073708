import React, { useContext } from 'react';

import './Footer.css';
import SoMeLine from '../misc/SoMeLine/SoMeLine';

import { SecretContext } from '../../context/SecretContext';

import { Container, Stack, Typography } from '@mui/material';

function Footer(props) {
  const art = `⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣀⣀⣀⣠⣤⠶⠶⠶⠛⠳⠶⢤⣀⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⢷⣶⣶⣿⣿⣦⣄⠀⠀⠀⠀⠀⠀⠀⢀⡶⠟⠉⠋⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠉⠛⠶⢤⣀⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⠘⣿⣿⠻⣿⣿⣿⣿⣦⡀⠀⠀⢀⣴⠋⠀⠀⠀⠀⠀⠀⠀⢀⣠⣤⣶⣦⣤⣃⠀⠀⠀⠀⠀⡈⣙⣳⣤⣀⠀⢀⣼⣦⣤⣀
  ⠀⠀⠀⠘⣿⣴⣦⣻⣿⣿⣿⣿⣷⣴⡟⠁⠀⠀⠀⠀⠀⠀⣠⡶⢛⣉⣠⣤⣤⣉⡙⠻⠶⣶⣦⣶⣿⢿⣭⣙⣿⣴⣿⣿⣿⣿⣿
  ⠀⠀⠀⠀⠛⠋⠉⠉⠉⠉⠉⠉⣹⡏⢁⠀⠀⠀⠀⠀⠀⠀⣴⣾⣿⣿⡿⠿⠿⣿⣿⣿⣾⡀⠀⠉⣻⣿⣿⣿⣿⡿⠿⠿⠿⢿⣿
  ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢠⣿⣇⡏⠀⠀⠀⠀⠀⠀⣾⣿⡿⠋⠉⢀⣠⣤⣠⡈⠙⢿⣿⠀⠀⣿⡟⢋⣥⣹⢷⠀⠀⠀⠀⠀
  ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣸⣿⣄⡀⠀⠀⠀⠀⠀⢘⣿⡟⠀⠀⣰⣿⣿⣿⣿⣷⡀⠀⣿⡆⢠⡿⠀⣼⣿⣿⢸⡄⠀⠀⠀⠀
  ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣿⣿⣧⠇⠀⠀⠀⠀⠀⢸⣿⡄⠀⠀⢿⣿⣿⣿⣿⡿⠁⣀⣿⡇⢸⡇⠀⢿⣿⡿⢸⠃⠀⠀⠀⠀
  ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠉⢿⣿⣆⠀⠀⠀⠀⠀⠀⠹⣿⣄⠀⠀⠉⠉⠉⠉⢁⣀⣿⣿⠁⠀⣿⣄⡜⢉⣤⣾⠀⠀⠀⠀⠀
  ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠙⢿⣾⣅⠀⠀⠀⠀⠘⠙⢿⣿⣶⣶⣶⣶⣶⣾⣿⡿⠟⠀⠀⣻⣿⣿⣿⣿⠏⠀⠀⠀⠀⠀
  ⠀⠀⠀⠀⠀⠀⠀⠀⠀⣸⣄⠀⠀⠀⠈⢻⣿⣷⣤⡀⠀⠀⠀⠀⠈⠛⠛⠋⠉⠉⠉⠉⢀⣴⣿⣿⣿⠋⠀⣹⠋⠀⠀⠀⠀⠀⠀
  ⠀⠀⠀⠀⠀⠀⠀⢀⣾⣿⣿⣷⣤⣤⣶⣿⡿⠷⣿⣿⣷⣤⣀⣀⡞⠁⠀⠀⠀⠀⠀⠀⢸⣿⣿⡿⠃⢀⣴⣟⣷⡶⠶⢶⣒⠀⠀
  ⢀⣀⣀⣀⣀⣤⣴⠿⠛⠙⠛⠋⠉⠉⠉⠀⠀⠀⠀⠀⠀⠉⠙⠛⠷⠠⠄⠀⠀⠀⠀⠀⠀⠈⠩⠀⠀⣸⠋⠉⠀⠀⠀⠀⠈⠁⠀
  ⠈⠉⠉⠽⠛⣿⣿⣶⣶⣶⣶⣶⣤⣤⣠⣦⣶⡂⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠻⡆⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⠀⠀⠀⠙⢿⣿⣿⣿⣿⣿⣿⣿⣿⡇⢻⣇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⡇⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⠀⠀⠀⠀⠀⠙⢿⣿⣿⣿⣿⣿⣿⡇⠈⠹⣧⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⡇⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠙⠿⢿⣿⣿⡇⠀⠀⠹⣧⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣷⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠛⣷⠀⠀⠀⠘⣧⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣼⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣿⠄⠀⠀⠀⠘⠃⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⡏⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⡏⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣴⠁⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⡟⠀⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣿⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⡀⠀⣀⣀⣤⣤⣠⣾⠃⠀⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣿⡀⠀⠀⠀⠀⠀⠀⣠⠴⠖⠒⠋⠉⠉⠙⢿⣿⣴⡾⠛⠃⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠘⣧⡀⠀⠀⣀⣴⠞⠁⠀⠀⠀⠀⠀⠀⠀⠀⠈⠛⢿⣷⣶⣄⣀⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠸⣧⢸⡟⠛⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠻⠉⠀⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢻⣿⣿⣆⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠘⠿⣛⠛⠓⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣐⣿⣷⠄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀`;
  const { showSecret, setShowSecret } = useContext(SecretContext);
  //Handle onclick
  const handleSecret = () => {
    setShowSecret(!showSecret);
    if (!showSecret) {
      console.log('Shitpost mode activated\n' + art);
    } else {
      console.log('Shitpost mode deactivated');
    }
  };

  const currentYear = new Date().getFullYear();
  return (
    <Container maxWidth="md" {...props}>
      <Stack
        display="flex"
        flexDirection={{ xs: 'column-reverse', sm: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        py={4}
      >
        <Typography variant="h6" sx={{ color: props.color || undefined }}>
          Propulse NTNU &nbsp;
          <span onClick={() => handleSecret()}>©</span>&nbsp;&nbsp;
          {currentYear}
        </Typography>
        <SoMeLine color={props.color || undefined} size="40px" />
      </Stack>
    </Container>
  );
}

export default Footer;
