import { Box, Container, Stack, useTheme } from '@mui/material';

import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Links } from './Navbar.jsx';
import PropulseLogo from './PropulseLogo';

const Header = (props) => {
  const theme = useTheme();
  let location = useLocation();
  const [overridingColor, setOverrideColor] = React.useState(null);
  const [overridingHoverColor, setOverrideHoverColor] = React.useState(null);
  const [overridingLogoColor, setOverrideLogoColor] = React.useState(null);

  React.useEffect(() => {
    if (location.pathname === '/Projects/Bifrost') {
      setOverrideColor('white');
      setOverrideHoverColor('#7703fc');
      setOverrideLogoColor('#7703fc');
    } else if (location.pathname === '/Projects/Birkeland') {
      setOverrideColor('black');
      setOverrideHoverColor('#00DEE7');
      setOverrideLogoColor('#00DEE7');
    } else if (location.pathname === '/Projects/Stetind') {
      setOverrideColor('white');
      setOverrideLogoColor('#337DFF');
    } else if (location.pathname === '/Projects/Mjolner') {
      setOverrideColor('white');
      setOverrideLogoColor('#278EFF');
    } else if (location.pathname === '/Projects/Sleipner') {
      setOverrideColor('white');
      setOverrideHoverColor('#fc7703');
      setOverrideLogoColor('#fc7703');
    } else if (location.pathname === '/Projects/Penumbra') {
      setOverrideColor('white');
      setOverrideHoverColor(theme.palette.primary.main);
      setOverrideLogoColor(theme.palette.primary.main);
    } else if (location.pathname === '/Projects/Hybrid') {
      setOverrideColor('white');
      setOverrideHoverColor(theme.palette.primary.main);
      setOverrideLogoColor(theme.palette.primary.main);
    } else if (location.pathname === '/Projects/Bifrost') {
      setOverrideColor('white');
      setOverrideHoverColor(theme.palette.primary.main);
      setOverrideLogoColor(theme.palette.primary.main);
    } else if (location.pathname === '/Projects/Valemon') {
      setOverrideColor('white');
      setOverrideHoverColor(theme.palette.primary.main);
      setOverrideLogoColor(theme.palette.primary.main);
    } else if (location.pathname === '/Projects/Project2025') {
      setOverrideColor('white');
      setOverrideHoverColor(theme.palette.primary.main);
      setOverrideLogoColor(theme.palette.primary.main);
    } else if (location.pathname === '/') {
      setOverrideColor('white');
      setOverrideHoverColor(theme.palette.primary.main);
      setOverrideLogoColor('white');
    } else {
      setOverrideColor(null);
      setOverrideHoverColor(null);
      setOverrideLogoColor(null);
    }
  }, [location]);

  return (
    <Box {...props}>
      <Container maxWidth="lg">
        <Stack
          display="flex"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Link to="/">
            <Box width={140}>
              <PropulseLogo
                fill={
                  overridingLogoColor
                    ? overridingLogoColor
                    : theme.palette.mode === 'dark'
                      ? theme.palette.text.primary
                      : theme.palette.primary.main
                }
              />
            </Box>
          </Link>
          <Links color={overridingColor} hoverColor={overridingHoverColor} />
        </Stack>
      </Container>
    </Box>
  );
};

export default Header;
