import React from 'react';
import { Routes, Route, useNavigate, NavLink } from 'react-router-dom';

import About from '../../pages/About/About.jsx';
import Application from '../../pages/Application/Application.jsx';
import Home from '../../pages/Home/Home.jsx';
import Partners from '../../pages/Partners/Partners.jsx';

import './Header.css';
import {
  Box,
  IconButton,
  Modal,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LightModeIcon from '@mui/icons-material/LightMode';
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';
import { styled } from '@mui/system';

import RocketLaunch from './RocketLaunch';
import Projects from '../../pages/Projects/Projects';
import Project from '../../pages/Projects/Project';
import Team from '../../pages/Team/Team';
import MediaOverview from '../../pages/MediaOverview/MediaOverview';
import NotFound from './NotFound';
import Donation from '../../pages/Donation/Donation';

const PropulseNavLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? 'white' : theme.palette.text.primary,
  fontWeight: 500,
  padding: '8px 18px',
  borderRadius: '20px',
  transition: '0.3s',
  transitionTimingFunction: 'cubic-bezier(0, 1, 0, 1)',
  '&:hover': {
    color: 'white',
    backgroundColor: theme.palette.primary.main
  }
}));

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

const Links = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = React.useState(false);
  const colorMode = React.useContext(ColorModeContext);
  const [animation, setAnimation] = React.useState(false);

  const handleClose = () => {
    setShowMenu(false);
    setAnimation(false);
  };

  const handleClick = (link) => {
    setAnimation(true);
    const timeout = setTimeout(() => {
      if (link.includes('http')) {
        window.open(link, '_self');
      } else {
        navigate(link, { replace: true });
      }
      handleClose();
    }, 600);
    return () => clearTimeout(timeout);
  };

  return (
    <Stack display="flex" flexDirection="row" alignItems="center">
      <Box display={{ xs: 'none', md: 'flex' }} gap={1}>
        {/* <PropulseNavLink
          style={{ color: props.color }}
          sx={{ '&:hover': { backgroundColor: props.hoverColor } }}
          to="/">
          Home
        </PropulseNavLink> */}
        <PropulseNavLink
          style={{ color: props.color }}
          sx={{ '&:hover': { backgroundColor: props.hoverColor } }}
          to="/About"
        >
          About
        </PropulseNavLink>
        <PropulseNavLink
          style={{ color: props.color }}
          sx={{ '&:hover': { backgroundColor: props.hoverColor } }}
          to="/Projects"
        >
          Projects
        </PropulseNavLink>
        <PropulseNavLink
          style={{ color: props.color }}
          sx={{ '&:hover': { backgroundColor: props.hoverColor } }}
          to="/Team"
        >
          Team
        </PropulseNavLink>
        <PropulseNavLink
          style={{ color: props.color }}
          sx={{ '&:hover': { backgroundColor: props.hoverColor } }}
          to="/Partners"
        >
          Partners
        </PropulseNavLink>
        <PropulseNavLink
          style={{ color: props.color }}
          sx={{ '&:hover': { backgroundColor: props.hoverColor } }}
          to="https://wiki.propulse.no/"
          target="_blank"
        >
          Wiki&nbsp;
          <OpenInNewIcon fontSize="10px" />
        </PropulseNavLink>
        <PropulseNavLink
          sx={{
            color: props.color || 'primary.contrastText',
            bgcolor: props.hoverColor || 'primary.main'
          }}
          to="/Join"
        >
          Join
        </PropulseNavLink>
      </Box>
      <IconButton
        onClick={colorMode.toggleColorMode}
        color="inherit"
        sx={{
          mx: 1,
          p: 2,
          height: 55,
          display: { xs: 'block', md: 'block' },
          color: props.color ? props.color : ''
        }}
      >
        {theme.palette.mode === 'dark' ? (
          <NightlightRoundIcon />
        ) : (
          <LightModeIcon />
        )}
      </IconButton>
      <Box
        onClick={() => setShowMenu(true)}
        display={{ xs: 'block', md: 'none' }}
        sx={{
          border: `${props.color ? theme.palette.grey[300] : theme.palette.divider} solid 2px`,
          borderRadius: '20px',
          p: 1.75,
          cursor: 'pointer'
        }}
      >
        <Box
          sx={{
            width: 30,
            height: 30,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around'
          }}
        >
          <Box
            sx={{
              backgroundColor: props.color
                ? props.color
                : theme.palette.text.secondary,
              width: '100%',
              height: '10%',
              borderRadius: '2px'
            }}
          />
          <Box
            sx={{
              backgroundColor: props.color
                ? props.color
                : theme.palette.text.secondary,
              width: '100%',
              height: '10%',
              borderRadius: '2px'
            }}
          />
          <Box
            sx={{
              backgroundColor: props.color
                ? props.color
                : theme.palette.text.secondary,
              width: '100%',
              height: '10%',
              borderRadius: '2px'
            }}
          />
        </Box>
      </Box>
      <Modal
        open={showMenu}
        onClose={handleClose}
        sx={{
          userSelect: 'none',
          backdropFilter: 'blur(12px)',
          transition: '0.5s',
          transitionTimingFunction: 'cubic-bezier(.08,.92,0,1)'
        }}
      >
        <>
          <Box
            sx={{
              position: 'absolute',
              top: 60,
              right: 5,
              /* transform: 'translate(-50%, -50%)', */
              width: 'fit-content',
              borderRadius: '20px',
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              zIndex: 'modal'
            }}
          >
            {/* <PropulseNavLink
              onClick={() => {
                handleClick('/');
              }}
              sx={{ fontSize: 24, color: 'white' }}>
              Home
            </PropulseNavLink> */}
            <PropulseNavLink
              onClick={() => {
                handleClick('/About');
              }}
              sx={{ fontSize: 24, color: 'white' }}
            >
              About
            </PropulseNavLink>
            <PropulseNavLink
              onClick={() => {
                handleClick('/Projects');
              }}
              sx={{ fontSize: 24, color: 'white' }}
            >
              Projects
            </PropulseNavLink>
            <PropulseNavLink
              onClick={() => {
                handleClick('/Team');
              }}
              sx={{ fontSize: 24, color: 'white' }}
            >
              Team
            </PropulseNavLink>
            <PropulseNavLink
              onClick={() => {
                handleClick('/Partners');
              }}
              sx={{ fontSize: 24, color: 'white' }}
            >
              Partners
            </PropulseNavLink>
            <PropulseNavLink
              onClick={() => {
                handleClick('https://wiki.propulse.no/');
              }}
              sx={{ fontSize: 24, color: 'white' }}
            >
              Wiki
              <OpenInNewIcon fontSize="10px" />
            </PropulseNavLink>
            <PropulseNavLink
              onClick={() => {
                handleClick('/Join');
              }}
              sx={{ fontSize: 24, color: 'white' }}
            >
              Join
            </PropulseNavLink>
          </Box>
          <Box
            onClick={handleClose}
            sx={{ position: 'absolute', bottom: -10, left: -10, width: '100%' }}
          >
            <RocketLaunch activate={animation} />
          </Box>
        </>
      </Modal>
    </Stack>
  );
};

const RoutesCollection = () => {
  return (
    <Routes className="Routes">
      <Route path="/" element={<Home />} />
      <Route path="/About" element={<About />} />
      <Route path="/Projects" element={<Projects />} />
      <Route path="/Projects/:name" element={<Project />} />
      <Route path="/Team" element={<Team />} />
      <Route path="/Team/:teamId" element={<Team />} />
      <Route path="/Team/:teamId/:chosenGroup" element={<Team />} />
      <Route path="/Partners" element={<Partners />} />
      <Route path="/Media" element={<MediaOverview />} />
      <Route path="/Donate" element={<Donation />} />
      <Route path="/Join" element={<Application />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export { Links, RoutesCollection, ColorModeContext };
