import { IconButton, Stack, useTheme } from '@mui/material';

import React from 'react';

import { ReactComponent as FacebookIcon } from '../../../assets/images/graphical/icons/icon-facebook.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/images/graphical/icons/icon-instagram.svg';
import { ReactComponent as LinkedinIcon } from '../../../assets/images/graphical/icons/icon-linkedin.svg';
import { ReactComponent as TiktokIcon } from '../../../assets/images/graphical/icons/icon-tiktok.svg';
import { ReactComponent as YoutubeIcon } from '../../../assets/images/graphical/icons/icon-youtube.svg';
import './SoMeLine.css';

const SoMeLine = (props) => {
  const theme = useTheme();
  return (
    <Stack direction="row">
      <IconButton
        href="https://www.facebook.com/PropulseNTNU/"
        target="_blank"
        rel="noreferrer"
      >
        <FacebookIcon
          fill={
            props.color
              ? props.color
              : theme.palette.mode === 'dark'
                ? 'white'
                : theme.palette.text.primary
          }
          width={props.size}
          height={props.size}
        />
      </IconButton>
      <IconButton
        href="https://www.instagram.com/propulsentnu/"
        target="_blank"
        rel="noreferrer"
      >
        <InstagramIcon
          fill={
            props.color
              ? props.color
              : theme.palette.mode === 'dark'
                ? 'white'
                : theme.palette.text.primary
          }
          width={props.size}
          height={props.size}
        />
      </IconButton>
      <IconButton
        href="https://www.linkedin.com/company/propulse-ntnu/?originalSubdomain=no"
        target="_blank"
        rel="noreferrer"
      >
        <LinkedinIcon
          fill={
            props.color
              ? props.color
              : theme.palette.mode === 'dark'
                ? 'white'
                : theme.palette.text.primary
          }
          width={props.size}
          height={props.size}
        />
      </IconButton>
      <IconButton
        href="https://www.tiktok.com/@propulsentnu"
        target="_blank"
        rel="noreferrer"
      >
        <TiktokIcon
          fill={
            props.color
              ? props.color
              : theme.palette.mode === 'dark'
                ? 'white'
                : theme.palette.text.primary
          }
          width={props.size}
          height={props.size}
        />
      </IconButton>
      <IconButton
        href="https://www.youtube.com/channel/UCUK9oPK7FMoBEer9J4VnDhA/featured"
        target="_blank"
        rel="noreferrer"
      >
        <YoutubeIcon
          fill={
            props.color
              ? props.color
              : theme.palette.mode === 'dark'
                ? 'white'
                : theme.palette.text.primary
          }
          width={props.size}
          height={props.size}
        />
      </IconButton>
    </Stack>
  );
};

export default SoMeLine;
