import React from 'react';

const PropulseLogo = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1567 911.91">
      <path
        d="M1567,563.95c-3.66,5.13-8.66,6.74-14.89,6.72-45.59-.15-91.18-.03-136.77-.11-20.12-.03-31.27-10.78-31.95-30.84-.24-7.04,.29-14.1,.44-21.15,.14-6.12,3.62-8.64,9.36-8.77,.73-.02,1.46-.15,2.19-.15,51.2-.01,102.39-.07,153.59,.02,8.69,.02,12.61,3.53,12.9,10.62,.31,7.24-3.84,11.87-12.42,12.09-16.57,.42-33.15,.13-49.73,.2-24.37,.11-48.74,.29-73.11,.42-4.13,.02-8.24,.11-12.33-.97-4.32-1.14-7.9-.3-8.27,5.16-.33,4.86-.07,9.57,6.06,10.67,2.37,.42,4.86,.23,7.3,.23,42.91,.01,85.81,.03,128.72-.02,6.91,0,13.46,.87,18.9,5.64v10.23h0Z"
        fill={props.fill}
      />
      <path
        d="M1567,487.92c-4.64,4.49-10.21,5.71-16.58,5.69-50.67-.14-101.34-.07-152.02-.09-5.98,0-12.12-.14-14.23-7.33-2.4-8.17,1.42-13.89,9.9-15.3,1.9-.32,3.88-.28,5.82-.28,50.19-.01,100.37,.01,150.56-.04,7.09,0,13.57,.8,16.55,8.56v8.77h0Z"
        fill={props.fill}
      />
      <path
        d="M766.62,911.91c.02-.42,0-.84-.06-1.26,2.98-2.68,5.95-2.7,8.91,.03-.03,.41-.06,.82-.07,1.23h-8.78Z"
        fill={props.fill}
      />
      <path
        d="M1202.93,160.63c.1,39.7-3.97,74.96-9.7,110.07-.28,1.68-.55,3.36-.77,5.05-.62,4.91,.42,10.9-8.14,9.27-9.16-1.75-11.13-3.72-9.43-12.53,7.33-38.13,10.67-76.61,10.4-115.4-.21-30.58-3.39-60.84-15.46-89.3-17.98-42.42-53.22-58.24-99.25-45.25-34.37,9.7-63.43,28.52-88.44,53.42-64.91,64.61-116.04,139.37-159.58,219.52-22.4,41.24-42.65,83.55-59.64,127.32-2.7,6.94-6.23,7.2-12.31,4.87-6.37-2.44-7.27-5.98-4.86-11.86,51.02-124.31,117.46-239.12,209.13-338.3,24.99-27.04,53.66-49.47,87.73-64.32,23.33-10.17,47.9-15.84,73.04-11.95,25.76,3.98,46.54,17.79,60.88,40.21,13.68,21.4,19.33,45.48,23.09,70.11,2.69,17.59,3.78,35.33,3.31,49.08h0Z"
        fill={props.fill}
      />
      <path
        d="M1183.7,311.25c-2.65,16.75-7.35,33.57-12.62,50.26-20.95,66.33-47.92,130.38-74.04,194.75-4.7,11.59-8.39,22.58-5.87,35.81,3.06,16.12-3.19,31.68-7.68,47.08-2.24,7.7-4.87,15.28-7.3,22.92-1.52,4.78-4.09,6.7-9.32,4.58-18.93-7.66-37.96-15.07-56.96-22.53-3.93-1.54-5.11-3.98-3.41-7.99,9.09-21.32,19.28-41.99,34.65-59.67,3.07-3.53,6.54-6.96,11.19-7.83,5.15-.96,6.84-4.04,8.64-8.48,28.96-71.34,56.04-143.48,90.44-212.51,6.84-13.73,14.29-27.11,23.24-39.6,1.7-2.38,3.43-5.23,6.96-3.88,2.99,1.15,1.65,4.19,2.07,7.08h0Z"
        fill={props.fill}
      />
      <path
        d="M775.47,910.68c-2.97-.01-5.94-.02-8.91-.03-26.71-1.33-47.15-13.92-62.7-35.09-19.07-25.98-28.55-55.61-32.3-87.22-6.44-54.31,.38-107.77,11.02-160.93,2.38-11.92,5.26-23.73,7.56-35.66,1.14-5.89,3.74-7.53,9.68-6.1,5.94,1.43,10.02,2.9,8.09,10.4-5.6,21.67-10.09,43.59-13.62,65.68-6.62,41.43-10.9,83.01-5.2,124.95,3.51,25.82,11.11,50.33,25.28,72.4,19.8,30.84,47.23,40.72,83.41,30.03,33.86-10,62.33-29.3,88.51-52.33,50.21-44.16,89.99-96.89,125.77-152.91,1.31-2.05,2.54-4.15,3.88-6.18,5.1-7.71,8.75-8.02,15.77-1.77,2.8,2.49,3.65,4.82,1.5,8.18-44.1,68.98-91.26,135.36-158.46,184.49-24.27,17.74-49.48,33.82-79.74,39.53-6.44,1.22-13.01,1.74-19.52,2.58h0Z"
        fill={props.fill}
      />
      <path
        d="M1262.86,570.58c-22.42,0-44.84,.08-67.27-.04-9.57-.05-13.64-3.48-14.07-11.14-.28-5.02,1.8-8.49,6.72-9.92,3.76-1.1,7.62-1.42,11.56-1.41,40.46,.06,80.91,.05,121.37,0,3.89,0,7.83-.05,11.65-.67,4.1-.67,6.39-3.38,6.02-7.76-.32-3.9-2.38-6.33-6.38-6.71-2.66-.25-5.34-.34-8.02-.34-37.29-.02-74.58-.02-111.87,0-5.87,0-11.68-.42-17.28-2.32-14.21-4.83-21.78-16.87-20.39-32.38,1.2-13.4,11.52-24.11,25.44-26.2,3.84-.58,7.75-1.01,11.62-1.01,41.92-.07,83.84-.06,125.76,0,2.89,0,5.84,.33,8.66,.99,5.57,1.3,7.91,5.55,8.11,10.78,.19,5.22-2.7,8.55-7.76,10.11-3.1,.95-6.22,1.01-9.39,1.01-42.16-.01-84.33-.02-126.49,0-10.15,0-13.79,2.14-13.78,7.96,0,5.75,3.96,8.1,13.81,8.11,39.48,.02,78.97-.19,118.45,.08,24.15,.17,38.57,21.02,29.68,42.49-5.4,13.05-15.98,18.35-29.61,18.38-22.18,.04-44.36,.01-66.54,.01h0Z"
        fill={props.fill}
      />
      <path
        d="M351.47,532.82c7.68,5.69,14.49,10.65,21.2,15.74,3.1,2.36,6.2,4.84,6.86,9.09,1.27,8.24-6.3,14.74-14.27,12.11-5.88-1.94-10.49-6.09-15.48-9.54-17.53-12.1-35.73-22.69-57.21-26.25-20.87-3.45-41.8-1.03-62.71-.85-4.9,.04-5.65,4.01-5.8,7.79-.25,6.08-.01,12.18-.35,18.26-.41,7.33-4.14,11.05-10.75,11.32-7,.28-11.17-3.35-11.71-11.05-.56-7.99-.9-15.99,.18-24.03,2.14-15.98,9.77-23.55,25.86-25.6,9.51-1.21,18.96-.24,28.45-.21,30.47,.11,60.93,.13,91.4,.19,5.64,.01,9.72-2.03,9.65-8.31-.07-6.4-4.44-7.96-9.96-7.95-28.52,.03-57.05,.01-85.57,.01-15.85,0-31.69,.12-47.54-.05-8.96-.09-12.78-4.31-12.26-12.56,.32-5.08,2.86-8.22,7.79-9.15,3.33-.63,6.76-1.1,10.14-1.11,41.93-.07,83.86-.14,125.8-.01,23.59,.07,38.05,16.54,33.71,37.98-2.35,11.62-10.26,18.86-27.41,24.19h0Z"
        fill={props.fill}
      />
      <path
        d="M539.62,470.47c-31.19-.3-62.38-.18-93.57,.24-17.33,.18-30.09,8.42-38.15,23.86-5.68,10.99-7.08,22.64-5.62,34.73,2.99,25.21,21,41.26,46.2,41.26h44.62c14.83,0,29.73-.18,44.62,.06,24.6,.37,38.7-9.22,46.82-32.41,11.84-33.51-10.44-67.38-44.92-67.75h0Zm24.78,60.67c-2.87,10.44-11.11,16.78-22.77,16.91-15.87,.18-31.68,.06-47.49,.06s-30.7,.12-46.08-.06c-14.77-.12-22.7-7.63-24.29-22.52-1.71-16.42,4.21-27.71,16.6-31.07,3.97-1.04,8.18-1.28,12.27-1.34,27.77-.06,55.6-.12,83.38,0,23.07,.12,34.61,15.62,28.38,38.03h0Z"
        fill={props.fill}
      />
      <path
        d="M166.82,477.01c-6.79-4.96-14.66-6.33-22.82-6.39-21.22-.06-42.44,0-63.65,0-18.76,0-37.53-.06-56.3,0-4.85,.06-9.7-.46-14.55,.8-5.42,1.37-8.61,4.62-8.78,10.15-1.03,26.29-.91,52.59,.06,78.88,.23,6.5,4.79,10.15,11.18,10.04,6.5-.17,10.72-3.31,11.24-10.04,.46-5.82,.4-11.69,.28-17.51-.17-9.47,.57-10.38,10.27-10.38,34.85,0,69.7,.06,104.55-.06,4.62,0,9.24-.23,13.86-.8,12.72-1.54,23.16-10.55,26.07-22.36,3.14-12.55-1.2-24.92-11.41-32.34h0Zm-17.74,32.74c-6.27,.23-12.66,.11-18.99,.11h-40.9c-19.22,0-38.5-.17-57.72,.06-5.65,.06-8.67-1.26-8.5-7.64,.11-6.16,1.6-9.47,8.61-9.41,37.53,.23,75.06,.11,112.53,.11,1.94,0,3.88,.34,5.82,.57,4.9,.68,7.3,3.71,7.19,8.5-.17,5.02-3.31,7.47-8.04,7.7h0Z"
        fill={props.fill}
      />
      <path
        d="M690.7,471.05c21.94,0,43.88-.27,65.81,.08,18.62,.3,30.26,11.67,30.82,29.12,.56,17.67-10.07,29.85-28.34,31.67-16.03,1.6-32.16,.47-48.24,.59-21.7,.16-43.41-.05-65.11,.08-12.43,.07-13.62,1.45-13.64,13.99,0,4.14,.16,8.3-.09,12.42-.47,7.44-4.31,11.16-11.51,11.51-6.21,.3-9.43-2.87-10.37-10.57-1.03-8.48-1.25-17-.27-25.5,1.82-15.83,11.58-24.66,27.97-24.79,24.14-.19,48.28-.05,72.42-.05,14.63,0,29.26,.06,43.89-.03,7.85-.05,11.68-2.88,11.22-8.31-.58-6.84-5.64-7.73-11.11-7.73-43.41-.02-86.81,0-130.22-.02-9.74,0-13.64-2.82-14.1-9.92-.55-8.5,3.35-12.66,12.83-12.92,9.99-.27,19.99-.07,29.99-.07,12.68,0,25.36,0,38.04,0v.44h0Z"
        fill={props.fill}
      />
      <path
        d="M902.64,570.58c-14.38,0-28.77,.02-43.15,0-30.81-.06-48.6-17.05-49.68-47.93-.45-12.87-.21-25.78-.03-38.67,.12-8.85,3.8-12.54,11.67-12.36,7.32,.17,10.65,3.96,10.76,12.58,.16,12.42-.06,24.85,.32,37.26,.54,17.61,8.62,26.09,26.26,26.35,29,.43,58.02,.44,87.02,0,18.52-.29,26.8-9.29,27.09-28,.18-11.68-.03-23.37,.06-35.06,.07-9.31,3.27-13.05,10.89-13.12,7.89-.08,11.15,3.39,11.22,12.7,.1,13.39,.07,26.79-.22,40.18-.61,27.81-18.95,45.8-46.86,46.06-15.11,.14-30.23,.03-45.35,.03h0Z"
        fill={props.fill}
      />
      <path
        d="M1197.74,659.93c.19-3.88,.12-7.78,.64-11.62,1.28-9.51,7.65-16.27,17.13-17.15,15.3-1.42,30.68-1.43,45.97-.01,9.67,.9,15.29,6.6,17.42,16.03,1.74,7.71,1.07,15.52,.92,23.29-.06,3.27-1.95,5.68-5.45,5.63-3.53-.05-5.11-2.46-5.15-5.83-.07-5.6,.16-11.21-.11-16.8-.42-8.55-3.83-11.75-12.16-12.27-12.42-.77-24.83-.47-37.24,.06-6.9,.3-10.73,4.03-11.11,10.99-.28,5.1-.08,10.23-.07,15.34,0,4.09-.24,8.45-5.6,8.45-5.19,0-4.96-4.3-4.9-8.06,.05-2.68,0-5.36,0-8.03-.1,0-.19,0-.29,0h0Z"
        fill={props.fill}
      />
      <path
        d="M1417.6,630.97c7.78,0,15.61-.52,23.34,.13,9.83,.83,16.18,7.01,17.24,16.79,.81,7.47,.39,15.07,.55,22.61,.06,3.09-1.12,5.39-4.38,5.62-3.53,.24-5.45-1.9-5.61-5.3-.23-5.1-.13-10.22-.29-15.33-.35-11.04-2.8-13.8-13.79-14.26-10.7-.45-21.43-.58-32.13-.39-11.02,.2-15.05,4.67-15.25,15.94-.08,4.38-.05,8.76-.01,13.15,.03,3.26-.86,6-4.58,6.23-3.58,.22-5.47-2.23-5.54-5.46-.16-7.03-1.09-14.11,.34-21.11,2.77-13.55,9.41-19.02,23.3-19.16,5.6-.06,11.21-.01,16.81-.01,0,.19,0,.37,0,.56h0Z"
        fill={props.fill}
      />
      <path
        d="M1512.53,676.44c-7.31,0-14.63,.29-21.92-.07-12.7-.62-19.17-6.77-20.33-19.38-.6-6.52-.42-13.12-.51-19.69-.05-3.49,.82-6.43,5.19-6.28,4.04,.13,4.4,2.98,4.49,6.14,.17,6.07-.45,12.15,.64,18.21,1.27,7.1,5.47,10.76,12.49,10.86,13.15,.17,26.31,.23,39.45-.02,7.92-.15,12.06-4.25,12.67-12.2,.42-5.57,.27-11.19,.34-16.78,.04-3.18,.6-5.98,4.54-6.17,4.2-.21,5.46,2.41,5.47,6.06,.03,8.24,1.37,16.55-1.28,24.64-3.27,9.97-9.59,14.62-20.07,14.76-3.65,.05-7.3-.04-10.95-.06-3.41-.01-6.82,0-10.23,0h0Z"
        fill={props.fill}
      />
      <path
        d="M1327.75,630.41c11.19,0,22.38-.11,33.57,.07,3.53,.06,7.97-.09,8.01,5.19,.04,4.75-4.05,4.79-7.37,5.24-5.29,.72-10.62,1.17-15.91,1.87-6.09,.8-9.57,4.36-10.38,10.43-.74,5.53-1.37,11.07-2.1,16.6-.44,3.3-1.34,6.58-5.51,6.35-4.13-.22-4.85-3.42-4.75-6.85,.21-7.03-.05-14.13,.85-21.08,.79-6.09-1.35-7.76-7.02-7.52-7.53,.31-15.08,.11-22.62,.05-3.57-.03-7.05-.34-7-5.28,.04-4.58,3.54-4.88,6.65-4.93,11.19-.17,22.38-.07,33.57-.07v-.06h0Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default PropulseLogo;
