import birkelandBackdrop from '../../assets/images/backgrounds/birkeland_backdrop.png';
import stetindBackdrop from '../../assets/images/backgrounds/stetind_backdrop.png';
import mjolnerBackdrop from '../../assets/images/backgrounds/mjolner_backdrop.png';
import sleipnerBackdrop from '../../assets/images/backgrounds/sleipner_backdrop.png';
import valemonBackdrop from '../../assets/images/backgrounds/valemon_backdrop.jpg';
import bifrostBackdrop from '../../assets/images/backgrounds/bifrost_backdrop.webp';
import project2025Backdrop from '../../assets/images/backgrounds/project2025_backdrop.png';

export default function getProjectBackground(identifier) {
  switch (identifier) {
    case 'Project2025':
      return project2025Backdrop;
    case 'Valemon':
      return valemonBackdrop;
    case 'Bifrost':
      return bifrostBackdrop;
    case 'Birkeland':
      return birkelandBackdrop;
    case 'Stetind':
      return stetindBackdrop;
    case 'Mjolner':
      return mjolnerBackdrop;
    case 'Sleipner':
      return sleipnerBackdrop;
    default:
      return '';
  }
}
