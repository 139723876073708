import React from 'react';

import './InformationCard.css';
import { Box, Typography, Card, Grid, useTheme, styled } from '@mui/material';

import { ReactComponent as TeamLogo } from '../../../assets/images/graphical/icons/teamlogo.svg';
import PropulseButton from '../../core/Universal/PropulseButton';

const GlassCard = styled(Card)(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: 'rgba(255,255,255, 0.05)',
  backgroundImage: 'none',
  backdropFilter: 'blur(5px)',
  boxShadow: theme.shadows[0],
  gap: 4
}));

const InformationCard = ({ title, content }) => {
  const theme = useTheme();
  return (
    <GlassCard
      theme={theme}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: { xs: '100%', md: '85%' },
        padding: '3% 5%',
        margin: { xs: '8% 0', md: '6% 0' }
      }}
    >
      <Box
        my={2}
        sx={{
          width: { xs: '20vw', md: '6vw' },
          height: { xs: '1.5vw', md: '.4vw' },
          bgcolor: 'primary.main'
        }}
      />
      <Typography
        mb={3}
        variant="h1"
        fontWeight={600}
        fontSize={{ xs: 28, md: 32 }}
        sx={{
          color: 'white',
          transition: 'opacity 1s ease-out'
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="p"
        fontWeight={300}
        fontSize={{ xs: 18, md: 18 }}
        sx={{
          color: 'white',
          transition: 'opacity 1s ease-out'
        }}
      >
        {content}
      </Typography>
    </GlassCard>
  );
};

export default InformationCard;
